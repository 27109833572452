import request from '@/utils/request'
export function getDcCustomercenterCustomeraddress(params) {
  return request({
    url: '/shoppingMallCommodity/dcCustomercenterCustomeraddress',
    method: 'get',
    params
  })
}
export function addDcCustomercenterCustomeraddress(data) {
    return request({
      url: '/shoppingMallCommodity/addCustomerAddress',
      method: 'post',
      data
    })
  }
  export function editDcCustomercenterCustomeraddress(data) {
    return request({
      url: '/dcCustomercenterCustomeraddress',
      method: 'PUT',
      data
    })
  }

  export function deleteDcCustomercenterCustomeraddress(id) {
    return request({
        url: '/dcCustomercenterCustomeraddress/' + id,
        method: 'DELETE',
    })
}
export function addDcCustomercenterCustomeraddressImage(data) {
  return request({
    url: '/dcCustomercenterCustomeraddress/addCustomerAddressImage ',
    method: 'post',
    data
  })
}

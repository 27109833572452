<template>
  <div class="shopping com-overflow-hidden" style="background: #f3f6f8">
    <div style="display: flex; align-items: center; width: 100%" v-if="lange == '中文'">
      <div
        style="
          width: 96%;
          margin-left: 2%;
          margin-right: 2%;
          background: #ffffff;
          min-height: 100vh;
          margin-top: 50px;
          margin-bottom: 100px;
        "
      >
        <div
          style="
            display: flex;
            justify-content: space-between;
            width: 100%;
            line-height: 80px;
            align-items: center;
          "
        >
          <div style="font-size: 22px; text-align: center; margin-left: 20px">
            我的订单<span style="font-size: 14px"></span>
          </div>
          <div
            style="
              min-height: 60px;
              display: flex;
              align-items: center;
              justify-content: center;
            "
          >
            <ul>
              <li>
                <div
                  style="line-height: 65px; font-size: 16px"
                  class="liItem"
                  @mouseover="resetBorderColor()"
                  @click="queryAll"
                >
                  所有订单
                </div>
              </li>
              <li>
                <div
                  style="line-height: 65px; font-size: 16px"
                  class="liItem"
                  @mouseover="resetBorderColor()"
                  @click="queryNoMoney"
                >
                  待付款
                </div>
              </li>
              <li>
                <div
                  style="line-height: 65px; font-size: 16px"
                  class="liItem"
                  @mouseover="resetBorderColor()"
                  @click="queryHaveMoney"
                >
                  已付款
                </div>
              </li>
              <li>
                <div
                  style="line-height: 65px; font-size: 16px"
                  class="liItem"
                  @mouseover="resetBorderColor()"
                  @click="queryNoGo"
                >
                  待发货
                </div>
              </li>
              <li>
                <div
                  style="line-height: 65px; font-size: 16px"
                  class="liItem"
                  @mouseover="resetBorderColor()"
                  @click="queryHaveGo"
                >
                  已发货
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div style="width: 100%">
          <div v-for="(item, index) in cartList" :key="index" style="">
            <div
              style="
                display: flex;
                align-items: center;
                line-height: 50px;
                font-weight: 500;
                background: #f3f6f8;
                margin: 0px 10px;
                padding: 0 10px;
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
                margin-top: 20px;
                border: 1px solid #f0f3f5;
              "
            >
              <div style="margin-left: 33px">
                <!-- <el-checkbox
                    :value="item.checked"
                    @change="selectItem($event, index)"
                  ></el-checkbox> -->
              </div>
              <!-- <div style="margin-left: 40px; line-height: 50px">
                  添加人：{{ item.creater }}
                </div>
                <div style="margin-left: 40px; line-height: 50px">
                  添加时间：{{ item.createTime }}
                </div> -->
            </div>
            <div
              style="
                margin: 0px 7px;
                padding: 0 7px;
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;
              "
            >
              <el-table :data="tableData" stripe style="width: 100%">
                <el-table-column
                  prop="date"
                  label="订单编码"
                  align="center"
                  min-width="100px"
                  ><template slot-scope="scope">
                    {{ item.orderCode }}
                  </template>
                </el-table-column>
                <!-- <el-table-column prop="date" label="物流编码" align="center" width="120px"
                    ><template slot-scope="scope">
                      {{ item.logisticsCode != "" ? item.logisticsCode : "" }}
                    </template>
                  </el-table-column> -->
                <!-- <el-table-column prop="date" label="收货地址" align="center" width="220px"
                    ><template slot-scope="scope">
                      {{
                        item.customerAddressString != "" ? item.customerAddressString : ""
                      }}
                    </template>
                  </el-table-column>
                  <el-table-column prop="date" label="收货人" align="center" width="80px"
                    ><template slot-scope="scope">
                      {{ item.contacts != "" ? item.consignee : "" }}
                    </template>
                  </el-table-column>
                  <el-table-column prop="date" label="联系电话" align="center" width="120px"
                    ><template slot-scope="scope">
                      {{ item.phone != "" ? item.phone : "" }}
                    </template>
                  </el-table-column> -->
                <el-table-column
                  prop="address"
                  label="总箱数"
                  align="center"
                  min-width="80px"
                >
                  <template slot-scope="scope">
                    {{ item.totalBoxNumber }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="address"
                  label="总体积/m³"
                  align="center"
                  min-width="80px"
                >
                  <template slot-scope="scope">
                    {{ (item.totalVolume * 1).toFixed(2) }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="address"
                  :label="organizationName == '迪拜' ? '总金额/DHS' : '总金额/RMB'"
                  align="center"
                  min-width="120px"
                >
                  <template slot-scope="scope">
                    <span> {{ (item.totalMoney * 1).toFixed(2) }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="address"
                  label="发货数量"
                  align="center"
                  min-width="120px"
                >
                  <template slot-scope="scope">
                    <span> {{ item.outQuantity * 1 }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="address"
                  label="待发货数量"
                  align="center"
                  min-width="120px"
                >
                  <template slot-scope="scope">
                    <span> {{ item.daiNumber * 1 }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="createTime"
                  label="下单时间"
                  align="center"
                  min-width="160px"
                >
                  <template slot-scope="scope">
                    {{ item.createTime }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="createTime"
                  label="订单状态"
                  align="center"
                  min-width="100px"
                >
                  <template slot-scope="scope">
                    <el-tag type="info" v-if="item.status == 0 && item.isClose != 1"
                      >未发货</el-tag
                    >
                    <el-tag type="success" v-if="item.status == 1 && item.isClose != 1"
                      >未发完</el-tag
                    >
                    <el-tag v-if="item.status == 2 && item.isClose != 1">已发完</el-tag>
                    <el-tag v-if="item.status == -1 && item.isClose != 1" type="warning"
                      >新增</el-tag
                    >
                    <el-tag v-if="item.status == -2 && item.isClose != 1" type="danger"
                      >不同意</el-tag
                    >
                    <el-tag v-if="item.isClose == 1" type="danger" style="color: #000"
                      >关闭订单</el-tag
                    >
                  </template>
                </el-table-column>
                <el-table-column
                  type="expand"
                  label="订单详情"
                  width="80px"
                  align="center"
                  v-show="lange == '中文'"
                >
                  <template slot-scope="props">
                    <div
                      v-for="(itemp, indexp) in item.orderCommodityList"
                      :key="indexp"
                      style=""
                    >
                      <div
                        style="
                          display: flex;
                          align-items: center;
                          line-height: 50px;
                          font-weight: 500;
                          background: #f3f6f8;
                          margin: 0px 10px;
                          padding: 0 10px;
                          border-top-left-radius: 10px;
                          border-top-right-radius: 10px;
                          margin-top: 20px;
                          border: 1px solid #f0f3f5;
                        "
                      ></div>
                      <div
                        style="
                          margin: 0px 7px;
                          padding: 0 7px;
                          border-bottom-left-radius: 10px;
                          border-bottom-right-radius: 10px;
                        "
                      >
                        <el-table :data="tableData" stripe style="width: 100%">
                          <el-table-column
                            prop="date"
                            label="商品"
                            align="center"
                            width="120px"
                            ><template slot-scope="scope">
                              <el-image
                                style="width: 100%; height: 100%"
                                :src="itemp.imageUrl"
                                :preview-src-list="[itemp.imageUrl]"
                              >
                              </el-image>
                            </template>
                          </el-table-column>
                          <el-table-column
                            prop="name"
                            label=""
                            align="center"
                            min-width="120px"
                          >
                            <template slot-scope="scope">
                              <div style="font-size: 14px">
                                <div style="text-align: left">{{ itemp.cInvCode }}</div>

                                <div
                                  style="text-align: left"
                                  v-if="itemp.shoppingMallName"
                                >
                                  {{ itemp.brandName + "-" + itemp.shoppingMallName }}
                                </div>
                                <div style="text-align: left" v-else>
                                  {{ itemp.brandName }}
                                </div>
                                <div style="text-align: left">
                                  {{
                                    itemp.sellAttributeId == 1
                                      ? "现货销售"
                                      : itemp.sellAttributeId == 2
                                      ? "生产预售"
                                      : itemp.sellAttributeId == 3
                                      ? "在途预售"
                                      : "新品预售"
                                  }}
                                </div>
                              </div>
                              <div style="font-size: 12px">
                                <div style="text-align: left">
                                  添加人：{{ itemp.creater }}
                                </div>
                                <div style="text-align: left">
                                  添加时间：{{ itemp.createTime }}
                                </div>
                              </div>
                            </template>
                          </el-table-column>
                          <el-table-column
                            prop="address"
                            label="颜色配比"
                            align="center"
                            min-width="230px"
                          >
                            <template slot-scope="scope">
                              <div style="position: relative">
                                <div
                                  ref="imgListp"
                                  style="display: flex; flex-wrap: wrap"
                                >
                                  <div
                                    v-for="(
                                      items, indexk
                                    ) in itemp.shoppingCartDeatailListCopy"
                                    :key="indexk"
                                    style="
                                      display: flex;
                                      aligin-item: center;
                                      flex-direction: column;
                                      margin-right: 2%;
                                      margin-bottom: 20px;
                                      width: 22%;
                                    "
                                  >
                                    <el-image
                                      style="
                                        width: 100%;
                                        height: 100%;
                                        margin-right: 10px;
                                        margin-bottom: 10px;
                                      "
                                      :src="items.imageUrls"
                                      :preview-src-list="items.imageUrlLists"
                                    >
                                    </el-image>

                                    <div
                                      style="
                                        width: 100%;
                                        justify-content: center;
                                        align-items: center;
                                      "
                                    >
                                      <div class="counters">
                                        <div style="font-size: 21px; color: black">-</div>
                                        <div style="font-weight: 500">
                                          {{ items.colorQuantity }}
                                        </div>
                                        <div style="font-size: 21px; color: black">+</div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  style="
                                    display: flex;
                                    width: 100%;
                                    align-items: center;
                                    justify-content: center;
                                    font-size: 18px;
                                    margin: 10px 0;
                                    position: absolute;
                                    bottom: -10px;
                                    left: 0%;
                                  "
                                  v-if="muchShow"
                                  @click="muchShowClick(index, indexp)"
                                >
                                  <i class="el-icon-arrow-down"></i>
                                </div>
                                <div
                                  style="
                                    display: flex;
                                    width: 100%;
                                    align-items: center;
                                    justify-content: center;
                                    font-size: 18px;
                                    margin: 20px 0;
                                  "
                                  v-else
                                  @click="muchShowClick(index, indexp)"
                                >
                                  <i class="el-icon-arrow-up"></i>
                                </div>
                              </div>
                            </template>
                          </el-table-column>
                          <el-table-column
                            prop="address"
                            label="单价/psc"
                            align="center"
                            min-width="90px"
                          >
                            <template slot-scope="scope">
                              <span v-if="organizationNames == '迪拜'">
                                {{
                                  itemp.picePrice
                                    ? itemp.picePrice.toFixed(2) + "/DHS"
                                    : ""
                                }}</span
                              >
                              <span v-else>
                                {{
                                  itemp.picePrice
                                    ? itemp.picePrice.toFixed(2) + "/RMB"
                                    : ""
                                }}</span
                              >
                            </template>
                          </el-table-column>
                          <el-table-column
                            prop="address"
                            label="体积/m³"
                            align="center"
                            min-width="90px"
                          >
                            <template slot-scope="scope">
                              {{ itemp.ivolumesum.toFixed(2) }}
                            </template>
                          </el-table-column>

                          <el-table-column
                            prop="address"
                            label="购买数量/PCS"
                            align="center"
                            min-width="120px"
                          >
                            <template slot-scope="scope">
                              {{ itemp.countSum }}
                            </template>
                          </el-table-column>
                          <el-table-column
                            prop="address"
                            label="发货数量/PCS"
                            align="center"
                            min-width="120px"
                          >
                            <template slot-scope="scope">
                              {{ itemp.outQuantity }}
                            </template>
                          </el-table-column>
                          <el-table-column
                            prop="address"
                            label="待发货数量/PCS"
                            align="center"
                            min-width="120px"
                          >
                            <template slot-scope="scope">
                              {{ itemp.daiNumber }}
                            </template>
                          </el-table-column>
                          <el-table-column
                            prop="address"
                            label="购买箱量/CTN"
                            align="center"
                            min-width="120px"
                          >
                            <template slot-scope="scope">
                              {{ itemp.boxNumber }}
                            </template>
                          </el-table-column>
                          <el-table-column
                            prop="address"
                            :label="
                              organizationName == '迪拜' ? '总金额/DHS' : '总金额/RMB'
                            "
                            align="center"
                            min-width="120px"
                          >
                            <template slot-scope="scope">
                              <span> {{ itemp.totalPrice.toFixed(2) }}</span>
                            </template>
                          </el-table-column>
                        </el-table>
                      </div>
                    </div>
                    <div style="margin: 20px 20px; display: flex">
                      <div>
                        <span
                          >物流编码:
                          {{ item.logisticsCode != "" ? item.logisticsCode : "" }}</span
                        >
                      </div>
                      <div style="margin-left: 30px">
                        <span
                          >收货地址:
                          {{
                            item.customerAddressString != ""
                              ? item.customerAddressString
                              : ""
                          }}</span
                        >
                      </div>
                      <div style="margin-left: 30px">
                        <span
                          >联系人: {{ item.contacts != "" ? item.consignee : "" }}</span
                        >
                      </div>
                      <div style="margin-left: 30px">
                        <span>联系电话: {{ item.phone != "" ? item.phone : "" }}</span>
                      </div>
                    </div>
                    <div style="margin: 20px 20px; display: flex">
                      <div>支付凭证：</div>
                      <div style="display: flex">
                        <div
                          v-for="(itemp, indexp) in item.orderPaymentList"
                          :key="indexp"
                        >
                          <el-image
                            style="
                              width: 100px;
                              height: 100px;
                              margin-right: 10px;
                              margin-bottom: 10px;
                            "
                            :src="itemp.imageUrl"
                            :preview-src-list="itemp.imageUrlList"
                          ></el-image>
                        </div>
                      </div>
                      <div style="margin-left: 30px">物流单号：</div>
                      <div style="display: flex">
                        <div v-for="(itemp, indexp) in item.logisticsList" :key="indexp">
                          <el-image
                            style="
                              width: 100px;
                              height: 100px;
                              margin-right: 10px;
                              margin-bottom: 10px;
                            "
                            :src="itemp.imageUrl"
                            :preview-src-list="itemp.imageUrlList"
                          ></el-image>
                        </div>
                      </div>
                    </div>
                  </template>
                </el-table-column>

                <el-table-column label="操作" min-width="80px" align="center">
                  <template
                    slot-scope="scope"
                    v-if="
                      (item.status != -2 && item.isClose != 1) ||
                      (item.status != 2 && item.isClose != 1)
                    "
                  >
                    <el-button type="text" size="small" @click="payClick(item)"
                      >支付</el-button
                    >
                    <el-button type="text" size="small" @click="payClickItem(item)"
                      >上传支付凭证</el-button
                    >
                    <!-- <el-button type="text" size="small" @click="payClickItemd(item)"
                        >上传物流图片</el-button
                      > -->
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      style="display: flex; align-items: center; width: 100%"
      v-if="lange == 'English'"
    >
      <div
        style="
          width: 96%;
          margin-left: 2%;
          margin-right: 2%;
          background: #ffffff;
          min-height: 100vh;
          margin-top: 50px;
          margin-bottom: 100px;
        "
      >
        <div
          style="
            display: flex;
            justify-content: space-between;
            width: 100%;
            line-height: 80px;
            align-items: center;
          "
        >
          <div style="font-size: 22px; text-align: center; margin-left: 20px">
            My Orders<span style="font-size: 14px"></span>
          </div>
          <div
            style="
              min-height: 60px;
              display: flex;
              align-items: center;
              justify-content: center;
            "
          >
            <ul>
              <li>
                <div
                  style="line-height: 65px; font-size: 16px"
                  class="liItem"
                  @mouseover="resetBorderColor()"
                  @click="queryAll"
                >
                  All Orders
                </div>
              </li>
              <li>
                <div
                  style="line-height: 65px; font-size: 16px"
                  class="liItem"
                  @mouseover="resetBorderColor()"
                  @click="queryNoMoney"
                >
                  Pending Payment
                </div>
              </li>
              <li>
                <div
                  style="line-height: 65px; font-size: 16px"
                  class="liItem"
                  @mouseover="resetBorderColor()"
                  @click="queryHaveMoney"
                >
                  Paid
                </div>
              </li>
              <li>
                <div
                  style="line-height: 65px; font-size: 16px"
                  class="liItem"
                  @mouseover="resetBorderColor()"
                  @click="queryNoGo"
                >
                  Pending Shipment
                </div>
              </li>
              <li>
                <div
                  style="line-height: 65px; font-size: 16px"
                  class="liItem"
                  @mouseover="resetBorderColor()"
                  @click="queryHaveGo"
                >
                  Shipped
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div style="width: 100%">
          <div v-for="(item, index) in cartList" :key="index" style="">
            <div
              style="
                display: flex;
                align-items: center;
                line-height: 50px;
                font-weight: 500;
                background: #f3f6f8;
                margin: 0px 10px;
                padding: 0 10px;
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
                margin-top: 20px;
                border: 1px solid #f0f3f5;
              "
            >
              <div style="margin-left: 33px">
                <!-- <el-checkbox
                    :value="item.checked"
                    @change="selectItem($event, index)"
                  ></el-checkbox> -->
              </div>
              <!-- <div style="margin-left: 40px; line-height: 50px">
                  添加人：{{ item.creater }}
                </div>
                <div style="margin-left: 40px; line-height: 50px">
                  添加时间：{{ item.createTime }}
                </div> -->
            </div>
            <div
              style="
                margin: 0px 7px;
                padding: 0 7px;
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;
              "
            >
              <el-table :data="tableData" stripe style="width: 100%">
                <el-table-column
                  prop="date"
                  label="Order Number"
                  align="center"
                  min-width="100px"
                  ><template slot-scope="scope">
                    {{ item.orderCode }}
                  </template>
                </el-table-column>
                <!-- <el-table-column prop="date" label="Shipping Number" align="center" width="150px"
                    ><template slot-scope="scope">
                      {{ item.logisticsCode != "" ? item.logisticsCode : "" }}
                    </template>
                  </el-table-column> -->
                <!-- <el-table-column prop="date" label="Shipping Address" align="center" width="220px"
                    ><template slot-scope="scope">
                      {{
                        item.customerAddressString != "" ? item.customerAddressString : ""
                      }}
                    </template>
                  </el-table-column>
                  <el-table-column prop="date" label="Consignee" align="center" width="100px"
                    ><template slot-scope="scope">
                      {{ item.contacts != "" ? item.consignee : "" }}
                    </template>
                  </el-table-column>
                  <el-table-column prop="date" label="Contact Number" align="center" width="140px"
                    ><template slot-scope="scope">
                      {{ item.phone != "" ? item.phone : "" }}
                    </template>
                  </el-table-column> -->
                <el-table-column
                  prop="address"
                  label="CTN"
                  align="center"
                  min-width="60px"
                >
                  <template slot-scope="scope">
                    {{ item.totalBoxNumber }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="address"
                  label="CBM/m³"
                  align="center"
                  min-width="60px"
                >
                  <template slot-scope="scope">
                    {{ (item.totalVolume * 1).toFixed(2) }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="address"
                  :label="
                    organizationName == '迪拜' ? 'Total Amount /DHS' : 'Total Amount /RMB'
                  "
                  align="center"
                  min-width="120px"
                >
                  <template slot-scope="scope">
                    <span> {{ (item.totalMoney * 1).toFixed(2) }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="address"
                  label="ShipmentQuantity"
                  align="center"
                  min-width="120px"
                >
                  <template slot-scope="scope">
                    <span> {{ item.outQuantity * 1 }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="address"
                  label="QuantityToBeShipped"
                  align="center"
                  min-width="120px"
                >
                  <template slot-scope="scope">
                    <span> {{ item.daiNumber * 1 }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="createTime"
                  label="Order Time"
                  align="center"
                  min-width="160px"
                >
                  <template slot-scope="scope">
                    {{ item.createTime }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="createTime"
                  label="orderStaus"
                  align="center"
                  min-width="100px"
                >
                  <template slot-scope="scope">
                    <el-tag type="info" v-if="item.status == 0 && item.isClose != 1"
                      >Unshipped</el-tag
                    >
                    <el-tag type="success" v-if="item.status == 1 && item.isClose != 1"
                      >Not completed yet</el-tag
                    >
                    <el-tag v-if="item.status == 2 && item.isClose != 1">Sent out</el-tag>
                    <el-tag v-if="item.status == -1 && item.isClose != 1" type="warning"
                      >newly added</el-tag
                    >
                    <el-tag v-if="item.status == -2 && item.isClose != 1" type="danger"
                      >disagree</el-tag
                    >
                    <el-tag v-if="item.isClose == 1" type="danger" style="color: #000"
                      >close order</el-tag
                    >
                  </template>
                </el-table-column>
                <el-table-column
                  type="expand"
                  label="Order Details "
                  width="180px"
                  align="center"
                  v-show="lange == '英文'"
                >
                  <template slot-scope="props">
                    <div
                      v-for="(itemp, indexp) in item.orderCommodityList"
                      :key="indexp"
                      style=""
                    >
                      <div
                        style="
                          display: flex;
                          align-items: center;
                          line-height: 50px;
                          font-weight: 500;
                          background: #f3f6f8;
                          margin: 0px 10px;
                          padding: 0 10px;
                          border-top-left-radius: 10px;
                          border-top-right-radius: 10px;
                          margin-top: 20px;
                          border: 1px solid #f0f3f5;
                        "
                      ></div>
                      <div
                        style="
                          margin: 0px 7px;
                          padding: 0 7px;
                          border-bottom-left-radius: 10px;
                          border-bottom-right-radius: 10px;
                        "
                      >
                        <el-table :data="tableData" stripe style="width: 100%">
                          <el-table-column
                            prop="date"
                            label="Product"
                            align="center"
                            min-width="100px"
                            ><template slot-scope="scope">
                              <el-image
                                style="width: 100%; height: 100%"
                                :src="itemp.imageUrl"
                                :preview-src-list="[itemp.imageUrl]"
                              >
                              </el-image>
                            </template>
                          </el-table-column>
                          <el-table-column
                            prop="name"
                            label=""
                            align="center"
                            min-width="120px"
                          >
                            <template slot-scope="scope">
                              <div style="font-size: 12px">
                                <div style="text-align: left">{{ itemp.cInvCode }}</div>
                                <div
                                  style="text-align: left"
                                  v-if="itemp.shoppingMallName"
                                >
                                  {{ itemp.brandName + "-" + itemp.shoppingMallName }}
                                </div>
                                <div style="text-align: left" v-else>
                                  {{ itemp.brandName }}
                                </div>
                                <div style="text-align: left">
                                  {{
                                    itemp.sellAttributeId == 1
                                      ? "spot sales"
                                      : itemp.sellAttributeId == 2
                                      ? " Pre-Sale In Production"
                                      : itemp.sellAttributeId == 3
                                      ? "Pre sale in transit"
                                      : "New Arrival"
                                  }}
                                </div>
                              </div>
                              <div style="font-size: 12px">
                                <div style="text-align: left">
                                  AddPeople：{{ itemp.creater }}
                                </div>
                                <div style="text-align: left">
                                  AddTime：{{ itemp.createTime }}
                                </div>
                              </div>
                            </template>
                          </el-table-column>
                          <el-table-column
                            prop="address"
                            label="Color Proportion"
                            align="center"
                            min-width="230px"
                          >
                            <template slot-scope="scope">
                              <div style="position: relative">
                                <div
                                  ref="imgListp"
                                  style="display: flex; flex-wrap: wrap"
                                >
                                  <div
                                    v-for="(
                                      items, indexk
                                    ) in itemp.shoppingCartDeatailListCopy"
                                    :key="indexk"
                                    style="
                                      display: flex;
                                      aligin-item: center;
                                      flex-direction: column;
                                      margin-right: 2%;
                                      margin-bottom: 20px;
                                      width: 22%;
                                    "
                                  >
                                    <el-image
                                      style="
                                        width: 100%;
                                        height: 100%;
                                        margin-right: 10px;
                                        margin-bottom: 10px;
                                      "
                                      :src="items.imageUrls"
                                      :preview-src-list="items.imageUrlLists"
                                    >
                                    </el-image>

                                    <div
                                      style="
                                        width: 100%;
                                        justify-content: center;
                                        align-items: center;
                                      "
                                    >
                                      <div class="counters">
                                        <div style="font-size: 21px; color: black">-</div>
                                        <div style="font-weight: 500">
                                          {{ items.colorQuantity }}
                                        </div>
                                        <div style="font-size: 21px; color: black">+</div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  style="
                                    display: flex;
                                    width: 100%;
                                    align-items: center;
                                    justify-content: center;
                                    font-size: 18px;
                                    margin: 10px 0;
                                    position: absolute;
                                    bottom: -10px;
                                    left: 0%;
                                  "
                                  v-if="muchShow"
                                  @click="muchShowClick(index, indexp)"
                                >
                                  <i class="el-icon-arrow-down"></i>
                                </div>
                                <div
                                  style="
                                    display: flex;
                                    width: 100%;
                                    align-items: center;
                                    justify-content: center;
                                    font-size: 18px;
                                    margin: 20px 0;
                                  "
                                  v-else
                                  @click="muchShowClick(index, indexp)"
                                >
                                  <i class="el-icon-arrow-up"></i>
                                </div>
                              </div>
                            </template>
                          </el-table-column>
                          <el-table-column
                            prop="address"
                            label="price/psc"
                            align="center"
                            min-width="90px"
                          >
                            <template slot-scope="scope">
                              <span v-if="organizationNames == '迪拜'">
                                {{
                                  itemp.picePrice
                                    ? itemp.picePrice.toFixed(2) + "/DHS"
                                    : ""
                                }}</span
                              >
                              <span v-else>
                                {{
                                  itemp.picePrice
                                    ? itemp.picePrice.toFixed(2) + "/RMB"
                                    : ""
                                }}</span
                              >
                            </template>
                          </el-table-column>
                          <el-table-column
                            prop="address"
                            label="CBM/m³"
                            align="center"
                            min-width="80px"
                          >
                            <template slot-scope="scope">
                              {{ itemp.ivolumesum.toFixed(2) }}
                            </template>
                          </el-table-column>

                          <el-table-column
                            prop="address"
                            label="ToatlQuantity/PCS"
                            align="center"
                            min-width="180px"
                          >
                            <template slot-scope="scope">
                              {{ itemp.countSum }}
                            </template>
                          </el-table-column>
                          <el-table-column
                            prop="address"
                            label="ShipmentQuantity"
                            align="center"
                            min-width="140px"
                          >
                            <template slot-scope="scope">
                              {{ itemp.outQuantity }}
                            </template>
                          </el-table-column>
                          <el-table-column
                            prop="address"
                            label="QuantityToBeShipped"
                            align="center"
                            min-width="140px"
                          >
                            <template slot-scope="scope">
                              {{ itemp.daiNumber }}
                            </template>
                          </el-table-column>
                          <el-table-column
                            prop="address"
                            label="CTN"
                            align="center"
                            min-width="80px"
                          >
                            <template slot-scope="scope">
                              {{ itemp.boxNumber }}
                            </template>
                          </el-table-column>
                          <el-table-column
                            prop="address"
                            :label="
                              organizationName == '迪拜'
                                ? 'Total Amount/DHS'
                                : 'Total Amount/RMB'
                            "
                            align="center"
                            min-width="180px"
                          >
                            <template slot-scope="scope">
                              <span> {{ itemp.totalPrice }}</span>
                            </template>
                          </el-table-column>
                        </el-table>
                      </div>
                    </div>
                    <div style="margin: 20px 20px; display: flex">
                      <div>
                        <span
                          >Shipping Number:
                          {{ item.logisticsCode != "" ? item.logisticsCode : "" }}</span
                        >
                      </div>
                      <div style="margin-left: 30px">
                        <span
                          >Shipping Address:
                          {{
                            item.customerAddressString != ""
                              ? item.customerAddressString
                              : ""
                          }}</span
                        >
                      </div>
                      <div style="margin-left: 30px">
                        <span
                          >Consignee:
                          {{ item.contacts != "" ? item.consignee : "" }}</span
                        >
                      </div>
                      <div style="margin-left: 30px">
                        <span
                          >Contact Number: {{ item.phone != "" ? item.phone : "" }}</span
                        >
                      </div>
                    </div>
                    <div style="margin: 20px 20px; display: flex">
                      <div>Document Upload：</div>
                      <div style="display: flex">
                        <div
                          v-for="(itemp, indexp) in item.orderPaymentList"
                          :key="indexp"
                        >
                          <el-image
                            style="
                              width: 100px;
                              height: 100px;
                              margin-right: 10px;
                              margin-bottom: 10px;
                            "
                            :src="itemp.imageUrl"
                            :preview-src-list="itemp.imageUrlList"
                          ></el-image>
                        </div>
                      </div>
                      <div style="margin-left: 30px">Logistics tracking number：</div>
                      <div style="display: flex">
                        <div v-for="(itemp, indexp) in item.logisticsList" :key="indexp">
                          <el-image
                            style="
                              width: 100px;
                              height: 100px;
                              margin-right: 10px;
                              margin-bottom: 10px;
                            "
                            :src="itemp.imageUrl"
                            :preview-src-list="itemp.imageUrlList"
                          ></el-image>
                        </div>
                      </div>
                    </div>
                  </template>
                </el-table-column>

                <el-table-column label="Setting" min-width="120" align="center">
                  <template
                    slot-scope="scope"
                    v-if="
                      (item.status != -2 && item.isClose != 1) ||
                      (item.status != 2 && item.isClose != 1)
                    "
                  >
                    <el-button type="text" size="small" @click="payClick(item)"
                      >Payment</el-button
                    >
                    <el-button type="text" size="small" @click="payClickItem(item)"
                      >Payment Document Upload</el-button
                    >
                    <!-- <el-button type="text" size="small" @click="payClickItemd(item)"
                        >上传物流图片</el-button
                      > -->
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title="payment"
      :visible.sync="dialogVisible"
      width="50%"
      v-if="lange == '中文'"
    >
      <!-- <div style="display: flex" v-if="checked1 == true">
          <div
            style="
              display: flex;
              justify-content: space-between;
              font-weight: 600;
              line-height: 28px;
              margin-right: 40px;
            "
          >
            <div style="">含税金额：</div>
            <div style="font-size: 15px; color: #ec1c24" v-if="checked1 == true">
              {{ totalTaxMoney }}{{ organizationName == "迪拜" ? "DHS" : "/RMB" }}
            </div>
            <div style="font-size: 15px; color: #ec1c24" v-else>
              {{ buyCounterMoneySum }}{{ organizationName == "迪拜" ? "DHS" : "/RMB" }}
            </div>
          </div>
          <div
            style="
              display: flex;
              justify-content: space-between;
              font-weight: 600;
              line-height: 28px;
              margin-right: 40px;
            "
          >
            <div style="">税额：</div>
            <div style="font-size: 15px; color: #ec1c24" v-if="checked1 == true">
              {{ tax }}{{ organizationName == "迪拜" ? "DHS" : "/RMB" }}
            </div>
            <div style="font-size: 15px; color: #ec1c24" v-else>0</div>
          </div>
          <div
            style="
              display: flex;
              justify-content: space-between;
              font-weight: 600;
              line-height: 28px;
              margin-right: 40px;
            "
          >
            <div style="">税率：</div>
            <div style="font-size: 15px; color: #ec1c24" v-if="checked1 == true">
              {{ taxRate }}%
            </div>
            <div style="font-size: 15px; color: #ec1c24" v-else>0</div>
          </div>
          <div
            style="
              display: flex;
              justify-content: space-between;
              font-weight: 600;
              line-height: 28px;
              margin-right: 40px;
            "
          >
            <div style="">不含税金额：</div>
            <div style="font-size: 15px; color: #ec1c24">
              {{ buyCounterMoneySum.toFixed(2)
              }}{{ organizationName == "迪拜" ? "DHS" : "/RMB" }}
            </div>
          </div>
        </div> -->
      <div style="display: flex">
        <div
          style="
            display: flex;
            justify-content: space-between;
            font-weight: 600;
            line-height: 28px;
            margin-right: 40px;
          "
        >
          <div style="">支付金额：</div>
          <div style="font-size: 20px; color: #ec1c24">
            {{ (payItem.totalMoney * 1).toFixed(2)
            }}{{ organizationName == "迪拜" ? "DHS" : "/RMB" }}
          </div>
        </div>
      </div>
      <div style="display: flex">
        <div style="width: 30%; margin-right: 2%">
          <div style="margin-bottom: 20px">{{ listItem.paymentCodeWeChatName }}：</div>
          <div>
            <el-image
              v-if="listItem.paymentCode_WeChat"
              style="width: 100%; height: 100%"
              :src="listItem.paymentCode_WeChat"
              :preview-src-list="[listItem.paymentCode_WeChat]"
            >
            </el-image>
            <div v-else>暂无设置</div>
          </div>
        </div>
        <div style="width: 30%; margin-right: 2%">
          <div style="margin-bottom: 20px">{{ listItem.paymentCodeAlipayName }}：</div>
          <div>
            <el-image
              v-if="listItem.paymentCode_Alipay"
              style="width: 100%; height: 100%"
              :src="listItem.paymentCode_Alipay"
              :preview-src-list="[listItem.paymentCode_Alipay]"
            >
            </el-image>
            <div v-else>暂无设置</div>
          </div>
        </div>
        <div style="width: 30%">
          <div style="margin-bottom: 20px">{{ listItem.paymentCodeBankName }}：</div>
          <div>
            <el-image
              v-if="listItem.paymentCode_Bank"
              style="width: 100%; height: 100%"
              :src="listItem.paymentCode_Bank"
              :preview-src-list="[listItem.paymentCode_Bank]"
            >
            </el-image>
            <div v-else>暂无设置</div>
          </div>
        </div>
      </div>
      <div v-if="listItem.isPCShowCompany" style="margin-top: 50px">
        <div>{{ listItem.paymentCodeCompanyName }}:</div>

        <div
          v-html="listItem.paymentCode_Company"
          v-if="listItem.paymentCode_Company"
        ></div>
        <div v-else>暂无设置</div>
      </div>
      <div style="display: flex; justify-content: center; width: 100%">
        <el-button type="success" @click="dialogVisibles = true">上传凭证</el-button>
      </div>
    </el-dialog>
    <el-dialog title="payment" :visible.sync="dialogVisible" width="40%" v-else>
      <div style="display: flex">
        <div style="width: 30%; margin-right: 2%">
          <div style="margin-bottom: 20px">{{ listItem.paymentCodeWeChatName }}：</div>
          <div>
            <el-image
              v-if="listItem.paymentCode_WeChat"
              style="width: 100%; height: 100%"
              :src="listItem.paymentCode_WeChat"
              :preview-src-list="[listItem.paymentCode_WeChat]"
            >
            </el-image>
            <div v-else>No settings available at the moment</div>
          </div>
        </div>
        <div style="width: 30%; margin-right: 2%">
          <div style="margin-bottom: 20px">{{ listItem.paymentCodeAlipayName }}：</div>
          <div>
            <el-image
              v-if="listItem.paymentCode_Alipay"
              style="width: 100%; height: 100%"
              :src="listItem.paymentCode_Alipay"
              :preview-src-list="[listItem.paymentCode_Alipay]"
            >
            </el-image>
            <div v-else>No settings available at the moment</div>
          </div>
        </div>
        <div style="width: 30%">
          <div style="margin-bottom: 20px">{{ listItem.paymentCodeBankName }}：</div>
          <div>
            <el-image
              v-if="listItem.paymentCode_Bank"
              style="width: 100%; height: 100%"
              :src="listItem.paymentCode_Bank"
              :preview-src-list="[listItem.paymentCode_Bank]"
            >
            </el-image>
            <div v-else>No settings available at the moment</div>
          </div>
        </div>
      </div>
      <div v-if="listItem.isPCShowCompany" style="margin-top: 50px">
        <div>{{ listItem.paymentCodeCompanyName }}:</div>

        <div
          v-html="listItem.paymentCode_Company"
          v-if="listItem.paymentCode_Company"
        ></div>
        <div v-else>No settings available at the moment</div>
      </div>
      <div style="display: flex; justify-content: center; width: 100%">
        <el-button type="success" @click="dialogVisibles = true"
          >Upload credentials</el-button
        >
      </div>
    </el-dialog>
    <el-dialog
      title="凭证上传"
      :visible.sync="dialogVisibles"
      width="40%"
      append-to-body
      v-if="lange == '中文'"
    >
      <div style="display: flex; justify-content: center; width: 100%">
        <el-upload
          action=""
          list-type="picture-card"
          :on-preview="handlePictureCardPreview"
          :http-request="httprequest"
        >
          <i class="el-icon-plus"></i>
        </el-upload>
        <el-dialog :visible.sync="dialogVisiblek">
          <img width="100%" :src="dialogImageUrl" alt="" />
        </el-dialog>
      </div>
      <div style="display: flex; justify-content: center; width: 100%; margin-top: 20px">
        <el-button type="success" @click="addDcOrdercenterOrderClick2"
          >上传凭证</el-button
        >
      </div>
    </el-dialog>
    <el-dialog
      title="Upload credentials"
      :visible.sync="dialogVisibles"
      width="40%"
      append-to-body
      v-else
    >
      <div style="display: flex; justify-content: center; width: 100%">
        <el-upload
          action=""
          list-type="picture-card"
          :on-preview="handlePictureCardPreview"
          :http-request="httprequest"
        >
          <i class="el-icon-plus"></i>
        </el-upload>
        <el-dialog :visible.sync="dialogVisiblek">
          <img width="100%" :src="dialogImageUrl" alt="" />
        </el-dialog>
      </div>
      <div style="display: flex; justify-content: center; width: 100%; margin-top: 20px">
        <el-button type="success" @click="addDcOrdercenterOrderClick2"
          >Upload credentials</el-button
        >
      </div>
    </el-dialog>
    <!-- <el-dialog title="物流单号图片上传" :visible.sync="dialogVisiblej" width="40%" append-to-body>
        <div style="display: flex; justify-content: center; width: 100%">
          <el-upload
            action=""
            list-type="picture-card"
            :on-preview="handlePictureCardPreviewj"
            :http-request="httprequestj"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
          <el-dialog :visible.sync="dialogVisibled" append-to-body>
            <img width="100%" :src="dialogImageUrld" alt="" />
          </el-dialog>
        </div>
        <div style="display: flex; justify-content: center; width: 100%; margin-top: 20px">
          <el-button type="success" @click="addDcOrdercenterOrderClick2"
            >上传凭证</el-button
          >
        </div>
      </el-dialog> -->
  </div>
</template>

<script>
import {
  getDcShoppingmallShoppingcart,
  deleteDcShoppingmallShoppingcart,
  getCustomerPaymentCode,
  addCustomerOrderPaymentImage,
  addCustomerOrderLogisticsImage,
  dcCustomercenterPaymentcodeQueryDefault,
} from "@/api/cart/index.js";
import {
  getDcBasearchiveCommodityPackingratio,
  getDcOrdercenterOrder,
} from "@/api/good/index.js";
import ShopItem from "./Shoping/ShopItem.vue";
import shopOrder from "./shopOrder/index.vue";
import { encryptionKey } from "@/config";
import CryptoJS from "crypto-js";
import { mapGetters } from "vuex";
export default {
  name: "ShoppingVue",
  data() {
    return {
      dialogVisible: false,
      dialogVisibles: false,
      dialogVisiblek: false,
      dialogVisiblej: false,
      dialogVisibled: false,
      buyCounterPcsSum: 0,
      buyCounterVolumeSum: 0,
      buyCounterCtnSum: 0,
      buyCounterMoneySum: 0,
      organizationNames: localStorage.getItem("organizationNames"),
      muchShow: true,
      checked: false,
      tableData: [
        {
          date: "2016-05-02",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
      ],

      isHome: false,
      isActive: false,
      isAllSelected: false,
      allShop: "0.00",
      dialogTableVisible: false,
      dialogVisiblep: false,
      imageHight: 0,

      checkep: false,
      lpButtonShow: false,

      cartList: [],
      priceCounter: 0,
      buyCountSum: 0,
      buyCountSumMoney: 0,
      listItem: {},
      dialogImageUrl: "",
      dialogImageUrld: "",
      payItem: {},
      payItemj: {},
    };
  },
  components: {
    ShopItem,
    shopOrder,
  },

  mounted() {
    getCustomerPaymentCode({}).then((res) => {
      if (res.code == 200) {
        if (JSON.stringify(res.data) != "{}") {
          this.listItem = res.data;

          if (this.listItem.paymentCode_Alipay) {
            this.listItem.paymentCode_Alipay = this.codeChange(
              this.listItem.paymentCode_Alipay
            );
          } else {
            this.listItem.paymentCode_Alipay = "";
          }
          if (this.listItem.paymentCode_Bank) {
            this.listItem.paymentCode_Bank = this.codeChange(
              this.listItem.paymentCode_Bank
            );
          } else {
            this.listItem.paymentCode_Bank = "";
          }
          if (this.listItem.paymentCode_WeChat) {
            this.listItem.paymentCode_WeChat = this.codeChange(
              this.listItem.paymentCode_WeChat
            );
          } else {
            this.listItem.paymentCode_WeChat = "";
          }
          console.log(this.listItem);
        } else {
          dcCustomercenterPaymentcodeQueryDefault({ pageSize: 10, pageNum: 1 }).then(
            (resp) => {
              if (res.data.length != 0) {
                this.listItem = resp.data[0];
                console.log("this.listItem", this.listItem);
                if (this.listItem.isPCShowWeChat == 1) {
                  this.listItem.isPCShowWeChat = true;
                } else {
                  this.listItem.isPCShowWeChat = false;
                }
                if (this.listItem.isPCShowAlipay == 1) {
                  this.listItem.isPCShowAlipay = true;
                } else {
                  this.listItem.isPCShowAlipay = false;
                }
                if (this.listItem.isPCShowBank == 1) {
                  this.listItem.isPCShowBank = true;
                } else {
                  this.listItem.isPCShowBank = false;
                }
                if (this.listItem.isAPPShowWeChat == 1) {
                  this.listItem.isAPPShowWeChat = true;
                } else {
                  this.listItem.isAPPShowWeChat = false;
                }
                if (this.listItem.isAPPShowAlipay == 1) {
                  this.listItem.isAPPShowAlipay = true;
                } else {
                  this.listItem.isAPPShowAlipay = false;
                }
                if (this.listItem.isAPPShowBank == 1) {
                  this.listItem.isAPPShowBank = true;
                } else {
                  this.listItem.isAPPShowBank = false;
                }
                if (this.listItem.paymentCode_Alipay) {
                  this.listItem.paymentCode_Alipay = this.codeChange(
                    this.listItem.paymentCode_Alipay
                  );
                }
                if (this.listItem.paymentCode_Bank) {
                  this.listItem.paymentCode_Bank = this.codeChange(
                    this.listItem.paymentCode_Bank
                  );
                }
                if (this.listItem.paymentCode_WeChat) {
                  this.listItem.paymentCode_WeChat = this.codeChange(
                    this.listItem.paymentCode_WeChat
                  );
                }

                console.log("listItem", this.listItem);
                this.$forceUpdate();
              } else {
                this.listItem = {
                  paymentCodeWeChatName: "",
                  paymentCodeAlipayName: "",
                  paymentCodeBankName: "",
                  paymentCodeCompanyName: "",
                  isPCShowWeChat: "",
                  isPCShowAlipay: "",
                  isPCShowBank: "",
                  isPCShowCompany: "",
                  isAPPShowWeChat: "",
                  isAPPShowAlipay: "",
                  isAPPShowBank: "",
                  isAPPShowCompany: "",
                };
              }
            }
          );
        }
      }
    });
    getDcOrdercenterOrder({ pageNum: 1, pageSize: 1000 }).then((res) => {
      this.cartList = res.data.list;

      this.cartList.forEach((item) => {
        if (item.orderPaymentList && item.orderPaymentList.length != 0) {
          item.orderPaymentList.forEach((iteml) => {
            if (iteml.fileName) {
              iteml.imageUrl = this.$locationUrl + "file/" + iteml.fileUrl;
              iteml.imageUrlList = [iteml.imageUrl];
            }
          });
        }
        if (item.logisticsList && item.logisticsList.length != 0) {
          item.logisticsList.forEach((iteml) => {
            if (iteml.fileName) {
              iteml.imageUrl = this.$locationUrl + "file/" + iteml.fileUrl;
              iteml.imageUrlList = [iteml.imageUrl];
            }
          });
        }

        console.log(item);
        item.orderCommodityList.forEach((itemk) => {
          itemk.checked = false;
          // let haveDictnameLP = itemk.sellAttributeList.filter((itemp) => {
          //   return itemp.dictname == "零批";
          // });
          // if (haveDictnameLP.length != 0) {
          //   this.lpButtonShow = true;
          // } else {
          //   this.lpButtonShow = false;
          // }
          itemk.countSum = 0;
          item.outQuantity = 0;
          item.daiNumber = 0;
          itemk.packSum = 0;
          itemk.daiNumber = 0;
          itemk.outQuantity = 0;
          itemk.ordercommodityDetailList.forEach((itemj) => {
            console.log(itemj.colorQuantity);
            console.log(itemj.outQuantity);
            itemk.countSum = itemj.colorQuantity * 1 + itemk.countSum * 1;
            itemk.daiNumber =
              (itemj.colorQuantity - itemj.outQuantity) * 1 + itemk.daiNumber * 1;
            itemk.outQuantity = itemk.outQuantity * 1 + itemj.outQuantity * 1;
          });

          console.log(itemk.packingRatioList);
          itemk.packingRatioList.forEach((itemv) => {
            itemk.packSum = itemv.fpeiBi * 1 + itemk.packSum * 1;
          });
          console.log(itemk.packSum);
          itemk.totalPrice = itemk.picePrice * itemk.countSum;
          itemk.ivolumesum = (itemk.ivolume / itemk.packSum / 1000000) * itemk.countSum;
          itemk.imageUrl = this.$locationUrl + "file/" + itemk.imageUrl;
          console.log(itemk.imageUrl);
          itemk.ordercommodityDetailList.forEach((items) => {
            items.imageUrls =
              this.$locationUrl +
              "file/abyFile/commodityFile/commodityColorImage/" +
              items.fileName;
            items.imageUrlLists = [item.imageUrls];
            console.log(items.imageUrls);
          });
          itemk.shoppingCartDeatailListCopy = itemk.ordercommodityDetailList.slice(0, 4);
        });
      });
      this.cartList.forEach((item) => {
        item.orderCommodityList.forEach((itemk) => {
          console.log("-------------", itemk.daiNumber, itemk.outQuantity);
          item.daiNumber = itemk.daiNumber * 1 + item.daiNumber * 1;
          item.outQuantity = itemk.outQuantity * 1 + item.outQuantity * 1;
        });
      });
    });
    this.$forceUpdate();
  },
  created() {},
  computed: {
    ...mapGetters({
      lange: "user/lange",
      organizationName: "user/organizationName",
    }),
  },
  watch: {},
  methods: {
    queryAll() {
      getDcOrdercenterOrder({ pageNum: 1, pageSize: 1000000 }).then((res) => {
        this.cartList = res.data.list;

        this.cartList.forEach((item) => {
          if (item.orderPaymentList && item.orderPaymentList.length != 0) {
            item.orderPaymentList.forEach((iteml) => {
              if (iteml.fileName) {
                iteml.imageUrl = this.$locationUrl + "file/" + iteml.fileUrl;
                iteml.imageUrlList = [iteml.imageUrl];
              }
            });
          }
          if (item.logisticsList && item.logisticsList.length != 0) {
            item.logisticsList.forEach((iteml) => {
              if (iteml.fileName) {
                iteml.imageUrl = this.$locationUrl + "file/" + iteml.fileUrl;
                iteml.imageUrlList = [iteml.imageUrl];
              }
            });
          }

          console.log(item);
          item.orderCommodityList.forEach((itemk) => {
            itemk.checked = false;
            // let haveDictnameLP = itemk.sellAttributeList.filter((itemp) => {
            //   return itemp.dictname == "零批";
            // });
            // if (haveDictnameLP.length != 0) {
            //   this.lpButtonShow = true;
            // } else {
            //   this.lpButtonShow = false;
            // }
            itemk.countSum = 0;
            itemk.packSum = 0;
            itemk.ordercommodityDetailList.forEach((itemj) => {
              console.log(itemj.colorQuantity);
              itemk.countSum = itemj.colorQuantity * 1 + itemk.countSum * 1;
            });
            console.log(itemk.packingRatioList);
            itemk.packingRatioList.forEach((itemv) => {
              itemk.packSum = itemv.fpeiBi * 1 + itemk.packSum * 1;
            });
            console.log(itemk.packSum);
            itemk.totalPrice = itemk.picePrice * itemk.countSum;
            itemk.ivolumesum = (itemk.ivolume / itemk.packSum / 1000000) * itemk.countSum;
            itemk.imageUrl = this.$locationUrl + "file/" + itemk.imageUrl;
            console.log(itemk.imageUrl);
            itemk.ordercommodityDetailList.forEach((items) => {
              items.imageUrls =
                this.$locationUrl +
                "file/abyFile/commodityFile/commodityColorImage/" +
                items.fileName;
              items.imageUrlLists = [item.imageUrls];
              console.log(items.imageUrls);
            });
            itemk.shoppingCartDeatailListCopy = itemk.ordercommodityDetailList.slice(
              0,
              4
            );
          });
        });
      });
    },
    queryNoMoney() {
      getDcOrdercenterOrder({ pageNum: 1, pageSize: 1000000, isPaid: 0 }).then((res) => {
        this.cartList = res.data.list;

        this.cartList.forEach((item) => {
          if (item.orderPaymentList && item.orderPaymentList.length != 0) {
            item.orderPaymentList.forEach((iteml) => {
              if (iteml.fileName) {
                iteml.imageUrl = this.$locationUrl + "file/" + iteml.fileUrl;
                iteml.imageUrlList = [iteml.imageUrl];
              }
            });
          }
          if (item.logisticsList && item.logisticsList.length != 0) {
            item.logisticsList.forEach((iteml) => {
              if (iteml.fileName) {
                iteml.imageUrl = this.$locationUrl + "file/" + iteml.fileUrl;
                iteml.imageUrlList = [iteml.imageUrl];
              }
            });
          }

          console.log(item);
          item.orderCommodityList.forEach((itemk) => {
            itemk.checked = false;
            // let haveDictnameLP = itemk.sellAttributeList.filter((itemp) => {
            //   return itemp.dictname == "零批";
            // });
            // if (haveDictnameLP.length != 0) {
            //   this.lpButtonShow = true;
            // } else {
            //   this.lpButtonShow = false;
            // }
            itemk.countSum = 0;
            itemk.packSum = 0;
            itemk.ordercommodityDetailList.forEach((itemj) => {
              console.log(itemj.colorQuantity);
              itemk.countSum = itemj.colorQuantity * 1 + itemk.countSum * 1;
            });
            console.log(itemk.packingRatioList);
            itemk.packingRatioList.forEach((itemv) => {
              itemk.packSum = itemv.fpeiBi * 1 + itemk.packSum * 1;
            });
            console.log(itemk.packSum);
            itemk.totalPrice = itemk.picePrice * itemk.countSum;
            itemk.ivolumesum = (itemk.ivolume / itemk.packSum / 1000000) * itemk.countSum;
            itemk.imageUrl = this.$locationUrl + "file/" + itemk.imageUrl;
            console.log(itemk.imageUrl);
            itemk.ordercommodityDetailList.forEach((items) => {
              items.imageUrls =
                this.$locationUrl +
                "file/abyFile/commodityFile/commodityColorImage/" +
                items.fileName;
              items.imageUrlLists = [item.imageUrls];
              console.log(items.imageUrls);
            });
            itemk.shoppingCartDeatailListCopy = itemk.ordercommodityDetailList.slice(
              0,
              4
            );
          });
        });
      });
    },
    queryHaveMoney() {
      getDcOrdercenterOrder({ pageNum: 1, pageSize: 1000000, isPaid: 1 }).then((res) => {
        this.cartList = res.data.list;

        this.cartList.forEach((item) => {
          if (item.orderPaymentList && item.orderPaymentList.length != 0) {
            item.orderPaymentList.forEach((iteml) => {
              if (iteml.fileName) {
                iteml.imageUrl = this.$locationUrl + "file/" + iteml.fileUrl;
                iteml.imageUrlList = [iteml.imageUrl];
              }
            });
          }
          if (item.logisticsList && item.logisticsList.length != 0) {
            item.logisticsList.forEach((iteml) => {
              if (iteml.fileName) {
                iteml.imageUrl = this.$locationUrl + "file/" + iteml.fileUrl;
                iteml.imageUrlList = [iteml.imageUrl];
              }
            });
          }

          console.log(item);
          item.orderCommodityList.forEach((itemk) => {
            itemk.checked = false;
            // let haveDictnameLP = itemk.sellAttributeList.filter((itemp) => {
            //   return itemp.dictname == "零批";
            // });
            // if (haveDictnameLP.length != 0) {
            //   this.lpButtonShow = true;
            // } else {
            //   this.lpButtonShow = false;
            // }
            itemk.countSum = 0;
            itemk.packSum = 0;
            itemk.ordercommodityDetailList.forEach((itemj) => {
              console.log(itemj.colorQuantity);
              itemk.countSum = itemj.colorQuantity * 1 + itemk.countSum * 1;
            });
            console.log(itemk.packingRatioList);
            itemk.packingRatioList.forEach((itemv) => {
              itemk.packSum = itemv.fpeiBi * 1 + itemk.packSum * 1;
            });
            console.log(itemk.packSum);
            itemk.totalPrice = itemk.picePrice * itemk.countSum;
            itemk.ivolumesum = (itemk.ivolume / itemk.packSum / 1000000) * itemk.countSum;
            itemk.imageUrl = this.$locationUrl + "file/" + itemk.imageUrl;
            console.log(itemk.imageUrl);
            itemk.ordercommodityDetailList.forEach((items) => {
              items.imageUrls =
                this.$locationUrl +
                "file/abyFile/commodityFile/commodityColorImage/" +
                items.fileName;
              items.imageUrlLists = [item.imageUrls];
              console.log(items.imageUrls);
            });
            itemk.shoppingCartDeatailListCopy = itemk.ordercommodityDetailList.slice(
              0,
              4
            );
          });
        });
      });
    },
    queryHaveGo() {
      getDcOrdercenterOrder({ pageNum: 1, pageSize: 1000000, isDelivery: 1 }).then(
        (res) => {
          this.cartList = res.data.list;

          this.cartList.forEach((item) => {
            if (item.orderPaymentList && item.orderPaymentList.length != 0) {
              item.orderPaymentList.forEach((iteml) => {
                if (iteml.fileName) {
                  iteml.imageUrl = this.$locationUrl + "file/" + iteml.fileUrl;
                  iteml.imageUrlList = [iteml.imageUrl];
                }
              });
            }
            if (item.logisticsList && item.logisticsList.length != 0) {
              item.logisticsList.forEach((iteml) => {
                if (iteml.fileName) {
                  iteml.imageUrl = this.$locationUrl + "file/" + iteml.fileUrl;
                  iteml.imageUrlList = [iteml.imageUrl];
                }
              });
            }

            console.log(item);
            item.orderCommodityList.forEach((itemk) => {
              itemk.checked = false;
              // let haveDictnameLP = itemk.sellAttributeList.filter((itemp) => {
              //   return itemp.dictname == "零批";
              // });
              // if (haveDictnameLP.length != 0) {
              //   this.lpButtonShow = true;
              // } else {
              //   this.lpButtonShow = false;
              // }
              itemk.countSum = 0;
              itemk.packSum = 0;
              itemk.ordercommodityDetailList.forEach((itemj) => {
                console.log(itemj.colorQuantity);
                itemk.countSum = itemj.colorQuantity * 1 + itemk.countSum * 1;
              });
              console.log(itemk.packingRatioList);
              itemk.packingRatioList.forEach((itemv) => {
                itemk.packSum = itemv.fpeiBi * 1 + itemk.packSum * 1;
              });
              console.log(itemk.packSum);
              itemk.totalPrice = itemk.picePrice * itemk.countSum;
              itemk.ivolumesum =
                (itemk.ivolume / itemk.packSum / 1000000) * itemk.countSum;
              itemk.imageUrl = this.$locationUrl + "file/" + itemk.imageUrl;
              console.log(itemk.imageUrl);
              itemk.ordercommodityDetailList.forEach((items) => {
                items.imageUrls =
                  this.$locationUrl +
                  "file/abyFile/commodityFile/commodityColorImage/" +
                  items.fileName;
                items.imageUrlLists = [item.imageUrls];
                console.log(items.imageUrls);
              });
              itemk.shoppingCartDeatailListCopy = itemk.ordercommodityDetailList.slice(
                0,
                4
              );
            });
          });
        }
      );
    },
    queryNoGo() {
      getDcOrdercenterOrder({ pageNum: 1, pageSize: 1000000, isDelivery: 0 }).then(
        (res) => {
          this.cartList = res.data.list;

          this.cartList.forEach((item) => {
            if (item.orderPaymentList && item.orderPaymentList.length != 0) {
              item.orderPaymentList.forEach((iteml) => {
                if (iteml.fileName) {
                  iteml.imageUrl = this.$locationUrl + "file/" + iteml.fileUrl;
                  iteml.imageUrlList = [iteml.imageUrl];
                }
              });
            }
            if (item.logisticsList && item.logisticsList.length != 0) {
              item.logisticsList.forEach((iteml) => {
                if (iteml.fileName) {
                  iteml.imageUrl = this.$locationUrl + "file/" + iteml.fileUrl;
                  iteml.imageUrlList = [iteml.imageUrl];
                }
              });
            }

            console.log(item);
            item.orderCommodityList.forEach((itemk) => {
              itemk.checked = false;
              // let haveDictnameLP = itemk.sellAttributeList.filter((itemp) => {
              //   return itemp.dictname == "零批";
              // });
              // if (haveDictnameLP.length != 0) {
              //   this.lpButtonShow = true;
              // } else {
              //   this.lpButtonShow = false;
              // }
              itemk.countSum = 0;
              itemk.packSum = 0;
              itemk.ordercommodityDetailList.forEach((itemj) => {
                console.log(itemj.colorQuantity);
                itemk.countSum = itemj.colorQuantity * 1 + itemk.countSum * 1;
              });
              console.log(itemk.packingRatioList);
              itemk.packingRatioList.forEach((itemv) => {
                itemk.packSum = itemv.fpeiBi * 1 + itemk.packSum * 1;
              });
              console.log(itemk.packSum);
              itemk.totalPrice = itemk.picePrice * itemk.countSum;
              itemk.ivolumesum =
                (itemk.ivolume / itemk.packSum / 1000000) * itemk.countSum;
              itemk.imageUrl = this.$locationUrl + "file/" + itemk.imageUrl;
              console.log(itemk.imageUrl);
              itemk.ordercommodityDetailList.forEach((items) => {
                items.imageUrls =
                  this.$locationUrl +
                  "file/abyFile/commodityFile/commodityColorImage/" +
                  items.fileName;
                items.imageUrlLists = [item.imageUrls];
                console.log(items.imageUrls);
              });
              itemk.shoppingCartDeatailListCopy = itemk.ordercommodityDetailList.slice(
                0,
                4
              );
            });
          });
        }
      );
    },
    addDcOrdercenterOrderClick2() {
      getDcOrdercenterOrder({ pageNum: 1, pageSize: 1000000 }).then((res) => {
        this.cartList = res.data.list;

        this.cartList.forEach((item) => {
          if (item.orderPaymentList && item.orderPaymentList.length != 0) {
            item.orderPaymentList.forEach((iteml) => {
              if (iteml.fileName) {
                iteml.imageUrl = this.$locationUrl + "file/" + iteml.fileUrl;
                iteml.imageUrlList = [iteml.imageUrl];
              }
            });
          }
          if (item.logisticsList && item.logisticsList.length != 0) {
            item.logisticsList.forEach((iteml) => {
              if (iteml.fileName) {
                iteml.imageUrl = this.$locationUrl + "file/" + iteml.fileUrl;
                iteml.imageUrlList = [iteml.imageUrl];
              }
            });
          }
          console.log(item);
          item.orderCommodityList.forEach((itemk) => {
            itemk.checked = false;
            // let haveDictnameLP = itemk.sellAttributeList.filter((itemp) => {
            //   return itemp.dictname == "零批";
            // });
            // if (haveDictnameLP.length != 0) {
            //   this.lpButtonShow = true;
            // } else {
            //   this.lpButtonShow = false;
            // }
            itemk.countSum = 0;
            itemk.packSum = 0;
            itemk.ordercommodityDetailList.forEach((itemj) => {
              console.log(itemj.colorQuantity);
              itemk.countSum = itemj.colorQuantity * 1 + itemk.countSum * 1;
            });
            itemk.packingRatioList.forEach((itemv) => {
              itemk.packSum = itemv.fpeiBi * 1 + itemk.packSum * 1;
            });
            console.log(itemk.packSum);
            if (itemk.countSum % itemk.packingQuantity != 0) {
              itemk.packingSum = Math.ceil(itemk.countSum / itemk.packingQuantity);
            } else {
              itemk.packingSum = itemk.countSum / itemk.packingQuantity;
            }
            itemk.totalPrice = itemk.picePrice * itemk.countSum;
            itemk.ivolumesum = (itemk.ivolume / itemk.packSum / 1000000) * itemk.countSum;
            itemk.imageUrl = this.$locationUrl + "file/" + itemk.imageUrl;
            console.log(itemk.imageUrl);
            itemk.ordercommodityDetailList.forEach((items) => {
              items.imageUrls =
                this.$locationUrl +
                "file/abyFile/commodityFile/commodityColorImage/" +
                items.fileName;
              items.imageUrlLists = [item.imageUrls];
              console.log(items.imageUrls);
            });
            itemk.shoppingCartDeatailListCopy = itemk.ordercommodityDetailList.slice(
              0,
              4
            );
          });
        });
        this.dialogVisible = false;
        this.dialogVisiblej = false;
        this.dialogVisibles = false;
      });
      this.$forceUpdate();
    },
    payClick(item) {
      this.dialogVisible = true;
      this.payItem = item;
    },
    payClickItem(item) {
      this.dialogVisibles = true;
      this.payItem = item;
    },
    payClickItemd(item) {
      this.dialogVisiblej = true;
      this.payItemj = item;
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisiblek = true;
    },
    handlePictureCardPreviewj(file) {
      this.dialogImageUrlj = file.url;
      this.dialogVisiblekd = true;
    },
    httprequest(e) {
      this.formData = new FormData();
      this.formData.append("image", e.file);
      this.formData.append("orderId", this.payItem.orderId);
      addCustomerOrderPaymentImage(this.formData).then((res) => {
        this.$message({
          message: "图片上传成功",
          type: "success",
        });
      });
    },
    httprequestj(e) {
      this.formData = new FormData();
      this.formData.append("image", e.file);
      this.formData.append("orderId", this.payItemj.orderId);
      addCustomerOrderLogisticsImage(this.formData).then((res) => {
        this.$message({
          message: "图片上传成功",
          type: "success",
        });
      });
    },
    codeChange(item) {
      console.log(item);
      var decrypted = CryptoJS.AES.decrypt(item, encryptionKey).toString(
        CryptoJS.enc.Utf8
      );
      console.log(decrypted);
      var bytes = window.atob(decrypted.split(",")[1]);
      //处理异常,将ascii码小于0的转换为大于0
      var ab = new ArrayBuffer(bytes.length);
      var ia = new Uint8Array(ab);
      for (var i = 0; i < bytes.length; i++) {
        ia[i] = bytes.charCodeAt(i);
      }
      const blob = new Blob([ab], { type: "image/png" });

      // 生成 Blob 对象的 URL 地址
      const imageUrl = URL.createObjectURL(blob);
      return imageUrl;
    },
    resetBorderColor() {},
    selectItem(event, index) {
      if (event == true) {
        this.$set(this.cartList[index], "checked", true);
      } else {
        this.$set(this.cartList[index], "checked", false);
      }
      this.buyCounterPcsSum = 0;
      this.buyCounterVolumeSum = 0;
      this.buyCounterCtnSum = 0;
      this.buyCounterMoneySum = 0;

      this.cartList.forEach((item) => {
        if (item.checked == true) {
          this.buyCounterPcsSum = this.buyCounterPcsSum * 1 + item.countSum;
          this.buyCounterVolumeSum = this.buyCounterVolumeSum * 1 + item.ivolumesum;
          this.buyCounterCtnSum = this.buyCounterCtnSum * 1 + item.packingSum;
          this.buyCounterMoneySum = this.buyCounterMoneySum * 1 + item.totalPrice;
        }
      });

      this.$forceUpdate();
    },

    // =====================================================修改箱数==============================================================

    // =====================================================零批数量减少==============================================================

    // =============================================================================================================================

    // =====================================================零批数量增加==============================================================

    // =============================================================================================================================
    muchShowClick(indexFather, index) {
      if (this.muchShow == true) {
        console.log(this.cartList[indexFather]);
        console.log(this.cartList[indexFather].orderCommodityList[index]);
        this.cartList[indexFather].orderCommodityList[
          index
        ].shoppingCartDeatailListCopy = this.cartList[indexFather].orderCommodityList[
          index
        ].ordercommodityDetailList;
      } else {
        this.cartList[indexFather].orderCommodityList[
          index
        ].shoppingCartDeatailListCopy = this.cartList[indexFather].orderCommodityList[
          index
        ].ordercommodityDetailList.slice(0, 4);
      }
      this.muchShow = !this.muchShow;
    },
    goToChangeItem(item) {
      // window.open(
      //   this.$locationViewUrl+"#/goods/Detail?cinvCode=" + item.cinvCode,
      //   "_blank"
      // );
      window.open(
        this.$locationViewUrl +
          "#/goods/cartItemDetail?cinvCode=" +
          item.cinvCode +
          "&brandName=" +
          this.brandName +
          "&shoppingCartId=" +
          item.shoppingCartId,
        "_blank"
      );
    },
    deleteCartItem(item) {
      this.$confirm("此操作将该商品从购物车中删除, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteDcShoppingmallShoppingcart(item.shoppingCartId).then((res) => {
            if (res.code == 200) {
              this.dialogVisiblep = true;
              getDcShoppingmallShoppingcart({ pageNum: 1, pageSize: 10 }).then((res) => {
                this.cartList = res.data;
                this.cartList.forEach((item) => {
                  item.imageUrl = this.$locationUrl + "file/" + item.imageUrl;
                  console.log(item.imageUrl);
                  item.shoppingCartDeatailList.forEach((items) => {
                    items.imageUrls =
                      this.$locationUrl +
                      "file/abyFile/commodityFile/commodityColorImage/" +
                      items.fileName;
                    items.imageUrlLists = [item.imageUrls];
                    console.log(items.imageUrls);
                  });
                });
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },

    buyGoods(item) {
      // this.$store.commit('getGoodsInfo', item)
      this.$router.push({ path: "/goods/goodOrderDetail", query: { brandName: this.brandName } });
    },
    getImageHeight() {
      this.dynamicHeight = this.$refs.images.height + "px";
    },
    sumChange(a, b) {
      console.log("3213123");
      console.log(a, b);
      if (a == 1) {
        this.allShop = this.allShop * 1 + b * 1;
      } else {
        this.allShop = this.allShop * 1 - b * 1;
      }
    },

    deleteMultipe() {
      this.dialogTableVisible = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@mixin wh($w, $h: $w) {
  width: $w;
  height: $h;
}
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;

  height: 60px;
}

li {
  display: inline-block;
  padding: 0 42px;
  border-bottom: 2px solid transparent; /* 初始下边框颜色 */
  transition: border-bottom-color 0.3s; /* 添加过渡效果 */
  // color: white;
}

li:hover {
  // text-decoration: underline;
  color: #29b8b6;
  border-bottom: 2px solid #eceff7;
}
//   overflow: scroll;
//   height: 100vh; /* 设置右侧模块高度，使其能够滚动 */
// background: lightgreen;
.counter1 {
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 50%;
  padding: 5px;
  border: 1px solid #8e8e8e;
  position: relative;
  line-height: 31px;
  border-radius: 40px;
}
.counters {
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
  padding: 5px;
  border: 1px solid #8e8e8e;
  position: relative;
  line-height: 11px;
  border-radius: 40px;
}

// .counters span {
//   position: absolute;
//   left: 50%;
//   font-size: 15px;
//   top: 50%;
//   transform: translate(-50%, -50%);
// }

// .counters::before,
// .counters::after {
//   content: "";
//   cursor: pointer;
// }

// .counters::before {
//   content: "-";
//   font-size: 12px;
//   margin-left: 4px;
// }

// .counters::after {
//   content: "+";
//   font-size: 12px;
//   margin-right: 4px;
// }
.counter {
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 70%;
  padding: 5px;
  border: 2px solid #d7d7d7;
  position: relative;

  border-radius: 12px;
  /* height: 40%; */
  line-height: 11px;
}
.shopping {
  .content {
    background-color: #fff;

    margin-bottom: 45px;
    font-family: "Microsoft YaHei";
    padding-bottom: 10px;
  }
}

.hd,
.cart-table-th {
  padding: 0 18px;
  align-items: center;
  justify-content: space-between;
}

.hd {
  height: 72px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e6e6e6;

  .car {
    color: #000;
    font-size: 18px;
    font-weight: 600;
  }

  .cart-sum {
    strong {
      font-size: 22px;
      font-weight: 700;
      color: #f50;
      margin-right: 12px;
    }

    a {
      $h: 42px;
      display: inline-block;
      text-align: center;
      line-height: $h;
      @include wh(74px, $h);
      background: #aaa;
      color: #fff;
      font-size: 16px;
      border-radius: 21px;
      cursor: not-allowed;
    }

    .active {
      background-color: #f50;
      cursor: pointer;
    }
  }
}

.cartmain {
  user-select: none;
  $h: 50px;

  .cart-table-th {
    padding: 0 25px;
    height: $h;
    margin-bottom: 15px;
  }

  .th {
    height: 100%;
    color: #3c3c3c;
    font-weight: bold;
    font-size: 14px;
    line-height: $h;
  }

  .default-list {
    color: #aaaaaa;
    font-size: 25px;
    font-weight: 700;
    text-align: center;
    line-height: 300px;
  }
}
</style>
<style lang="scss">
.th {
  flex: 2;
}

.th-chk {
  flex: 1.2;

  // width: 150px;
  input,
  label {
    cursor: pointer;
  }

  input {
    vertical-align: middle;
    margin-right: 8px;
  }

  label {
    width: 50px;
    height: 50px;
    display: inline-block;
  }
}

.th-info {
  flex: 2.8;
}

.th-price,
.th-amount,
.th-sum,
.th-op {
  text-align: center;
}
</style>

<template>
    <div>
        <el-dialog v-dialogDrag title="详情" :visible.sync="dialogVisible" width="1200px" center
            :close-on-click-modal="false" :append-to-body="true">
            <div class="top" style="margin-bottom: 20px;">
                    <span class="title"></span>
                    <div>流程详情</div>
                </div>
            <div class="block">
                <el-timeline>
                    <div v-for="(item, index) in list" :key="index">
                        <el-timeline-item :timestamp="item.createdDate" placement="top">
                            <el-card>
                                <h4 v-if="item.type == 0 || item.type == 2">投诉人：{{ item.createdBy }}</h4>
                                <h4 v-else>客服：{{ item.createdBy }}</h4>
                                <p>{{ item.replyText }} </p>
                                <div>{{ item.createdDate }}</div>
                            </el-card>
                        </el-timeline-item>
                    </div>

                </el-timeline>
            </div>

            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisibles = true" size="mini" type="primary">回复投诉人</el-button>
                <el-button @click="dialogVisible = false" size="mini" type="primary">取 消</el-button>
            </span>
            <el-dialog v-dialogDrag title="回复" :visible.sync="dialogVisibles" width="720px" center
                :close-on-click-modal="false" :append-to-body="true">
                <div style="">
                    <el-form class="form" ref="form" :model="form" label-width="auto">
                        <!-- <el-form-item label="官方邮箱" prop="email">
                    <el-input v-model.trim="form.email" autocomplete="off" placeholder="请输入官方邮箱" maxlength="50"></el-input>
                </el-form-item>
                <el-form-item label="咨询微信" prop="wechat">
                    <el-input v-model.trim="form.wechat" autocomplete="off" placeholder="请输入微信,多个通过/区分" maxlength="50"
                        type="textarea"></el-input>
                  
                </el-form-item> -->

                        <el-form-item label="图片" prop="imageUrl">
                            <div class="demo-image__preview">
                                <el-upload :class="{ styleA: file_list.length === 0, styleB: file_list.length === 1 }"
                                    :action="path + '/api/file'" accept=".jpg,.jpeg,.png,.JPG,.JPEG,.mp4,.webp"
                                    list-type="picture-card" :headers="{ ['ACCESS-TOKEN']: accessToken }" :limit="1"
                                    ref="upload" :before-upload="beforeUploadOther" :on-change="beforeUpload"
                                    :on-preview="handlePreview" :on-exceed="handleExceed" :on-success="handleSuccess"
                                    :on-remove="handleRemove" :file-list="file_list">
                                    <i slot="default" class="el-icon-plus"></i>
                                    <div slot="file" slot-scope="{ file }">
                                        <img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
                                        <span class="el-upload-list__item-actions">
                                            <span class="el-upload-list__item-preview"
                                                @click="handlePictureCardPreview(file)">
                                                <i class="el-icon-zoom-in"></i>
                                            </span>
                                            <span v-if="!disabled" class="el-upload-list__item-delete"
                                                @click="handleRemove(file)">
                                                <i class="el-icon-delete"></i>
                                            </span>
                                        </span>
                                    </div>
                                </el-upload>
                                <el-dialog :visible.sync="dialogVisibles" append-to-body width="30%">
                                    <img width="100%" :src="dialogImageUrl" alt="" />
                                </el-dialog>
                            </div>

                        </el-form-item>
                        <el-form-item label="备注" prop="landLine" style="width:100%">
                            <el-input v-model.trim="form.landLine" type="textarea"
                                :autosize="{ minRows: 6, maxRows: 10 }" placeholder="请输入内容" v-model="textarea2"
                                style="width:100%;"></el-input>

                        </el-form-item>

                    </el-form>
                </div>
              
                <span slot="footer" class="dialog-footer">
                    <el-button size="mini" type="primary" @click="clickOver">回复</el-button>

                </span>
            </el-dialog>
        </el-dialog>

    </div>
</template>

<script>

// import { GetzfReply, AddzfReply } from "../../../api/room.js";
export default {
    data() {
        return {
            dialogVisible: false,
            dialogVisibles: false,
            list: [],
            form:{},
            item: {},
            model: { refundReasonWap: '' },
            file_list:[]
        }
    },
    methods: {
        show(row) {
            // console.log(row)
            // this.item = Object.assign({}, row)
            // GetzfReply({
            //     zfComplaintId: row.id,
            //     pageNum: 1,
            //     pageSize: 1000
            // }).then(res => {

            // })
            this.list = [
                {
                    "id": "493270767192612864",
                    "zfComplaintId": "468574061568364544",
                    "replyText": "dasdad",
                    "parentId": null,
                    "createdId": null,
                    "createdBy": null,
                    "createdDate": "2024-04-02",
                    "type": 2
                },
                {
                    "id": "493270798419230720",
                    "zfComplaintId": "468574061568364544",
                    "replyText": "weqdasdweqe",
                    "parentId": null,
                    "createdId": null,
                    "createdBy": null,
                    "createdDate": "2024-04-02",
                    "type": 2
                },
                {
                    "id": "493273537167548416",
                    "zfComplaintId": "468574061568364544",
                    "replyText": "111",
                    "parentId": null,
                    "createdId": null,
                    "createdBy": null,
                    "createdDate": "2024-04-02",
                    "type": 1
                },
                {
                    "id": "493275838534238208",
                    "zfComplaintId": "468574061568364544",
                    "replyText": "好的，我收到了。马上会处理",
                    "parentId": null,
                    "createdId": null,
                    "createdBy": "222",
                    "createdDate": "2024-04-02",
                    "type": 1
                }
            ]
            this.dialogVisible = true
        },
        close() {
            this.dialogVisible = false;
        },
        clickOver() {
            AddzfReply({
                replyText: this.model.refundReasonWap,
                zfComplaintId: this.item.id,
                createdBy: sessionStorage.getItem('staffName'),
                type: 1,
            }).then(res => {
                GetzfReply({
                    zfComplaintId: this.item.id,
                    pageNum: 1,
                    pageSize: 1000
                }).then(res => {
                    this.list = res.pageInfo.list
                })
                this.model.refundReasonWap = ''
                this.dialogVisibles = false;
            })
        },
        handleIndex(index, item) {
            this.classIndex = index
            this.activeName = item.value
        },
    }
}
</script>
<style scoped>
.no-received-every-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    box-sizing: border-box;
    flex-wrap: wrap;
}

.no-received-every-top-Price {
    font-size: 18px;
    color: #092553;
    letter-spacing: -.54px;
    font-weight: 900;
}

.no-received-every-top-amount {
    font-size: 14px;
    font-family: SourceHanSansCN-Medium;
    color: #8994af;
    margin-right: 10px;
    padding: 10px 0;
}

.overdue {
    padding: 0 5px;
    height: 20px;
    line-height: 20px;
    display: inline-block;
    background: rgba(249, 21, 21, .08);
    border-radius: 4px;
    font-size: 12px;
    color: #f91515;
    margin-left: 10px;
}

.no-received-every-top-name {
    font-size: 14px;
    font-family: SourceHanSansCN-Medium;
}

.no-received {
    border: 2px solid #f2f4f8;
    margin-bottom: 10px;
}

.no-received-every-top {
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    font-size: 14px;
    color: #092553;
    background-color: #f2f4f8;
    height: 60px;
    line-height: 60px;
}

.box {
    width: 50%;
    font-size: 14px;
    color: #627393;
    font-weight: 600;
    height: 40px;
    line-height: 40px;
}

.box-active {
    width: 100%;
    font-size: 14px;
    color: #627393;
    font-weight: 600;
    height: 40px;
    line-height: 40px;
    display: flex;
    justify-content: space-between;
}

.table-header {
    width: 100%;
    height: 50px;
    line-height: 50px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    font-family: SourceHanSansCN-Medium;
    color: #092553;
    font-weight: 600;
    font-size: 16px;
}

.table-header-title {
    border-radius: 2.5px;
    width: 5px;
    height: 16px;
    margin-right: 15px;
    background-color: #28bd9c;
}

.flex {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
}

.pay {
    color: #22ab8c;
    background-color: #eefaf7;
}

.Late {
    background-color: #ffeded;
    color: #f91515;
}

.center {
    margin-top: 20px;
    padding: 10px;
    box-sizing: border-box;
}

/deep/ .el-dialog__title {
    font-size: 18px;
    color: #092553;
    position: relative;
    font-weight: 700;
}

.content {
    padding: 10px;
    box-sizing: border-box;
    width: 100%;
    text-align: center;
}

.top {
    width: 100%;
    height: 50px;
    line-height: 50px;
    box-sizing: border-box;
    background-color: #f2f4f8;
    padding-left: 15px;
    display: flex;
    align-items: center;
    font-family: SourceHanSansCN-Medium;
    color: #092553;
    font-weight: 500;
    font-size: 18px;
}

.title {
    border-radius: 2.5px;
    width: 5px;
    height: 16px;
    margin-right: 15px;
    background-color: #28bd9c;
}
</style>
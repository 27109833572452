import { isNumber, isPhone, isIdCard, isEmail, isFloatNum, isTel, isHaveBlank, isUsername } from './validate'

/**
 * @description 判断是否为数字
 */
export function isNumberValid(rule, value, callback) {
  if (!isNumber(value)) {
    callback(new Error('请输入正整数'))
  }
  callback()
}
/**
 * @description 判断是否是手机号
 */
export function isPhoneValid(rule, value, callback) {
  if (!isPhone(value)) {
    callback(new Error('请输入正确的手机号'))
  }
  callback()
}
/**
 * @description 判断是否为固话
 */
export function isTelValid(rule, value, callback) {
  if (!isTel(value)) {
    callback(new Error('请输入正确的固话'))
  }
  callback()
}
/**
 * @description 判断是否是身份证号(第二代)
 */
export function isIdCardValid(rule, value, callback) {
  if (!isIdCard(value)) {
    callback(new Error('请输入正确的身份证号'))
  }
  callback()
}
/**
 * @description 判断是否是邮箱
 */
export function isEmailValid(rule, value, callback) {
  if (!isEmail(value)) {
    callback(new Error('请输入正确的邮箱'))
  }
  callback()
}
/**
 * @description 判断是否为数字且最多两位小数
 */
export function isFloatNumValid(rule, value, callback) {
  if (!isFloatNum(value)) {
    callback(new Error('请输入数字且最多两位小数'))
  }
  callback()
}
/**
 * @description 判断是否含有空格
 */
export function isHaveBlankValid(rule, value, callback) {
  if (!isHaveBlank(value)) {
    callback(new Error('不能含有空格'))
  }
  callback()
}
/**
 * @description 判断是否包含数字、大小写字母中至少两种字符的1-20位字符
 */
export function isUsernameValid(rule, value, callback) {
  if (!isUsername(value)) {
    callback(new Error('需包含数字、大小写字母中的1-30位字符'))
  }
  callback()
}

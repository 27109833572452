<template>
  <div class="InfoWrap com-mouse-default">
    <div class="goodsDetail">
      <h3 class="InfoName">
        {{ info.content }}
      </h3>
      <p class="news">
        {{ skuDesc }}
      </p>
      <div class="priceArea">
        <div class="priceArea1 com-flex">
          <div class="com-flex" style="display: flex; align-items: center">
            <div class="title">价&emsp;&ensp;格</div>
            <div class="price">
              <i>¥</i>
              <em>{{ info.price }}</em
              >/件
            </div>
            <div class="remark" style="margin-left: 20px">
              <i>月销量:768箱</i>
            </div>
          </div>
        </div>
        <div class="priceArea2">
          <div class="title">
            <i>商品介绍：</i>
          </div>
          <div class="fixWidth">
            <em class="t-gray"
              >这是商品介绍，这是商品介绍，这是商品介绍，这是商品介绍，这是商品介绍，这是商品介绍，这是商品介绍，这是商品介绍，这是商品介绍，这是商品介绍</em
            >
          </div>
        </div>
      </div>
      <div class="support">
        <div class="supportArea">
          <div class="title">剩余库存：</div>
          <div class="fixWidth">100个</div>
        </div>
        <div class="supportArea">
          <div class="title">送货地址：</div>
          <div class="fixWidth">广东省 广州市 天河区</div>
        </div>
      </div>
    </div>

    <!-- 属性列表 -->
    <div class="choose">
      <div class="cartWrap">
        <div class="controls com-flex">
          <div>
            <span>数&emsp;&ensp;量</span>
            <el-input-number
              v-model="skuNum"
              @change="handleChange"
              :min="1"
              :max="10"
              label="描述文字"
            ></el-input-number
            >&ensp;个
          </div>
        </div>
        <div class="add com-mouse-point">
          <a class="addCart buy" @click="addCarts">立即购买</a>
          <a class="addCart" @click="addCart"
            ><span class="iconfont icon-gouwuche-tianchong"></span> 加入购物车</a
          >
        </div>
      </div>
    </div>
    <el-dialog title="购物车详情" :visible.sync="dialogTableVisible" width="1400px">
      <shopCarts ref="shopCarts"></shopCarts>
    </el-dialog>
    <el-dialog title="支付详情" :visible.sync="dialogTableVisibles" width="550px">
      <div style="display: flex">
        <div>
          <div style="margin-left: 31px">支付宝支付：</div>
          <img
            src=" https://www.gqedu.org.cn:6868/manage/61b18e454c15444aa5102c76fab0cfc0.png"
          />
        </div>
        <div>
          <div style="margin-left: 31px">微信支付：</div>
          <img
            src=" https://www.gqedu.org.cn:6868/manage/61b18e454c15444aa5102c76fab0cfc0.png"
          />
        </div>
      </div>
      <div style="display: flex; justify-content: center">
        <el-button type="success" @click="dialogTableVisiblea = true"
          >上传支付凭证</el-button
        >
      </div>
    </el-dialog>
    <el-dialog title="上传凭证" :visible.sync="dialogTableVisiblea" width="550px">
      <el-upload action="#" list-type="picture-card" :auto-upload="false">
        <i slot="default" class="el-icon-plus"></i>
        <div slot="file" slot-scope="{ file }">
          <img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
          <span class="el-upload-list__item-actions">
            <span
              class="el-upload-list__item-preview"
              @click="handlePictureCardPreview(file)"
            >
              <i class="el-icon-zoom-in"></i>
            </span>
            <span
              v-if="!disabled"
              class="el-upload-list__item-delete"
              @click="handleDownload(file)"
            >
              <i class="el-icon-download"></i>
            </span>
            <span
              v-if="!disabled"
              class="el-upload-list__item-delete"
              @click="handleRemove(file)"
            >
              <i class="el-icon-delete"></i>
            </span>
          </span>
        </div>
      </el-upload>
    </el-dialog>
  </div>
</template>

<script>
import shopCarts from "../shopCarts/index.vue";
import { mapMutations } from "vuex";
export default {
  name: "InfoDetail",
  props: {
    info: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    shopCarts,
  },
  data() {
    return {
      dialogTableVisibles: false,
      dialogTableVisible: false,
      dialogTableVisiblea: false,
      skuNum: 1,

      skuDesc: "全场正品 达人推荐 无忧退换",
    };
  },
  methods: {
    ...mapMutations(["addProd"]),
    sub() {
      if (this.skuNum <= 1) {
        this.skuNum = 1;
        return;
      }
      this.skuNum--;
    },
    add() {
      this.skuNum++;
    },
    addCart() {
      this.dialogTableVisible = true;
    },
    addCarts() {
      this.dialogTableVisibles = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.el-input-number {
  width: 145px !important;
}

.InfoWrap {
  width: 700px;

  .InfoName {
    font-size: 14px;
    line-height: 21px;
    margin-top: 15px;
  }

  .news {
    color: #3c3c3c;
    margin-top: 15px;
  }

  .priceArea {
    background: #fff2e8;
    padding: 7px;
    margin: 13px 0;

    .priceArea1 {
      overflow: hidden;
      line-height: 28px;
      margin-top: 10px;
      justify-content: space-between;

      .title {
        margin-right: 15px;
      }

      .price {
        i,
        em {
          color: #f40;
        }

        i {
          font-size: 16px;
        }

        em {
          font-size: 24px;
          font-weight: 700;
          margin-left: 5px;
        }

        span {
          font-size: 12px;
        }
      }

      .remark {
        em,
        i {
          text-align: center;
          display: block;
          line-height: 13px;
          height: 15px;
        }
      }
    }

    .priceArea2 {
      overflow: hidden;
      line-height: 28px;
      margin-top: 10px;

      .title {
        margin-right: 15px;
        float: left;
      }

      .fixWidth {
        width: 520px;
        float: left;

        .red-bg {
          background: #f40;
          color: #fff;
          padding: 3px;
        }

        .t-gray {
          color: #999;
        }
      }
    }
  }

  .support {
    border-bottom: 1px solid #ededed;
    padding-bottom: 5px;

    .supportArea {
      overflow: hidden;
      line-height: 28px;
      margin-top: 10px;

      .title {
        margin-right: 15px;
        float: left;
      }

      .fixWidth {
        width: 520px;
        float: left;
        color: #999;
      }
    }
  }

  .choose {
    .chooseArea {
      overflow: hidden;
      line-height: 28px;
      margin-top: 10px;

      dl {
        overflow: hidden;
        margin: 13px 0;

        dt {
          margin-right: 15px;
          float: left;
        }

        dd {
          float: left;
          margin-right: 5px;
          color: #666;
          line-height: 24px;
          padding: 2px 14px;
          border-top: 1px solid #eee;
          border-right: 1px solid #bbb;
          border-bottom: 1px solid #bbb;
          border-left: 1px solid #eee;

          &.active {
            color: green;
            border: 1px solid green;
          }
        }
      }
    }

    .cartWrap {
      .controls {
        position: relative;

        margin: 15px 0;

        .itxt {
          width: 38px;
          height: 34px;
          border: 1px solid #ddd;
          color: #555;
          border-right: 0;
          text-align: center;
          outline: none;
        }

        .plus,
        .mins {
          width: 27px;
          text-align: center;
          height: 38px;
          background: #f1f1f1;
          color: #666;
        }

        span {
          margin-right: 11px;
        }
      }

      .add {
        a {
          width: 180px;
          background-color: #f40;
          padding: 0 25px;
          font-size: 16px;
          color: #fff;
          height: 36px;
          line-height: 36px;
          text-align: center;
          display: inline-block;
          border-radius: 3px;
        }

        .buy {
          width: 134px;
          color: #e5511d;
          border-color: #f0cab6;
          background: #ffe4d0;
          margin-right: 20px;
          padding: 0;
        }
      }
    }
  }
}
</style>

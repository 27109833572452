<template>
  <div style="border: 1px solid #bfbfbf">
    <div
      style="background: #f1f1f1; display: flex; line-height: 50px; padding-left: 44px"
    >
      <div style="font-size: 15px; font-weight: 700">下单时间:2024-04-09</div>
      <div style="margin-left: 10px">订单号:76432642480480949242348</div>
    </div>
    <div style="display: flex" class="shopitem com-flex">
      <div
        class="th th-chk th-info"
        style="display: flex; border-right: 1px solid #bfbfbf"
      >
        <img :src="shopIt.img" />
        <div style="margin-left: 10px">
          <p>{{ shopIt.content }}</p>
          <div class="icon com-flex">
            <!-- <img src="@images/pic_029.png" />
        <img src="@images/pic_030.png" />
        <img src="@images/pic_031.png" /> -->
          </div>
        </div>
      </div>
      <div class="th th-price" style="border-right: 1px solid #bfbfbf; line-height: 76px">
        <span>&yen;</span>{{ shopIt.price }}
      </div>
      <div class="th th-price" style="border-right: 1px solid #bfbfbf; line-height: 76px">
        {{ shopIt.num }}
      </div>
      <div class="th th-price" style="border-right: 1px solid #bfbfbf; line-height: 76px">
        箱
      </div>
      <div class="th th-price" style="border-right: 1px solid #bfbfbf; line-height: 76px">
        100
      </div>
      <div class="th th-price" style="border-right: 1px solid #bfbfbf; line-height: 38px">
        <div style="color: #ff6000"><span>&yen;</span>{{ total }}</div>
        <div v-if="indexNumbers == 2">(已付定金:230000)</div>
        <div>(不包含运费)</div>
      </div>
      <div class="th th-op" style="line-height: 32px">
        <div v-if="indexNumbers != 2">交易成功</div>
        <div v-if="indexNumbers == 2">支付</div>
        <div @click="shopIt.show = !shopIt.show" v-if="shopIt.type == 1">订单详情</div>
        <div @click="shopIt.show = !shopIt.show" v-if="shopIt.type == 2">订单详情</div>
        <div @click="dialogTableVisibles=true" v-if="indexNumbers == 2">
          支付
        </div>
        <div @click="dialogTableVisiblea=true" v-if="indexNumbers == 2">
          上传支付凭证
        </div>
        
      </div>
    </div>

    <div style="display: flex; padding-left: 30px; padding-right: 30px">
      <div v-if="shopIt.show == true && shopIt.type == 1">
        <div
          style="
            border-bottom: 1px solid #dddddd;
            border-top: 1px solid #dddddd;
            padding-top: 30px;
          "
        >
          <el-descriptions title="买家消息">
            <el-descriptions-item label="公司名称">奥比亚客户</el-descriptions-item>
            <el-descriptions-item label="联系人">小王</el-descriptions-item>
            <el-descriptions-item label="联系人电话">18100000000</el-descriptions-item>
            <el-descriptions-item label="收获地址"
              >江苏省苏州市吴中区吴中大道 1188 号</el-descriptions-item
            >
          </el-descriptions>
        </div>
        <div style="border-bottom: 1px solid #dddddd; margin-top: 30px">
          <el-descriptions title="订单信息">
            <el-descriptions-item label="订单编号"
              >76432642480480949242348</el-descriptions-item
            >
            <el-descriptions-item label="物流编号"
              >AO-BI-YA-2024-4-30</el-descriptions-item
            >
            <el-descriptions-item label="商品名称"
              >CHRISBELLA-双手腕女包-BG012211222</el-descriptions-item
            >
            <el-descriptions-item label="颜色配比"
              >橙色:3,粉色:2,黑色:7,黄色:3,蓝色:4,绿色:2,玫红:3</el-descriptions-item
            >
            <el-descriptions-item label="购买数量">300</el-descriptions-item>
            <el-descriptions-item label="商品单价">4100</el-descriptions-item>
            <el-descriptions-item label="支付金额">1230000.00</el-descriptions-item>
            <el-descriptions-item label="下单时间">2024-04-25</el-descriptions-item>
          </el-descriptions>
        </div>
        <div style="border-bottom: 1px solid #dddddd; margin-top: 30px" v-if='indexNumbers==5'>
          <el-descriptions title="物流单">
            <el-descriptions-item label="物流单图片">
              <div
                class="demo-image__preview"
                v-for="(item, index) in srcList2"
                :key="index"
                style="margin-right: 20px"
              >
                <el-image
                  style="width: 100px; height: 100px"
                  :src="item"
                  :preview-src-list="[item]"
                >
                </el-image>
              </div>
            </el-descriptions-item>
          </el-descriptions>
        </div>
      
        <div style="border-bottom: 1px solid #dddddd; margin-top: 30px">
          <el-descriptions title="支付凭证">
            <el-descriptions-item label="支付凭证图片">
              <div
                class="demo-image__preview"
                v-for="(item, index) in srcList3"
                :key="index"
                style="margin-right: 20px"
              >
                <el-image
                  style="width: 100px; height: 100px"
                  :src="item"
                  :preview-src-list="[item]"
                >
                </el-image>
              </div>
            </el-descriptions-item>
          </el-descriptions>
        </div>
      </div>
      <div
        style="display: flex; flex-wrap: wrap"
        v-if="shopIt.show == true && shopIt.type == 2"
      >
        <div
          style="
            border-bottom: 1px solid #dddddd;
            border-top: 1px solid #dddddd;
            padding-top: 30px;
          "
        >
          <el-descriptions title="买家消息">
            <el-descriptions-item label="公司名称">奥比亚客户</el-descriptions-item>
            <el-descriptions-item label="联系人">小王</el-descriptions-item>
            <el-descriptions-item label="联系人电话">18100000000</el-descriptions-item>
            <el-descriptions-item label="收获地址"
              >江苏省苏州市吴中区吴中大道 1188 号</el-descriptions-item
            >
          </el-descriptions>
        </div>
        <div style="border-bottom: 1px solid #dddddd; margin-top: 30px">
          <el-descriptions title="卖家消息">
            <el-descriptions-item label="公司名称">奥比亚</el-descriptions-item>
            <el-descriptions-item label="联系人">王试试</el-descriptions-item>
            <el-descriptions-item label="联系电话">18100000000</el-descriptions-item>
            <el-descriptions-item label="发货地址"
              >江苏省苏州市吴中区吴中大道 1188 号</el-descriptions-item
            >
          </el-descriptions>
        </div>
        <div style="border-bottom: 1px solid #dddddd; margin-top: 30px">
          <el-descriptions title="订单信息">
            <el-descriptions-item label="订单编号"
              >76432642480480949242348</el-descriptions-item
            >
            <el-descriptions-item label="物流编号"
              >AO-BI-YA-2024-4-30</el-descriptions-item
            >
            <el-descriptions-item label="商品名称"
              >CHRISBELLA-双手腕女包-BG012211222</el-descriptions-item
            >

            <el-descriptions-item label="购买数量">300</el-descriptions-item>
            <el-descriptions-item label="商品单价">4100</el-descriptions-item>
            <el-descriptions-item label="支付金额">1230000.00</el-descriptions-item>
            <el-descriptions-item label="下单时间">2024-04-25</el-descriptions-item>
            <el-descriptions-item label="颜色购买个数">
              <div style="display: flex; flex-wrap: wrap">
                <div style="margin-right: 10px; margin-top: 10px">
                  橙色：<el-input-number
                    v-model="num1"
                    :min="1"
                    :max="1000"
                    disabled
                    label=""
                  ></el-input-number>
                </div>
                <div style="margin-right: 10px; margin-top: 10px">
                  粉色：<el-input-number
                    v-model="num2"
                    :min="1"
                    :max="1000"
                    disabled
                    label=""
                  ></el-input-number>
                </div>
                <div style="margin-right: 10px; margin-top: 10px">
                  黑色：<el-input-number
                    v-model="num3"
                    :min="1"
                    :max="1000"
                    disabled
                    label=""
                  ></el-input-number>
                </div>
                <div style="margin-right: 10px; margin-top: 10px">
                  黄色：<el-input-number
                    v-model="num4"
                    :min="1"
                    :max="1000"
                    disabled
                    label=""
                  ></el-input-number>
                </div>
                <div style="margin-right: 10px; margin-top: 10px">
                  蓝色：<el-input-number
                    v-model="num5"
                    :min="1"
                    :max="1000"
                    disabled
                    label=""
                  ></el-input-number>
                </div>
                <div style="margin-right: 10px; margin-top: 10px">
                  绿色：<el-input-number
                    v-model="num6"
                    :min="1"
                    :max="1000"
                    disabled
                    label=""
                  ></el-input-number>
                </div>
                <div style="margin-right: 10px; margin-top: 10px">
                  玫红：<el-input-number
                    v-model="num7"
                    :min="1"
                    :max="1000"
                    disabled
                    label=""
                  ></el-input-number>
                </div>
              </div>
            </el-descriptions-item>
          </el-descriptions>
        </div>
        <div style="border-bottom: 1px solid #dddddd; margin-top: 30px" v-if='indexNumbers==5'>
          <el-descriptions title="物流单">
            <el-descriptions-item label="物流单图片">
              <div
                class="demo-image__preview"
                v-for="(item, index) in srcList"
                :key="index"
                style="margin-right: 20px"
              >
                <el-image
                  style="width: 100px; height: 100px"
                  :src="item"
                  :preview-src-list="[item]"
                >
                </el-image>
              </div>
            </el-descriptions-item>
          </el-descriptions>
        </div>
        <div style="border-bottom: 1px solid #dddddd; margin-top: 30px">
          <el-descriptions title="支付凭证">
            <el-descriptions-item label="支付凭证图片">
              <div
                class="demo-image__preview"
                v-for="(item, index) in srcList4"
                :key="index"
                style="margin-right: 20px"
              >
                <el-image
                  style="width: 100px; height: 100px"
                  :src="item"
                  :preview-src-list="[item]"
                >
                </el-image>
              </div>
            </el-descriptions-item>
          </el-descriptions>
        </div>
      </div>
    </div>
    <el-dialog title="支付详情" :visible.sync="dialogTableVisibles" width="550px">
      <div style="display: flex">
        <div>
          <div style="margin-left: 31px">支付宝支付：</div>
          <img
            src=" https://www.gqedu.org.cn:6868/manage/61b18e454c15444aa5102c76fab0cfc0.png"
          />
        </div>
        <div>
          <div style="margin-left: 31px">微信支付：</div>
          <img
            src=" https://www.gqedu.org.cn:6868/manage/61b18e454c15444aa5102c76fab0cfc0.png"
          />
        </div>
      </div>
      <div style="display: flex; justify-content: center">
        <el-button type="success" @click="dialogTableVisiblea = true"
          >上传支付凭证</el-button
        >
      </div>
    </el-dialog>
    <el-dialog title="上传凭证" :visible.sync="dialogTableVisiblea" width="550px">
      <el-upload action="#" list-type="picture-card" :auto-upload="false">
        <i slot="default" class="el-icon-plus"></i>
        <div slot="file" slot-scope="{ file }">
          <img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
          <span class="el-upload-list__item-actions">
            <span
              class="el-upload-list__item-preview"
              @click="handlePictureCardPreview(file)"
            >
              <i class="el-icon-zoom-in"></i>
            </span>
            <span
              v-if="!disabled"
              class="el-upload-list__item-delete"
              @click="handleDownload(file)"
            >
              <i class="el-icon-download"></i>
            </span>
            <span
              v-if="!disabled"
              class="el-upload-list__item-delete"
              @click="handleRemove(file)"
            >
              <i class="el-icon-delete"></i>
            </span>
          </span>
        </div>
      </el-upload>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "ShopItem",
  data() {
    return {
      dialogTableVisibles:false,
      dialogTableVisiblea:false,
      srcList: [
        "https://www.gqedu.org.cn:6868/manage/3eace233ebd44e79a2f220918ad524b5.png",
        "https://www.gqedu.org.cn:6868/manage/ec15f9fbd5d9447dab76289eeabca8d0.png",
      ],
      srcList2: [
        "https://www.gqedu.org.cn:6868/manage/64ff28809f1e4da58da6eb808d5e6255.png",
        "https://www.gqedu.org.cn:6868/manage/ceab4a9ea17c4968bc1da760ce4653fe.png",
      ],
      srcList3: [
        "https://www.gqedu.org.cn:6868/manage/c39fc740a0914238b3525326771646d6.jpg",
        "https://www.gqedu.org.cn:6868/manage/c0c865a1672c49a9a3425f045ac5332b.png",
      ],
      srcList4: [
        "https://www.gqedu.org.cn:6868/manage/e59c143c29ca4e538ef0736e506ee0f7.png",
        "https://www.gqedu.org.cn:6868/manage/ebfe7e9d9f2e4d8c9702ae8102e4ec3f.png",
      ],
      sonShowNumber: 0,
      num1: 10,
      num2: 10,
      num3: 10,
      num4: 10,
      num5: 10,
      num6: 10,
      num7: 10,
      shopItem: {},
      tableData: [
        {
          date: "1",
          name: "4500",
          address: "橙色:3,粉色:2,黑色:7,黄色:3,蓝色:4,绿色:2,玫红:3",
        },
        {
          date: "100",
          name: "4300",
          address: "橙色:3,粉色:2,黑色:7,黄色:3,蓝色:4,绿色:2,玫红:3",
        },
        {
          date: "300",
          name: "4100",
          address: "橙色:3,粉色:2,黑色:7,黄色:3,蓝色:4,绿色:2,玫红:3",
        },
        {
          date: "600",
          name: "3800",
          address: "橙色:3,粉色:2,黑色:7,黄色:3,蓝色:4,绿色:2,玫红:3",
        },
      ],
    };
  },
  props: {
    shopIt: {
      type: Object,
      default: () => {},
    },
    indexNumbers: {
      type: String,
      default: "",
    },
  },
  computed: {
    total() {
      const result = this.shopIt.num * this.shopIt.price;
      return result.toFixed(2);
    },
  },
  methods: {
    handleChange() {
      if (this.shopIt.checked == true) {
        this.$emit("BackFathers", 1, this.shopIt.num * this.shopIt.price);
      }
    },

    sub() {
      if (this.shopItem.num <= 1) {
        this.shopItem.num = 1;
        return;
      }
      this.subProd(this.shopItem.id, this.shopItem);
      // this.shopItem.num--;
    },
    add() {
      this.addProd(this.shopItem);
      // this.shopItem.num++;
    },
    changeStatus() {
      if (this.shopIt.checked == false) {
        console.log(1);
        this.$emit("BackFather", 1, this.total);
      } else {
        console.log(2);
        this.$emit("BackFather", 2, this.total);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@mixin wh($w, $h: $w) {
  width: $w;
  height: $h;
}

.shopitem {


  padding: 20px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;

  .th-chk {
    input {
      margin-top: -50%;
      transform: translateY(50%);
    }

    img {
      @include wh(80px);
    }
  }

  .th-info {
    .icon {
      margin-top: 40px;
      width: 16px;

      img {
        margin-right: 6px;
      }
    }
  }

  .th-price,
  .th-sum {
    font-weight: 700;
    font-size: 14px;
  }

  .th-price {
    color: #3c3c3c;
    margin-top: 6px;
    margin-left: 10px;
  }

  .th-sum {
    color: #f50;
  }

  .th-amount {
    height: 25px;
    margin-left: 24px;

    span {
      width: 19px;
      line-height: 25px;
      border: 1px solid #e5e5e5;
      background: #f0f0f0;
      font-size: 16px;
    }

    input {
      width: 39px;
      height: 100%;
      border: 1px solid #aaa;
      color: #343434;
      text-align: center;
      outline: none;
    }

    .sub:hover,
    .add:hover {
      color: #f50;
      border-color: #f60;
    }
  }

  .th-op {
    margin-top: 5px;

    span:hover {
      cursor: pointer;
      color: #f50;
      text-decoration: underline;
    }
  }

  &:hover {
    border-color: #ff915e;
  }
}
</style>

<template>
    <div id="goods">

        <div class="goods_list wrap clearfix">
            <div class="item" v-for="item in goodsList" @click="buyGoods(item)">
                <dl>
                    <dt>
                        <img :src="item.cover | fullPath" alt="" />
                    </dt>
                    <dd class="title">
                        {{ item.name }}
                    </dd>
                    <dd class="other">
                        <span class="price fl">￥{{ (parseFloat(item.cost)) }}</span>
                        <span class="price fl" style="margin-left: 40px;">我已购买：5000</span>
                    </dd>
                  
                    <dd class="other">
                        <span class="price fl">最低起购量：1000</span>
                    </dd>
                    <dd class="btn">立即购买</dd>
                </dl>
            </div>
        </div>
        <div class="home_fy_block wrap">
            <el-pagination background @current-change="handleCurrentChange" :current-page="formData.pageNo"
                :page-size="formData.pageSize" layout="total, prev, pager, next, jumper" :total="total">
            </el-pagination>
        </div>
    </div>
</template>

<script>
export default {
    name: "",
    data() {
        return {
            sonShow: false,
            formData: {
                pageNo: 1,
                pageSize: 20,
                sort: "",
                storeId: "",
                type: "",
                tenantid: localStorage.getItem('tenantInfoid')
            },
            total: 0,

            goodsList: [{ cover: 'https://www.gqedu.org.cn:6868/manage/1b939a9bd48e40dfa3b4442f0ff25e16.JPG', name: 'CHRISBELLA-女包-双手腕', cost: 1000, },
            { cover: 'https://www.gqedu.org.cn:6868/manage/8e7f8f051fe345f29c54c9ecf9cf5a72.JPG', name: 'BAGCO-女包-双手腕', cost: 1000, },
            { cover: 'https://www.gqedu.org.cn:6868/manage/1b939a9bd48e40dfa3b4442f0ff25e16.JPG', name: 'CHRISBELLA-女包-双手腕', cost: 1000, },
            { cover: 'https://www.gqedu.org.cn:6868/manage/1b939a9bd48e40dfa3b4442f0ff25e16.JPG', name: 'CHRISBELLA-女包-双手腕', cost: 1000, },
            { cover: 'https://www.gqedu.org.cn:6868/manage/1b939a9bd48e40dfa3b4442f0ff25e16.JPG', name: 'CHRISBELLA-女包-双手腕', cost: 1000, },
            { cover: 'https://www.gqedu.org.cn:6868/manage/1b939a9bd48e40dfa3b4442f0ff25e16.JPG', name: 'CHRISBELLA-女包-双手腕', cost: 1000, },
            { cover: 'https://www.gqedu.org.cn:6868/manage/1b939a9bd48e40dfa3b4442f0ff25e16.JPG', name: 'CHRISBELLA-女包-双手腕', cost: 1000, },
            { cover: 'https://www.gqedu.org.cn:6868/manage/1b939a9bd48e40dfa3b4442f0ff25e16.JPG', name: 'CHRISBELLA-女包-双手腕', cost: 1000, },
            { cover: 'https://www.gqedu.org.cn:6868/manage/1b939a9bd48e40dfa3b4442f0ff25e16.JPG', name: 'CHRISBELLA-女包-双手腕', cost: 1000, },
            { cover: 'https://www.gqedu.org.cn:6868/manage/1b939a9bd48e40dfa3b4442f0ff25e16.JPG', name: 'CHRISBELLA-女包-双手腕', cost: 1000, },
            { cover: 'https://www.gqedu.org.cn:6868/manage/1b939a9bd48e40dfa3b4442f0ff25e16.JPG', name: 'CHRISBELLA-女包-双手腕', cost: 1000, },
            { cover: 'https://www.gqedu.org.cn:6868/manage/8e7f8f051fe345f29c54c9ecf9cf5a72.JPG', name: 'BAGCO-女包-双手腕', cost: 1000, },
            { cover: 'https://www.gqedu.org.cn:6868/manage/1b939a9bd48e40dfa3b4442f0ff25e16.JPG', name: 'CHRISBELLA-女包-双手腕', cost: 1000, },
            { cover: 'https://www.gqedu.org.cn:6868/manage/1b939a9bd48e40dfa3b4442f0ff25e16.JPG', name: 'CHRISBELLA-女包-双手腕', cost: 1000, },
            { cover: 'https://www.gqedu.org.cn:6868/manage/1b939a9bd48e40dfa3b4442f0ff25e16.JPG', name: 'CHRISBELLA-女包-双手腕', cost: 1000, },
            { cover: 'https://www.gqedu.org.cn:6868/manage/1b939a9bd48e40dfa3b4442f0ff25e16.JPG', name: 'CHRISBELLA-女包-双手腕', cost: 1000, },
            { cover: 'https://www.gqedu.org.cn:6868/manage/1b939a9bd48e40dfa3b4442f0ff25e16.JPG', name: 'CHRISBELLA-女包-双手腕', cost: 1000, },
            { cover: 'https://www.gqedu.org.cn:6868/manage/1b939a9bd48e40dfa3b4442f0ff25e16.JPG', name: 'CHRISBELLA-女包-双手腕', cost: 1000, },
            { cover: 'https://www.gqedu.org.cn:6868/manage/1b939a9bd48e40dfa3b4442f0ff25e16.JPG', name: 'CHRISBELLA-女包-双手腕', cost: 1000, },
            { cover: 'https://www.gqedu.org.cn:6868/manage/1b939a9bd48e40dfa3b4442f0ff25e16.JPG', name: 'CHRISBELLA-女包-双手腕', cost: 1000, },
            { cover: 'https://www.gqedu.org.cn:6868/manage/8e7f8f051fe345f29c54c9ecf9cf5a72.JPG', name: 'BAGCO-女包-双手腕', cost: 1000, },
            { cover: 'https://www.gqedu.org.cn:6868/manage/1b939a9bd48e40dfa3b4442f0ff25e16.JPG', name: 'CHRISBELLA-女包-双手腕', cost: 1000, },
            { cover: 'https://www.gqedu.org.cn:6868/manage/1b939a9bd48e40dfa3b4442f0ff25e16.JPG', name: 'CHRISBELLA-女包-双手腕', cost: 1000, },
            { cover: 'https://www.gqedu.org.cn:6868/manage/1b939a9bd48e40dfa3b4442f0ff25e16.JPG', name: 'CHRISBELLA-女包-双手腕', cost: 1000, },
            { cover: 'https://www.gqedu.org.cn:6868/manage/1b939a9bd48e40dfa3b4442f0ff25e16.JPG', name: 'CHRISBELLA-女包-双手腕', cost: 1000, },
            { cover: 'https://www.gqedu.org.cn:6868/manage/1b939a9bd48e40dfa3b4442f0ff25e16.JPG', name: 'CHRISBELLA-女包-双手腕', cost: 1000, },
            { cover: 'https://www.gqedu.org.cn:6868/manage/1b939a9bd48e40dfa3b4442f0ff25e16.JPG', name: 'CHRISBELLA-女包-双手腕', cost: 1000, },
            { cover: 'https://www.gqedu.org.cn:6868/manage/1b939a9bd48e40dfa3b4442f0ff25e16.JPG', name: 'CHRISBELLA-女包-双手腕', cost: 1000, },
            { cover: 'https://www.gqedu.org.cn:6868/manage/1b939a9bd48e40dfa3b4442f0ff25e16.JPG', name: 'CHRISBELLA-女包-双手腕', cost: 1000, },
            { cover: 'https://www.gqedu.org.cn:6868/manage/1b939a9bd48e40dfa3b4442f0ff25e16.JPG', name: 'CHRISBELLA-女包-双手腕', cost: 1000, },
            { cover: 'https://www.gqedu.org.cn:6868/manage/8e7f8f051fe345f29c54c9ecf9cf5a72.JPG', name: 'BAGCO-女包-双手腕', cost: 1000, },
            { cover: 'https://www.gqedu.org.cn:6868/manage/1b939a9bd48e40dfa3b4442f0ff25e16.JPG', name: 'CHRISBELLA-女包-双手腕', cost: 1000, },
            { cover: 'https://www.gqedu.org.cn:6868/manage/1b939a9bd48e40dfa3b4442f0ff25e16.JPG', name: 'CHRISBELLA-女包-双手腕', cost: 1000, },
            { cover: 'https://www.gqedu.org.cn:6868/manage/1b939a9bd48e40dfa3b4442f0ff25e16.JPG', name: 'CHRISBELLA-女包-双手腕', cost: 1000, },
            { cover: 'https://www.gqedu.org.cn:6868/manage/1b939a9bd48e40dfa3b4442f0ff25e16.JPG', name: 'CHRISBELLA-女包-双手腕', cost: 1000, },
            { cover: 'https://www.gqedu.org.cn:6868/manage/1b939a9bd48e40dfa3b4442f0ff25e16.JPG', name: 'CHRISBELLA-女包-双手腕', cost: 1000, },
            { cover: 'https://www.gqedu.org.cn:6868/manage/1b939a9bd48e40dfa3b4442f0ff25e16.JPG', name: 'CHRISBELLA-女包-双手腕', cost: 1000, },
            { cover: 'https://www.gqedu.org.cn:6868/manage/1b939a9bd48e40dfa3b4442f0ff25e16.JPG', name: 'CHRISBELLA-女包-双手腕', cost: 1000, },
            ],
            typeList: [],
            storeList: [],
        };
    },
    created() {
        // this.getTypeList();
        // this.getStoreList();
        // this.getGoodsList();
    },
    mounted() { },
    watch: {},
    methods: {
        handleCurrentChange(val) {
            this.formData.pageNo = val;
            this.getGoodsList();
        },
        search() {
            this.formData.pageNo = 1;
            this.getGoodsList();
        },
        //查看全部分类
        getTypeList() {
            this.$request.post({
                url: "/ReportApi/business/goodstypes",
                params: {},
                success: (res) => {
                    for (let i = 0; i < res.length; i++) {
                        res[i].createTime = res[i].create_time;
                        delete res[i].create_time;
                        res[i].otberId = res[i].other_id;
                        delete res[i].other_id;
                        res[i].otherType = res[i].other_type;
                        delete res[i].other_type;
                    }
                    this.typeList = res;
                    //consloe.log(res);
                },
            });
        },
        //查看全部仓库
        getStoreList() {
            this.$request.post({
                url: "/ReportApi/business/getstore",
                success: (res) => {
                    this.storeList = res;
                    //consloe.log(this.storeList2);
                },
            });
        },
        //查看商品列表
        getGoodsList() {
            this.$request.post({
                url: "/ReportApi/business/goodlists",
                params: this.formData,
                success: (res) => {
                    this.goodsList = res[0];
                    this.total = res[1];
                },
            });
        },
        //立即购买
        buyGoods(item) {
            this.$store.commit("getGoodsInfo", item);
            this.$router.push("/goods/Detail");
        },
    },
};
</script>

<style lang="scss" scoped>
#goods {
    .goods_params {
        border: 1px solid #eee;
        border-radius: 4px;
        font-size: 14px;
        color: #666;

        .block {
            line-height: 50px;
            background: #fafafa;

            &:nth-child(1) {
                border-bottom: 1px solid #eee;
                background: #fff;
            }

            .left_info {
                float: left;
                padding-left: 20px;
                padding-right: 15px;
            }

            line-height: 50px;

            ul {
                li {
                    float: left;
                    padding: 0 10px;

                    &:hover {
                        cursor: pointer;
                        color: #f80;
                    }
                }

                .active {
                    font-weight: bold;
                    color: #f80;
                }
            }
        }
    }

    .goods_list {
        margin-top: 20px;
        margin-bottom: 20px;

        .item {
            width: 226px;
            border: 1px solid #eee;
            margin-bottom: 10px;
            margin-left: 10px;
            float: left;

            &:hover {
                cursor: pointer;
                box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.3);
            }

            dl {
                dt {
                    width: 210px;
                    margin: 0 auto;
                    margin-top: 8px;

                    img {
                        width: 210px;
                        height: 210px;
                    }
                }

                dd {
                    padding: 0 8px;
                    font-size: 14px;
                    color: #999;
                }

                .title {
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    line-height: 34px;
                }

                .other {
                    height: 28px;

                    .price {
                        color: #f80;
                        font-size: 16px;
                        font-weight: bold;
                    }
                }

                .num {
                    line-height: 34px;
                    color: #333;
                    border-top: 1px solid #efefef;
                    border-bottom: 1px solid #efefef;
                }

                .btn {
                    border-radius: 4px;
                    color: #f80;
                    border: 1px solid #f80;
                    line-height: 28px;
                    width: 180px;
                    text-align: center;
                    margin: 10px auto;

                    &:hover {
                        background: #f80;
                        color: #fff;
                    }
                }
            }
        }
    }
}
</style>
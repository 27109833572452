<template>
  <div id="goods">
    <div class="goods_params wrap">
      <div class="block clearfix">
        <div class="left_info">仓储：</div>
        <ul class="fl">
          <li
            @click="(formData.storeId = ''), search()"
            :class="formData.storeId == '' ? 'active' : ''"
          >
            全部仓储
          </li>
          <li
            @click="(formData.storeId = ''), search()"
            :class="formData.storeId == '' ? '' : 'active'"
          >
            广州仓储
          </li>
          <li
            @click="(formData.storeId = ''), search()"
            :class="formData.storeId == '' ? '' : 'active'"
          >
            迪拜仓储
          </li>
          <!-- <li
              @click="(formData.storeId = item.id), search()"
              :class="formData.storeId == item.id ? 'active' : ''"
              v-for="item in storeList"
            >
              {{ item.name }}
            </li> -->
        </ul>
      </div>
      <div class="block clearfix">
        <div class="left_info">排序：</div>
        <ul class="fl">
          <li
            @click="(formData.sort = ''), search()"
            :class="formData.sort == '' ? 'active' : ''"
          >
            默认排序
          </li>
          <li
            @click="(formData.sort = 1), search()"
            :class="formData.sort == 1 ? 'active' : ''"
          >
            价格最低
          </li>
          <li
            @click="(formData.sort = 3), search()"
            :class="formData.sort == 3 ? 'active' : ''"
          >
            销量最高
          </li>
          <li
            @click="(formData.sort = 4), search()"
            :class="formData.sort == 4 ? 'active' : ''"
          >
            重量最重
          </li>
          <li
            @click="(formData.sort = 5), search()"
            :class="formData.sort == 5 ? 'active' : ''"
          >
            最新发布
          </li>
        </ul>
      </div>
      <div class="block clearfix">
        <div class="left_info">店面大小：</div>
        <ul class="fl">
          <li
            @click="(formData.sort = ''), search()"
            :class="formData.sort == '' ? 'active' : ''"
          >
            30m³
          </li>
          <li
            @click="(formData.sort = 1), search()"
            :class="formData.sort == 1 ? 'active' : ''"
          >
            40m³
          </li>
          <li
            @click="(formData.sort = 3), search()"
            :class="formData.sort == 3 ? 'active' : ''"
          >
            50px0m³
          </li>
          <li
            @click="(formData.sort = 4), search()"
            :class="formData.sort == 4 ? 'active' : ''"
          >
            60m³
          </li>
          <li
            @click="(formData.sort = 5), search()"
            :class="formData.sort == 5 ? 'active' : ''"
          >
            70m³
          </li>
          <li
            @click="(formData.sort = 5), search()"
            :class="formData.sort == 5 ? 'active' : ''"
          >
            80m³
          </li>
          <li
            @click="(formData.sort = 5), search()"
            :class="formData.sort == 5 ? 'active' : ''"
          >
            大于80m³
          </li>
        </ul>
      </div>
      <div class="block clearfix">
        <div class="left_info">所处位置：</div>
        <ul class="fl">
          <li
            @click="(formData.sort = ''), search()"
            :class="formData.sort == '' ? 'active' : ''"
          >
            左边
          </li>
          <li
            @click="(formData.sort = 1), search()"
            :class="formData.sort == 1 ? 'active' : ''"
          >
            右边
          </li>
          <li
            @click="(formData.sort = 3), search()"
            :class="formData.sort == 3 ? 'active' : ''"
          >
            中间
          </li>
          <li
            @click="(formData.sort = 3), search()"
            :class="formData.sort == 3 ? 'active' : ''"
          >
            其他
          </li>
        </ul>
      </div>
      <!-- <div class="block clearfix">
                <div class="left_info">分类筛选：</div>
                <div style="display: flex;justify-content: space-between;align-items: center;">
                    <div>
                        <ul class="fl">
                            <li @click="(formData.sort = ''), search()" :class="formData.sort == '' ? 'active' : ''">
                                女包
                            </li>
                            <li @click="(formData.sort = 1), search()" :class="formData.sort == 1 ? 'active' : ''">
                                钱包
                            </li>
                            <li @click="(formData.sort = 3), search()" :class="formData.sort == 3 ? 'active' : ''">
                                男包
                            </li>
                            <li @click="(formData.sort = 4), search()" :class="formData.sort == 4 ? 'active' : ''">
                                功能包
                            </li>
                            <li @click="(formData.sort = 5), search()" :class="formData.sort == 5 ? 'active' : ''">
                                其他
                            </li>

                        </ul>
                    </div>
                    <div style="margin-right: 20px;" @click="sonShow = !sonShow">子分类 <i class="el-icon-arrow-down"
                            v-if="sonShow == false"></i><i class="el-icon-arrow-up" v-if="sonShow == true"></i></div>
                </div>
            </div>
            <div class="block clearfix" v-if="sonShow">
                <div class="left_info">子类分类：</div>
                <div style="display: flex;justify-content: space-between;align-items: center;">
                    <div>
                        <ul class="fl">
                            <li @click="(formData.sort = ''), search()" :class="formData.sort == '' ? 'active' : ''">
                                双手腕
                            </li>
                            <li @click="(formData.sort = 1), search()" :class="formData.sort == 1 ? 'active' : ''">
                                单手腕
                            </li>
                            <li @click="(formData.sort = 3), search()" :class="formData.sort == 3 ? 'active' : ''">
                                斜跨
                            </li>
                            <li @click="(formData.sort = 4), search()" :class="formData.sort == 4 ? 'active' : ''">
                                单肩包
                            </li>
                            <li @click="(formData.sort = 5), search()" :class="formData.sort == 5 ? 'active' : ''">
                                双肩包
                            </li>
                            <li @click="(formData.sort = 5), search()" :class="formData.sort == 5 ? 'active' : ''">
                                其他
                            </li>
                        </ul>
                    </div>
                </div>
            </div> -->
    </div>
    <div class="goods_list wrap clearfix">
      <div class="item" v-for="item in goodsList" @click="buyGoods(item)">
        <dl>
          <dt>
            <img :src="item.cover | fullPath" alt="" />
          </dt>
          <dd class="title">
            {{ item.name }}
          </dd>
          <dd class="other">
            <span class="price fl">￥{{ parseFloat(item.cost) }}</span>
          </dd>

          <dd class="btn">立即购买</dd>
        </dl>
      </div>
    </div>
    <div class="home_fy_block wrap">
      <el-pagination
        background
        @current-change="handleCurrentChange"
        :current-page="formData.pageNo"
        :page-size="formData.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      sonShow: false,
      formData: {
        pageNo: 1,
        pageSize: 20,
        sort: "",
        storeId: "",
        type: "",
        tenantid: localStorage.getItem("tenantInfoid"),
      },
      total: 0,

      goodsList: [
        {
          type: "1",
          cover:
            "https://www.gqedu.org.cn:6868/manage/f99006cfe5ca4a4cb0d87e414a1f3934.png",
          name: "前台",
          cost: 1000,
        },
        {
          type: "2",
          cover:
            "https://www.gqedu.org.cn:6868/manage/e402cdc1037d4e09b684cbb77273740e.png",
          name: "商品展示柜",
          cost: 1000,
        },
        {
          type: "1",
          cover:
            "https://www.gqedu.org.cn:6868/manage/8f89cc8bc7ba4b318f809a40ed3405b5.png",
          name: "商品展示柜",
          cost: 1000,
        },
        {
          type: "2",
          cover:
            "https://www.gqedu.org.cn:6868/manage/4995dd151aab43ffbd9336801e6c477d.png",
          name: "商品展示柜",
          cost: 1000,
        },
        {
          type: "1",
          cover:
            "https://www.gqedu.org.cn:6868/manage/cfb58d63131a44dfa9f4970bd3db28ee.png",
          name: "包包立架",
          cost: 1000,
        },
      ],
      typeList: [],
      storeList: [],
    };
  },
  created() {
    // this.getTypeList();
    // this.getStoreList();
    // this.getGoodsList();
  },
  mounted() {},
  watch: {},
  methods: {
    handleCurrentChange(val) {
      this.formData.pageNo = val;
      this.getGoodsList();
    },
    search() {
      this.formData.pageNo = 1;
      this.getGoodsList();
    },
    //查看全部分类
    getTypeList() {
      this.$request.post({
        url: "/ReportApi/business/goodstypes",
        params: {},
        success: (res) => {
          for (let i = 0; i < res.length; i++) {
            res[i].createTime = res[i].create_time;
            delete res[i].create_time;
            res[i].otberId = res[i].other_id;
            delete res[i].other_id;
            res[i].otherType = res[i].other_type;
            delete res[i].other_type;
          }
          this.typeList = res;
          //consloe.log(res);
        },
      });
    },
    //查看全部仓库
    getStoreList() {
      this.$request.post({
        url: "/ReportApi/business/getstore",
        success: (res) => {
          this.storeList = res;
          //consloe.log(this.storeList2);
        },
      });
    },
    //查看商品列表
    getGoodsList() {
      this.$request.post({
        url: "/ReportApi/business/goodlists",
        params: this.formData,
        success: (res) => {
          this.goodsList = res[0];
          this.total = res[1];
        },
      });
    },
    //立即购买
    buyGoods(item) {
      // this.$store.commit("getGoodsInfo", item);
      if (item.type == 1) {
        console.log(1);

        this.$router.push({
          path: "/goods/imageGoodListDetail",
          query: { brandName: this.brandName },
        });
      } else {
        this.$router.push({
          path: "/goods/imageGoodListDetails",
          query: { brandName: this.brandName },
        });
      }

      // },
    },
  },
};
</script>

<style lang="scss" scoped>
#goods {
  .goods_params {
    border: 1px solid #eee;
    border-radius: 4px;
    font-size: 14px;
    color: #666;

    .block {
      line-height: 50px;
      background: #fafafa;

      &:nth-child(1) {
        border-bottom: 1px solid #eee;
        background: #fff;
      }

      .left_info {
        float: left;
        padding-left: 20px;
        padding-right: 15px;
      }

      line-height: 50px;

      ul {
        li {
          float: left;
          padding: 0 10px;

          &:hover {
            cursor: pointer;
            color: #f80;
          }
        }

        .active {
          font-weight: bold;
          color: #f80;
        }
      }
    }
  }

  .goods_list {
    margin-top: 20px;
    margin-bottom: 20px;

    .item {
      width: 226px;
      border: 1px solid #eee;
      margin-bottom: 10px;
      margin-left: 10px;
      float: left;

      &:hover {
        cursor: pointer;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.3);
      }

      dl {
        dt {
          width: 210px;
          margin: 0 auto;
          margin-top: 8px;

          img {
            width: 210px;
            height: 210px;
          }
        }

        dd {
          padding: 0 8px;
          font-size: 14px;
          color: #999;
        }

        .title {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          line-height: 34px;
        }

        .other {
          height: 28px;

          .price {
            color: #f80;
            font-size: 16px;
            font-weight: bold;
          }
        }

        .num {
          line-height: 34px;
          color: #333;
          border-top: 1px solid #efefef;
          border-bottom: 1px solid #efefef;
        }

        .btn {
          border-radius: 4px;
          color: #f80;
          border: 1px solid #f80;
          line-height: 28px;
          width: 180px;
          text-align: center;
          margin: 10px auto;

          &:hover {
            background: #f80;
            color: #fff;
          }
        }
      }
    }
  }
}
</style>

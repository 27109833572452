<template>
  <div class="shopping com-overflow-hidden" style="background: #f3f6f8">
    <input type="file" id="fileinputYh" style="display: none" @change="uploadHomeImgYh" />
    <div style="display: flex; align-items: center; width: 100%">
      <div
        style="
          width: 84%;
          margin-left: 8%;
          margin-right: 8%;
          background: #ffffff;
          min-height: 100vh;
          margin-top: 50px;
          margin-bottom: 100px;
        "
      >
        <div
          style="font-size: 30px; font-weight: 600; line-height: 70px; margin-left: 20px" v-if='lange=="中文"'
        >
          确认订单（{{ cartList.length }}）
        </div>
        <div
          style="font-size: 30px; font-weight: 600; line-height: 70px; margin-left: 20px" v-else
        >
        confirm an order（{{ cartList.length }}）
        </div>
        <div
          style="
            display: flex;
            background: #f3f6f8;
            line-height: 70px;
            padding-left: 40px;
            font-size: 17px;
            font-weight: 600;
          "
        >
          <!-- <div
            v-if="addressItem"
            style="
              display: flex;
              background: #f3f6f8;
              line-height: 70px;
              padding-left: 40px;
              font-size: 17px;
              font-weight: 600;
            "
          >
            <div style="margin-right: 30px">收款地址：{{ addressItem.consignee }}</div>
            <div style="margin-right: 30px">{{ addressItem.phone }}</div>
            <div style="margin-right: 60px">{{ addressItem.address }}</div>
            <div style="color: #8a8a8a" @click="dialogVisiblel = true">修改更换地址</div>
            <el-image
              v-if="addressItem.imageUrl"
              style="width: 200px; height: 100px; margin: 10px 20px"
              :src="addressItem.imageUrl"
              :preview-src-list="[addressItem.imageUrl]"
            >
            </el-image>
          </div>
          <div v-else>
            <div style="color: #8a8a8a" @click="dialogVisiblel = true">新增地址</div>
          </div> -->
        </div>
        <el-dialog title="地址" :visible.sync="dialogVisiblel" width="70%">
          <el-table
            :data="addressList"
            style="width: 100%"
            @current-change="handleCurrentChange"
          >
            <el-table-column
              prop="consignee"
              label="收货人"
              min-width="180"
              align="center"
            >
            </el-table-column>
            <el-table-column prop="phone" label="联系电话" min-width="180" align="center">
            </el-table-column>
            <el-table-column
              prop="address"
              min-width="280"
              label="收货地址"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="address"
              min-width="280"
              label="客户名片"
              align="center"
            >
              <template slot-scope="scope">
                <el-image
                  v-if="scope.row.imageUrl"
                  style="width: 100px; height: 100px"
                  :src="scope.row.imageUrl"
                  :preview-src-list="[scope.row.imageUrl]"
                >
                </el-image>
                <div v-else>暂无设置</div>
              </template>
            </el-table-column>
            <el-table-column label="操作" min-width="240" align="center">
              <template slot-scope="scope">
                <el-button @click="handleClick1(scope.row)" type="text" size="small"
                  >选择</el-button
                >
                <el-button @click="handleClick2(scope.row)" type="text" size="small"
                  >编辑</el-button
                >
                <el-button type="text" size="small" @click="changeDefealt(scope.row)"
                  >设置为默认</el-button
                >
                <el-button type="text" size="small" @click="handleUploadYh(scope.row)"
                  >上传名片</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <div style="display: flex; justify-content: center; margin-top: 20px">
            <el-button type="primary" @click="dialogVisiblel = false">取消</el-button>
            <el-button type="success" @click="dialogVisibler = true">新增地址</el-button>
          </div>
        </el-dialog>
        <el-dialog title="新增" :visible.sync="dialogVisibler" width="35%">
          <el-form ref="form" :model="form" style="width: 80%; margin-left: 10%">
            <el-form-item>
              <el-input v-model="form.consignee" placeholder="联系人"></el-input>
            </el-form-item>
            <el-form-item>
              <el-input v-model="form.phone" placeholder="联系电话"></el-input>
            </el-form-item>
            <el-form-item>
              <el-input
                v-model="form.address"
                type="textarea"
                :rows="2"
                placeholder="收货地址"
              ></el-input>
            </el-form-item>

            <div style="display: flex; justify-content: center">
              <el-button type="primary" @click="dialogVisibler = false">取消</el-button>
              <el-button
                type="success"
                @click="addAddress"
                v-if="this.form.customeraddressid"
                >修改</el-button
              >
              <el-button type="success" @click="addAddress" v-else>新增</el-button>
            </div>
          </el-form>
        </el-dialog>
        <div style="width: 100%" v-if='lange=="中文"'>
          <div v-for="(item, index) in cartList" :key="index" style="">
            <div
              style="
                display: flex;
                align-items: center;
                line-height: 50px;
                font-weight: 500;
                background: #f3f6f8;
                margin: 0px 10px;
                padding: 0 10px;
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
                margin-top: 20px;
                border: 1px solid #f0f3f5;
              "
            >
              <!-- <div style="margin-left: 40px; line-height: 50px">
                  添加人：{{ item.creater }}
                </div>
                <div style="margin-left: 40px; line-height: 50px">
                  添加时间：{{ item.createTime }}
                </div> -->
            </div>
            <div
              style="
                margin: 0px 7px;
                padding: 0 7px;
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;
              "
            >
              <el-table :data="tableData" stripe style="width: 100%">
                <el-table-column prop="date" label="商品" align="center"
                  ><template slot-scope="scope">
                    <el-image
                      style="width: 100%; height: 100%"
                      :src="item.imageUrl"
                      :preview-src-list="[item.imageUrl]"
                    >
                    </el-image>
                  </template>
                </el-table-column>
                <el-table-column prop="name" label="" align="center" min-width="120px">
                  <template slot-scope="scope">
                    <div style="font-size: 14px">
                      <div style="text-align: left">{{ item.cinvCode }}</div>
                      <div style="text-align: left" v-if="item.shoppingMallName">
                        {{ item.brandName + "-" + item.shoppingMallName }}
                      </div>
                      <div style="text-align: left" v-else>
                        {{ item.brandName }}
                      </div>
                      <div style="text-align: left">单价：￥{{ item.picePrice }}</div>
                      <div style="text-align: left">
                        {{
                          item.sellAttributeId == 1
                            ? "现货销售"
                            : item.sellAttributeId == 2
                            ? "生产预售"
                            : item.sellAttributeId == 3
                            ? "在途预售"
                            : "新品预售"
                        }}
                      </div>
                    </div>
                    <div style="font-size: 12px">
                      <div style="text-align: left">添加人：{{ item.creater }}</div>
                      <div style="text-align: left">添加时间：{{ item.createTime }}</div>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="address"
                  label="颜色配比"
                  align="center"
                  min-width="230px"
                >
                  <template slot-scope="scope">
                    <div style="position: relative">
                      <div ref="imgListp" style="display: flex; flex-wrap: wrap">
                        <div
                          v-for="(items, indexw) in item.shoppingCartDeatailListCopy"
                          :key="indexw"
                          style="
                            display: flex;
                            aligin-item: center;
                            flex-direction: column;
                            margin-right: 2%;
                            margin-bottom: 20px;
                            width: 22%;
                          "
                        >
                          <el-image
                            style="
                              width: 100%;
                              height: 100%;
                              margin-right: 10px;
                              margin-bottom: 10px;
                            "
                            :src="items.imageUrls"
                            :preview-src-list="items.imageUrlLists"
                          >
                          </el-image>
                          <div
                            style="
                              width: 100%;
                              justify-content: center;
                              align-items: center;
                            "
                            v-if="!lpButtonShow"
                          >
                            <div class="counters">
                              <div style="font-size: 21px; color: #c1c1c1">-</div>
                              <div style="font-weight: 500">
                                {{ items.colorQuantity }}
                              </div>
                              <div style="font-size: 21px; color: #c1c1c1">+</div>
                            </div>
                          </div>
                          <div
                            style="
                              width: 100%;
                              justify-content: center;
                              align-items: center;
                            "
                            v-else
                          >
                            <div class="counters">
                              <div style="font-size: 21px; color: black">-</div>
                              <div style="font-weight: 500">
                                {{ items.colorQuantity }}
                              </div>
                              <div style="font-size: 21px; color: black">+</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        style="
                          display: flex;
                          width: 100%;
                          align-items: center;
                          justify-content: center;
                          font-size: 18px;
                          margin: 10px 0;
                          position: absolute;
                          bottom: -10px;
                          left: 0%;
                        "
                        v-if="muchShow"
                        @click="muchShowClick(index)"
                      >
                        <i class="el-icon-arrow-down"></i>
                      </div>
                      <div
                        style="
                          display: flex;
                          width: 100%;
                          align-items: center;
                          justify-content: center;
                          font-size: 18px;
                          margin: 20px 0;
                        "
                        v-else
                        @click="muchShowClick(index)"
                      >
                        <i class="el-icon-arrow-up"></i>
                      </div>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="address"
                  label="单价/psc"
                  align="center"
                  min-width="60px"
                >
                  <template slot-scope="scope">
                    {{ item.picePrice }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="address"
                  label="体积/m³"
                  align="center"
                  min-width="60px"
                >
                  <template slot-scope="scope">
                    {{ item.volume.toFixed(2) }}
                  </template>
                </el-table-column>

                <el-table-column
                  prop="address"
                  label="购买数量(PCS)"
                  align="center"
                  min-width="80px"
                >
                  <template slot-scope="scope"> {{ item.countSum }}PCS </template>
                </el-table-column>
                <el-table-column
                  prop="address"
                  label="购买箱量(CTN)"
                  align="center"
                  min-width="80px"
                >
                  <template slot-scope="scope">
                    <div
                      style="display: flex; align-items: center; justify-content: center"
                    >
                      <div class="counter">
                        <div style="font-size: 21px; color: black">-</div>
                        <div style="font-weight: 500">{{ item.boxNumber }}</div>
                        <div style="font-size: 21px; color: black">+</div>
                      </div>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="address"
                  label="总金额"
                  align="center"
                  min-width="60px"
                >
                  <template slot-scope="scope">
                    {{ item.totalPrice }}
                  </template>
                </el-table-column>
                <el-table-column fixed="right" label="操作" min-width="60" align="center">
                  <template slot-scope="scope">
                    <el-button type="text" size="small" @click="deleteCartItem(index)"
                      >删除</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div
            style="
              display: flex;
              align-items: start;
              justify-content: space-between;
              padding: 40px 15px;
              background: #f2f2f2;
              margin: 40px 20px;
            "
          >
            <div style="display: flex; margin-left: 40px; align-items: center"></div>
            <div style="width: 14%; margin-right: 4%">
              <!-- <div style="display: flex; justify-content: space-between;font-weight: 600;line-height: 28px;">
                      <div>货品种类</div>
                      <div>100</div>
                    </div> -->
              <div
                style="
                  display: flex;
                  justify-content: space-between;
                  font-weight: 600;
                  line-height: 28px;
                "
              >
                <div>总PCS数</div>
                <div style="font-size: 14px">{{ buyCounterPcsSum }}pcs</div>
              </div>
              <div
                style="
                  display: flex;
                  justify-content: space-between;
                  font-weight: 600;
                  line-height: 28px;
                "
              >
                <div>总体积</div>
                <div style="font-size: 14px">{{ buyCounterVolumeSum.toFixed(2) }}m³</div>
              </div>
              <div
                style="
                  display: flex;
                  justify-content: space-between;
                  font-weight: 600;
                  line-height: 28px;
                "
              >
                <div>总箱数</div>
                <div style="font-size: 14px">{{ buyCounterCtnSum }}CTN</div>
              </div>

              <div
                style="
                  display: flex;
                  justify-content: space-between;
                  font-weight: 600;
                  line-height: 28px;
                "
              >
                <div style="">总金额</div>
                <div style="font-size: 20px; color: #ec1c24">
                  ￥{{ buyCounterMoneySum }}
                </div>
              </div>
              <div
                style="
                  width: 100%;
                  justify-content: center;
                  margin-top: 20px;
                  margin-bottom: 20px;
                "
                @click="addDcOrdercenterOrderClick"
              >
                <div
                  class="counters"
                  style="
                    font-weight: 500;
                    background: black;
                    color: white;
                    text-align: center;
                    justify-content: center;
                    line-height: 28px;
                  "
                >
                  <div>确认订购</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style="width: 100%" v-else>
          <div v-for="(item, index) in cartList" :key="index" style="">
            <div
              style="
                display: flex;
                align-items: center;
                line-height: 50px;
                font-weight: 500;
                background: #f3f6f8;
                margin: 0px 10px;
                padding: 0 10px;
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
                margin-top: 20px;
                border: 1px solid #f0f3f5;
              "
            >
              <div style="margin-left: 33px">
                <el-checkbox
                  :value="item.checked"
                  @change="selectItem($event, index)"
                ></el-checkbox>
              </div>
            </div>
            <div
              style="
                margin: 0px 7px;
                padding: 0 7px;
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;
              "
            >
              <el-table :data="tableData" stripe style="width: 100%">
                <el-table-column prop="date" label="commodity" align="center"
                  ><template slot-scope="scope">
                    <el-image
                      style="width: 100%; height: 100%"
                      :src="item.imageUrl"
                      :preview-src-list="[item.imageUrl]"
                    >
                    </el-image>
                  </template>
                </el-table-column>
                <el-table-column prop="name" label="" align="center" min-width="120px">
                  <template slot-scope="scope">
                    <div style="font-size: 14px">
                      <div style="text-align: left">{{ item.cinvCode }}</div>
                      <div style="text-align: left" v-if="item.shoppingMallName">
                        {{ item.brandName + "-" + item.shoppingMallName }}
                      </div>
                      <div style="text-align: left" v-else>
                        {{ item.brandName }}
                      </div>

                      <div style="text-align: left">
                        {{
                          item.sellAttributeId == 1
                            ? "In Stock"
                            : item.sellAttributeId == 2
                            ? "Pre-Sale In Production"
                            : item.sellAttributeId == 3
                            ? "Pre-Sale In Transit"
                            : "New Arrival"
                        }}
                      </div>
                    </div>
                    <div style="font-size: 12px">
                      <div style="text-align: left">Add people：{{ item.creater }}</div>
                      <div style="text-align: left">Add Time：{{ item.createTime }}</div>
                    </div>
                    <div style="font-size: 12px" v-if="item.sellAttributeId != 1">
                      <div style="text-align: left">
                        Estimated delivery time：{{ item.deliveryDate }}
                      </div>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="address"
                  label="ColorRatio"
                  align="center"
                  min-width="180px"
                >
                  <template slot-scope="scope">
                    <div style="position: relative">
                      <div ref="imgListp" style="display: flex; flex-wrap: wrap">
                        <div
                          v-for="(items, indexw) in item.shoppingCartDeatailListCopy"
                          :key="indexw"
                          style="
                            display: flex;
                            aligin-item: center;
                            flex-direction: column;
                            margin-right: 2%;
                            margin-bottom: 20px;
                            width: 22%;
                          "
                        >
                          <el-image
                            style="
                              width: 100%;
                              height: 100%;
                              margin-right: 10px;
                              margin-bottom: 10px;
                            "
                            :src="items.imageUrls"
                            :preview-src-list="items.imageUrlLists"
                          >
                          </el-image>
                          <div
                            style="
                              width: 100%;
                              justify-content: center;
                              align-items: center;
                            "
                            v-if="!lpButtonShow"
                          >
                            <div class="counters">
                              <div style="font-size: 21px; color: #c1c1c1">-</div>
                              <div style="font-weight: 500">
                                {{ items.colorQuantity }}
                              </div>
                              <div style="font-size: 21px; color: #c1c1c1">+</div>
                            </div>
                          </div>
                          <div
                            style="
                              width: 100%;
                              justify-content: center;
                              align-items: center;
                            "
                            v-else
                          >
                            <div class="counters">
                              <div
                                style="font-size: 21px; color: black"
                                @click="decrementItem(items, indexw, index)"
                              >
                                -
                              </div>
                              <div style="font-weight: 500">
                                {{ items.colorQuantity }}
                              </div>
                              <div
                                style="font-size: 21px; color: black"
                                @click="incrementItem(items, indexw, index)"
                              >
                                +
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        style="
                          display: flex;
                          width: 100%;
                          align-items: center;
                          justify-content: center;
                          font-size: 18px;
                          margin: 20px 0;
                          position: absolute;
                          bottom: -20px;
                          left: 0%;
                        "
                        v-if="muchShow"
                        @click="muchShowClick(index)"
                      >
                        <i class="el-icon-arrow-down"></i>
                      </div>
                      <div
                        style="
                          display: flex;
                          width: 100%;
                          align-items: center;
                          justify-content: center;
                          font-size: 18px;
                          margin: 20px 0;
                        "
                        v-else
                        @click="muchShowClick(index)"
                      >
                        <i class="el-icon-arrow-up"></i>
                      </div>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="address"
                  label="unitPrice/PCS"
                  align="center"
                  min-width="60px"
                >
                  <template slot-scope="scope">
                    {{ item.picePrice ? item.picePrice.toFixed(2) + "/RMB" : "" }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="address"
                  label="volume/m³"
                  align="center"
                  min-width="60px"
                >
                  <template slot-scope="scope">
                    {{ item.volume ? item.volume.toFixed(2) : "" }}
                  </template>
                </el-table-column>

                <el-table-column
                  prop="address"
                  label="PurchaseQuantity(PCS)"
                  align="center"
                  min-width="100px"
                >
                  <template slot-scope="scope"> {{ item.countSum }}PCS </template>
                </el-table-column>
                <el-table-column
                  prop="address"
                  label="NumberOfBoxesPurchased(CTN)"
                  align="center"
                  min-width="120px"
                >
                  <template slot-scope="scope">
                    <div
                      style="display: flex; align-items: center; justify-content: center"
                    >
                      <div class="counter">
                        <div
                          style="font-size: 21px; color: black"
                          @click="decrementPacking(item, index)"
                        >
                          -
                        </div>
                        <div style="font-weight: 500">{{ item.boxNumber }}</div>
                        <div
                          style="font-size: 21px; color: black"
                          @click="incrementPacking(item, index)"
                        >
                          +
                        </div>
                      </div>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="address"
                  label="TotalAmount"
                  align="center"
                  min-width="60px"
                >
                  <template slot-scope="scope">
                    {{ item.totalPrice ? item.totalPrice.toFixed(2) + "/RMB" : "" }}
                  </template>
                </el-table-column>
                <el-table-column fixed="right" label="operate" min-width="60" align="center">
                  <template slot-scope="scope">
                    <el-button type="text" size="small" @click="deleteCartItem(item)"
                      >delete</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div
            style="
              display: flex;
              align-items: start;
              justify-content: space-between;
              padding: 40px 15px;
              background: #f2f2f2;
              margin: 40px 20px;
            "
          >
            <div style="display: flex; margin-left: 40px; align-items: center"></div>
            <div style="width: 14%; margin-right: 4%" v-if='lange=="中文"'>
              <!-- <div style="display: flex; justify-content: space-between;font-weight: 600;line-height: 28px;">
                      <div>货品种类</div>
                      <div>100</div>
                    </div> -->
              <div
                style="
                  display: flex;
                  justify-content: space-between;
                  font-weight: 600;
                  line-height: 28px;
                "
              >
                <div>总PCS数</div>
                <div style="font-size: 14px">{{ buyCounterPcsSum }}pcs</div>
              </div>
              <div
                style="
                  display: flex;
                  justify-content: space-between;
                  font-weight: 600;
                  line-height: 28px;
                "
              >
                <div>总体积</div>
                <div style="font-size: 14px">{{ buyCounterVolumeSum.toFixed(2) }}m³</div>
              </div>
              <div
                style="
                  display: flex;
                  justify-content: space-between;
                  font-weight: 600;
                  line-height: 28px;
                "
              >
                <div>总箱数</div>
                <div style="font-size: 14px">{{ buyCounterCtnSum }}CTN</div>
              </div>

              <div
                style="
                  display: flex;
                  justify-content: space-between;
                  font-weight: 600;
                  line-height: 28px;
                "
              >
                <div style="">总金额</div>
                <div style="font-size: 20px; color: #ec1c24">
                  ￥{{ buyCounterMoneySum }}
                </div>
              </div>
              <div
                style="
                  width: 100%;
                  justify-content: center;
                  margin-top: 20px;
                  margin-bottom: 20px;
                "
                @click="addDcOrdercenterOrderClick"
              >
                <div
                  class="counters"
                  style="
                    font-weight: 500;
                    background: black;
                    color: white;
                    text-align: center;
                    justify-content: center;
                    line-height: 28px;
                  "
                >
                  <div>确认订购</div>
                </div>
              </div>
            </div>
            <div style="width: 28%; margin-right: 4%" v-else>
              <!-- <div style="display: flex; justify-content: space-between;font-weight: 600;line-height: 28px;">
                      <div>货品种类</div>
                      <div>100</div>
                    </div> -->
                    <div
                  style="
                    display: flex;
                    justify-content: space-between;
                    font-weight: 600;
                    line-height: 28px;
                  "
                >
                  <div>Total PCS quantity</div>
                  <div style="font-size: 14px">{{ buyCounterPcsSum }}pcs</div>
                </div>
                <div
                  style="
                    display: flex;
                    justify-content: space-between;
                    font-weight: 600;
                    line-height: 28px;
                  "
                >
                  <div>Total Volume</div>
                  <div style="font-size: 14px">
                    {{ buyCounterVolumeSum.toFixed(2) }}m³
                  </div>
                </div>
                <div
                  style="
                    display: flex;
                    justify-content: space-between;
                    font-weight: 600;
                    line-height: 28px;
                  "
                >
                  <div>Total number of boxes</div>
                  <div style="font-size: 14px">{{ buyCounterCtnSum }}CTN</div>
                </div>

                <div
                  style="
                    display: flex;
                    justify-content: space-between;
                    font-weight: 600;
                    line-height: 28px;
                  "
                >
                  <div style="">Tax inclusive amount</div>
                  <div style="font-size: 20px; color: #ec1c24" v-if="checked1 == true">
                    {{ totalTaxMoney }}/RMB
                  </div>
                  <div style="font-size: 20px; color: #ec1c24" v-else>
                    {{ buyCounterMoneySum }}/RMB
                  </div>
                </div>
                <div
                  style="
                    display: flex;
                    justify-content: space-between;
                    font-weight: 600;
                    line-height: 28px;
                  "
                >
                  <div style="">Tax amount</div>
                  <div style="font-size: 20px; color: #ec1c24" v-if="checked1 == true">
                    {{ tax }}/RMB
                  </div>
                  <div style="font-size: 20px; color: #ec1c24" v-else>0</div>
                </div>
                <div
                  style="
                    display: flex;
                    justify-content: space-between;
                    font-weight: 600;
                    line-height: 28px;
                  "
                >
                  <div style="">tax rate</div>
                  <div style="font-size: 20px; color: #ec1c24" v-if="checked1 == true">
                    {{ taxRate }}%
                  </div>
                  <div style="font-size: 20px; color: #ec1c24" v-else>0</div>
                </div>
                <div
                  style="
                    display: flex;
                    justify-content: space-between;
                    font-weight: 600;
                    line-height: 28px;
                  "
                >
                  <div style="">Excluding tax amount</div>
                  <div style="font-size: 20px; color: #ec1c24">
                    {{ buyCounterMoneySum.toFixed(2) }}/RMB
                  </div>
                </div>
              <div
                style="
                  width: 100%;
                  justify-content: center;
                  margin-top: 20px;
                  margin-bottom: 20px;
                "
                @click="addDcOrdercenterOrderClick"
              >
                <div
                  class="counters"
                  style="
                    font-weight: 500;
                    background: black;
                    color: white;
                    text-align: center;
                    justify-content: center;
                    line-height: 28px;
                  "
                >
                  <div>确认订购</div>
                </div>
              </div>
            </div>
         
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="提示" :visible.sync="dialogVisible" width="40%">
      <div style="display: flex">
        <div style="width: 30%; margin-right: 2%">
          <div style="margin-bottom: 20px">{{ listItem.paymentCodeWeChatName }}：</div>
          <div>
            <el-image
              v-if="listItem.paymentCode_WeChat"
              style="width: 100%; height: 100%"
              :src="listItem.paymentCode_WeChat"
              :preview-src-list="[listItem.paymentCode_WeChat]"
            >
            </el-image>
            <div v-else>暂无设置</div>
          </div>
        </div>
        <div style="width: 30%; margin-right: 2%">
          <div style="margin-bottom: 20px">{{ listItem.paymentCodeAlipayName }}：</div>
          <div>
            <el-image
              v-if="listItem.paymentCode_Alipay"
              style="width: 100%; height: 100%"
              :src="listItem.paymentCode_Alipay"
              :preview-src-list="[listItem.paymentCode_Alipay]"
            >
            </el-image>
            <div v-else>暂无设置</div>
          </div>
        </div>
        <div style="width: 30%">
          <div style="margin-bottom: 20px">{{ listItem.paymentCodeBankName }}：</div>
          <div>
            <el-image
              v-if="listItem.paymentCode_Bank"
              style="width: 100%; height: 100%"
              :src="listItem.paymentCode_Bank"
              :preview-src-list="[listItem.paymentCode_Bank]"
            >
            </el-image>
            <div v-else>暂无设置</div>
          </div>
        </div>
      </div>
      <div v-if="listItem.isPCShowCompany" style="margin-top: 50px">
        <div>{{ listItem.paymentCodeCompanyName }}:</div>

        <div
          v-html="listItem.paymentCode_Company"
          v-if="listItem.paymentCode_Company"
        ></div>
        <div v-else>暂无设置</div>
      </div>
      <div style="display: flex; justify-content: center; width: 100%">
        <el-button type="danger" @click="addDcOrdercenterOrderClick1">稍后支付</el-button>
        <el-button type="success" @click="dialogVisibles = true">上传凭证</el-button>
      </div>
    </el-dialog>
    <el-dialog title="凭证上传" :visible.sync="dialogVisibles" width="40%" append-to-body>
      <div style="display: flex; justify-content: center; width: 100%">
        <el-upload
          action=""
          list-type="picture-card"
          :on-preview="handlePictureCardPreview"
          :http-request="httprequest"
        >
          <i class="el-icon-plus"></i>
        </el-upload>
        <el-dialog :visible.sync="dialogVisiblek">
          <img width="100%" :src="dialogImageUrl" alt="" />
        </el-dialog>
      </div>
      <div style="display: flex; justify-content: center; width: 100%; margin-top: 20px">
        <el-button type="success" @click="addDcOrdercenterOrderClick2"
          >上传凭证</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getDcShoppingmallShoppingcart,
  deleteDcShoppingmallShoppingcart,
  editDcShoppingmallShoppingcart,
  addDcOrdercenterOrder,
  getCustomerPaymentCode,
  addCustomerOrderPaymentImage,
  dcCustomercenterPaymentcodeQueryDefault,
} from "@/api/cart/index.js";
import {
  getDcCustomercenterCustomeraddress,
  addDcCustomercenterCustomeraddress,
  editDcCustomercenterCustomeraddress,
  addDcCustomercenterCustomeraddressImage,
} from "@/api/address/index.js";
import { mapGetters } from 'vuex'
import { getDcBasearchiveCommodityPackingratio } from "@/api/good/index.js";
import ShopItem from "./Shoping/ShopItem.vue";
import shopOrder from "./shopOrder/index.vue";
import { encryptionKey } from "@/config";
import CryptoJS from "crypto-js";
export default {
  name: "ShoppingVue",
  data() {
    return {
      buyCounterPcsSum: 0,
      buyCounterVolumeSum: 0,
      buyCounterCtnSum: 0,
      buyCounterMoneySum: 0,
      muchShow: true,
      dialogVisible: false,
      dialogVisibles: false,
      dialogVisiblek: false,
      dialogImageUrl: "",
      dialogVisible: false,
      disabled: false,
      checked: false,
      tableData: [
        {
          date: "2016-05-02",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
      ],
      isHome: false,
      isActive: false,
      isAllSelected: false,
      allShop: "0.00",
      dialogTableVisible: false,
      dialogVisiblep: false,
      imageHight: 0,

      checkep: false,
      lpButtonShow: false,

      cartList: [],
      priceCounter: 0,
      buyCountSum: 0,
      buyCountSumMoney: 0,
      addressItem: {},
      addressList: [],
      listItem: {},
      orderId: "",
      dialogVisiblel: false,
      dialogVisibler: false,
      currentRow: [],
      form: {},
      imageItem: {},
    };
  },
  components: {
    ShopItem,
    shopOrder,
  },

  mounted() {
    let selectList = localStorage.getItem("orderCommodityList").split(",");
    getDcShoppingmallShoppingcart({ pageNum: 1, pageSize: 10000 }).then((res) => {
      this.cartList = [];
      res.data.forEach((item) => {
        selectList.forEach((itemp) => {
          if (item.shoppingCartId == itemp) {
            this.cartList.push(item);
          }
        });
      });
      this.cartList.forEach((item) => {
        item.checked = false;

        item.countSum = 0;
        item.shoppingCartDeatailList.forEach((itemp) => {
          console.log(itemp.colorQuantity);
          item.countSum = itemp.colorQuantity * 1 + item.countSum * 1;
        });

        item.totalPrice = item.picePrice * item.countSum;
        item.volume = (item.ivolume / item.packingQuantity / 1000000) * item.countSum;
        item.imageUrl = this.$locationUrl + "file/" + item.imageUrl;
        console.log(item.imageUrl);
        item.shoppingCartDeatailList.forEach((items) => {
          items.imageUrls =
            this.$locationUrl +
            "file/abyFile/commodityFile/commodityColorImage/" +
            items.fileName;
          items.imageUrlLists = [item.imageUrls];
          console.log(items.imageUrls);
        });
        item.shoppingCartDeatailListCopy = item.shoppingCartDeatailList.slice(0, 4);
        this.buyCounterPcsSum = this.buyCounterPcsSum * 1 + item.countSum;
        this.buyCounterVolumeSum = this.buyCounterVolumeSum * 1 + item.volume;
        this.buyCounterCtnSum = this.buyCounterCtnSum * 1 + item.boxNumber;
        this.buyCounterMoneySum = this.buyCounterMoneySum * 1 + item.totalPrice;
      });
    });
    getDcCustomercenterCustomeraddress({}).then((res) => {
      this.addressList = res.data;
      this.addressList.forEach((item) => {
        if (item.imageUrl) {
          item.imageUrl = this.$locationUrl + "file/" + item.imageUrl;
        }
      });
      let addressItemp = this.addressList.filter((item) => {
        return item.isDefault == 1;
      });
      if (addressItemp.length == 0) {
        this.addressItem = res.data[0];
      } else {
        this.addressItem = addressItemp[0];
      }

      console.log(this.addressItem);
    });
    getCustomerPaymentCode({}).then((res) => {
      if (res.code == 200) {
        if (JSON.stringify(res.data) != "{}") {
          this.listItem = res.data;

          if (this.listItem.paymentCode_Alipay) {
            this.listItem.paymentCode_Alipay = this.codeChange(
              this.listItem.paymentCode_Alipay
            );
          } else {
            this.listItem.paymentCode_Alipay = "";
          }
          if (this.listItem.paymentCode_Bank) {
            this.listItem.paymentCode_Bank = this.codeChange(
              this.listItem.paymentCode_Bank
            );
          } else {
            this.listItem.paymentCode_Bank = "";
          }
          if (this.listItem.paymentCode_WeChat) {
            this.listItem.paymentCode_WeChat = this.codeChange(
              this.listItem.paymentCode_WeChat
            );
          } else {
            this.listItem.paymentCode_WeChat = "";
          }
          console.log(this.listItem);
        } else {
          dcCustomercenterPaymentcodeQueryDefault({ pageSize: 10, pageNum: 1 }).then(
            (resp) => {
              if (res.data.length != 0) {
                this.listItem = resp.data[0];
                console.log("this.listItem", this.listItem);
                if (this.listItem.isPCShowWeChat == 1) {
                  this.listItem.isPCShowWeChat = true;
                } else {
                  this.listItem.isPCShowWeChat = false;
                }
                if (this.listItem.isPCShowAlipay == 1) {
                  this.listItem.isPCShowAlipay = true;
                } else {
                  this.listItem.isPCShowAlipay = false;
                }
                if (this.listItem.isPCShowBank == 1) {
                  this.listItem.isPCShowBank = true;
                } else {
                  this.listItem.isPCShowBank = false;
                }
                if (this.listItem.isAPPShowWeChat == 1) {
                  this.listItem.isAPPShowWeChat = true;
                } else {
                  this.listItem.isAPPShowWeChat = false;
                }
                if (this.listItem.isAPPShowAlipay == 1) {
                  this.listItem.isAPPShowAlipay = true;
                } else {
                  this.listItem.isAPPShowAlipay = false;
                }
                if (this.listItem.isAPPShowBank == 1) {
                  this.listItem.isAPPShowBank = true;
                } else {
                  this.listItem.isAPPShowBank = false;
                }
                if (this.listItem.paymentCode_Alipay) {
                  this.listItem.paymentCode_Alipay = this.codeChange(
                    this.listItem.paymentCode_Alipay
                  );
                }
                if (this.listItem.paymentCode_Bank) {
                  this.listItem.paymentCode_Bank = this.codeChange(
                    this.listItem.paymentCode_Bank
                  );
                }
                if (this.listItem.paymentCode_WeChat) {
                  this.listItem.paymentCode_WeChat = this.codeChange(
                    this.listItem.paymentCode_WeChat
                  );
                }

                console.log("listItem", this.listItem);
                this.$forceUpdate();
              } else {
                this.listItem = {
                  paymentCodeWeChatName: "",
                  paymentCodeAlipayName: "",
                  paymentCodeBankName: "",
                  paymentCodeCompanyName: "",
                  isPCShowWeChat: "",
                  isPCShowAlipay: "",
                  isPCShowBank: "",
                  isPCShowCompany: "",
                  isAPPShowWeChat: "",
                  isAPPShowAlipay: "",
                  isAPPShowBank: "",
                  isAPPShowCompany: "",
                };
              }
            }
          );
        }
      }
    });
    this.$forceUpdate();
  },
  created() {},
  computed: {
    ...mapGetters({
        lange: 'user/lange',
      }),
  },
  watch: {},
  methods: {
    handleUploadYh(row) {
      this.imageItem = row;
      document.querySelector("#fileinputYh").click();
    },
    uploadHomeImgYh(event) {
      let formData = new FormData();
      formData.append("image", event.target.files[0]);
      formData.append("customeraddressid", this.imageItem.customeraddressid);

      addDcCustomercenterCustomeraddressImage(formData).then((res) => {
        if (res.code == 200) {
          getDcCustomercenterCustomeraddress({}).then((res) => {
            this.addressList = res.data;
            this.addressList.forEach((item) => {
              if (item.imageUrl) {
                item.imageUrl = this.$locationUrl + "file/" + item.imageUrl;
              }
            });
            let addressItemp = this.addressList.filter((item) => {
              return item.isDefault == 1;
            });
            if (addressItemp.length == 0) {
              this.addressItem = res.data[0];
            } else {
              this.addressItem = addressItemp[0];
            }

            console.log(this.addressItem);
          });
        }
      });
    },
    changeDefealt(row) {
      this.form = row;

      this.form.isDefault = 1;
      editDcCustomercenterCustomeraddress(this.form).then((res) => {
        if (res.code == 200) {
          getDcCustomercenterCustomeraddress({}).then((res) => {
            this.addressList = res.data;
            this.addressList.forEach((item) => {
              if (item.imageUrl) {
                item.imageUrl = this.$locationUrl + "file/" + item.imageUrl;
              }
            });
            let addressItemp = this.addressList.filter((item) => {
              return item.isDefault == 1;
            });
            if (addressItemp.length == 0) {
              this.addressItem = res.data[0];
            } else {
              this.addressItem = addressItemp[0];
            }
            this.form = {};
            this.dialogVisiblel = false;
          });
        }
      });
    },
    addAddress() {
      if (this.form.customeraddressid) {
        editDcCustomercenterCustomeraddress(this.form).then((res) => {
          if (res.code == 200) {
            this.dialogVisibler = false;

            getDcCustomercenterCustomeraddress({}).then((res) => {
              let addressItemp = res.data.filter((item) => {
                return item.isDefault == 1;
              });
              if (addressItemp.length == 0) {
                this.addressItem = res.data[0];
              }
              this.addressList = res.data;
              this.form = {};
            });
          }
        });
      } else {
        addDcCustomercenterCustomeraddress(this.form).then((res) => {
          if (res.code == 200) {
            this.dialogVisibler = false;

            getDcCustomercenterCustomeraddress({}).then((res) => {
              let addressItemp = res.data.filter((item) => {
                return item.isDefault == 1;
              });
              if (addressItemp.length == 0) {
                this.addressItem = res.data[0];
              }
              this.addressList = res.data;
              this.form = {};
            });
          }
        });
      }
    },
    handleClick1(row) {
      this.addressItem = row;
      console.log(row);

      this.dialogVisiblel = false;
    },
    handleClick2(row) {
      this.dialogVisibler = true;
      this.form = row;
    },
    handleCurrentChange(val) {
      this.currentRow = val;
    },
    httprequest(e) {
      this.formData = new FormData();
      this.formData.append("image", e.file);
      this.formData.append("orderId", this.orderId);
      addCustomerOrderPaymentImage(this.formData).then((res) => {
        this.$message({
          message: "图片上传成功",
          type: "success",
        });
      });
    },

    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisiblek = true;
    },
    handleDownload(file) {
      console.log(file);
    },
    codeChange(item) {
      console.log(item);
      var decrypted = CryptoJS.AES.decrypt(item, encryptionKey).toString(
        CryptoJS.enc.Utf8
      );
      console.log(decrypted);
      var bytes = window.atob(decrypted.split(",")[1]);
      //处理异常,将ascii码小于0的转换为大于0
      var ab = new ArrayBuffer(bytes.length);
      var ia = new Uint8Array(ab);
      for (var i = 0; i < bytes.length; i++) {
        ia[i] = bytes.charCodeAt(i);
      }
      const blob = new Blob([ab], { type: "image/png" });

      // 生成 Blob 对象的 URL 地址
      const imageUrl = URL.createObjectURL(blob);
      return imageUrl;
    },
    addDcOrdercenterOrderClick1() {
      this.$router.push( { path: "/goods/goodOrder", query: { brandName: this.brandName } });
      this.$notify({
        title: this.lange == "中文" ? "成功" : "succsee",
        message: this.lange == "中文" ? "订单已创建" : "Order created",
        type: "success",
        duration: 0, offset: 100
      });
    },
    addDcOrdercenterOrderClick2() {
      this.$router.push( { path: "/goods/goodOrder", query: { brandName: this.brandName } });
      this.$notify({
        title: this.lange == "中文" ? "成功" : "succsee",
        message: this.lange == "中文" ? "订单已创建" : "Order created",
        type: "success",
        duration: 0, offset: 100
      });
    },
    addDcOrdercenterOrderClick() {
      let orderCommodityList = [];
      this.cartList.forEach((item) => {
        orderCommodityList.push({ shoppingCartId: item.shoppingCartId });
      });
      addDcOrdercenterOrder({
        totalVolume: this.buyCounterVolumeSum.toFixed(2),
        totalBoxNumber: this.buyCounterCtnSum,
        totalMoney: this.buyCounterMoneySum,
        orderCommodityList: orderCommodityList,
      }).then((res) => {
        this.dialogVisible = true;
        this.orderId = res.data.orderId;
        // this.$router.push("/goods/goodOrder");
        // this.$notify({
        //   title: "成功",
        //   message: "订单已创建",
        //   type: "success",
        // });
      });
    },
    selectItem(event, index) {
      if (event == true) {
        this.$set(this.cartList[index], "checked", true);
      } else {
        this.$set(this.cartList[index], "checked", false);
      }
      this.buyCounterPcsSum = 0;
      this.buyCounterVolumeSum = 0;
      this.buyCounterCtnSum = 0;
      this.buyCounterMoneySum = 0;

      this.cartList.forEach((item) => {
        if (item.checked == true) {
          this.buyCounterPcsSum = this.buyCounterPcsSum * 1 + item.countSum;
          this.buyCounterVolumeSum = this.buyCounterVolumeSum * 1 + item.volume;
          this.buyCounterCtnSum = this.buyCounterCtnSum * 1 + item.boxNumber;
          this.buyCounterMoneySum = this.buyCounterMoneySum * 1 + item.totalPrice;
        }
      });

      this.$forceUpdate();
    },
    allSelect(event) {
      this.buyCounterPcsSum = 0;
      this.buyCounterVolumeSum = 0;
      this.buyCounterCtnSum = 0;
      this.buyCounterMoneySum = 0;
      if (event == true) {
        this.cartList.forEach((item) => {
          item.checked = true;
          this.buyCounterPcsSum = this.buyCounterPcsSum * 1 + item.countSum;
          this.buyCounterVolumeSum = this.buyCounterVolumeSum * 1 + item.volume;
          this.buyCounterCtnSum = this.buyCounterCtnSum * 1 + item.boxNumber;
          this.buyCounterMoneySum = this.buyCounterMoneySum * 1 + item.totalPrice;
        });
      } else {
        this.cartList.forEach((item) => {
          item.checked = false;
        });

        this.buyCounterPcsSum = 0;
        this.buyCounterVolumeSum = 0;
        this.buyCounterCtnSum = 0;
        this.buyCounterMoneySum = 0;
      }
    },

    // =====================================================修改箱数==============================================================
    decrementPacking(item, index) {
      if (item.boxNumber == 1) {
        item.boxNumber = 1;
      } else {
        item.boxNumber--;
      }
      item.countSum = 0;
      getDcBasearchiveCommodityPackingratio({
        cInvCode: item.cinvCode,
        pageNum: 1,
        pageSize: 100,
      }).then((res) => {
        console.log(res);
        this.cartList[index].colorList = res.data;
        this.cartList[index].shoppingCartDeatailList.forEach((itemp) => {
          this.cartList[index].colorList.forEach((items) => {
            if (itemp.peibiId == items.id) {
              itemp.colorQuantity = items.fpeiBi * item.boxNumber;
            }
          });
          item.countSum = itemp.colorQuantity * 1 + item.countSum * 1;
          item.totalPrice = item.countSum * item.picePrice;
          item.volume = (item.ivolume / item.packingQuantity / 1000000) * item.countSum;
        });
        this.buyCounterPcsSum = 0;
        this.buyCounterVolumeSum = 0;
        this.buyCounterCtnSum = 0;
        this.buyCounterMoneySum = 0;

        this.cartList.forEach((item) => {
          if (item.checked == true) {
            this.buyCounterPcsSum = this.buyCounterPcsSum * 1 + item.countSum;
            this.buyCounterVolumeSum = this.buyCounterVolumeSum * 1 + item.volume;
            this.buyCounterCtnSum = this.buyCounterCtnSum * 1 + item.boxNumber;
            this.buyCounterMoneySum = this.buyCounterMoneySum * 1 + item.totalPrice;
          }
        });
        let shoppingCartDeatailList = [];
        this.cartList[index].shoppingCartDeatailList.forEach((item) => {
          shoppingCartDeatailList.push({
            colorName: item.colorName,
            peibiId: item.peibiId,
            colorQuantity: item.colorQuantity,
            shoppingCartId: item.shoppingCartId,
          });
        });
        editDcShoppingmallShoppingcart({
          shoppingCartId: this.cartList[index].shoppingCartId,
          picePrice: this.cartList[index].picePrice,
          totalPrice: this.cartList[index].totalPrice,
          quantity: this.cartList[index].boxNumber,
          sellAttributeId: this.cartList[index].sellAttributeId,
          cinvCode: this.cartList[index].cinvCode,
          shoppingCartDeatailList: shoppingCartDeatailList,
        }).then((res) => {
          if (res.code == 200) {
          }
        });
      });

      this.$forceUpdate();
    },
    incrementPacking(item, index) {
      item.boxNumber++;
      item.countSum = 0;
      getDcBasearchiveCommodityPackingratio({
        cInvCode: item.cinvCode,
        pageNum: 1,
        pageSize: 100,
      }).then((res) => {
        console.log(res);
        this.cartList[index].colorList = res.data;
        this.cartList[index].shoppingCartDeatailList.forEach((itemp) => {
          this.cartList[index].colorList.forEach((items) => {
            if (itemp.peibiId == items.id) {
              itemp.colorQuantity = items.fpeiBi * item.boxNumber;
            }
          });
          item.countSum = itemp.colorQuantity * 1 + item.countSum * 1;
          item.totalPrice = item.countSum * item.picePrice;
          item.volume = (item.ivolume / item.packingQuantity / 1000000) * item.countSum;
        });
        this.buyCounterPcsSum = 0;
        this.buyCounterVolumeSum = 0;
        this.buyCounterCtnSum = 0;
        this.buyCounterMoneySum = 0;

        this.cartList.forEach((item) => {
          if (item.checked == true) {
            this.buyCounterPcsSum = this.buyCounterPcsSum * 1 + item.countSum;
            this.buyCounterVolumeSum = this.buyCounterVolumeSum * 1 + item.volume;
            this.buyCounterCtnSum = this.buyCounterCtnSum * 1 + item.boxNumber;
            this.buyCounterMoneySum = this.buyCounterMoneySum * 1 + item.totalPrice;
          }
        });
        let shoppingCartDeatailList = [];
        this.cartList[index].shoppingCartDeatailList.forEach((item) => {
          shoppingCartDeatailList.push({
            colorName: item.colorName,
            peibiId: item.peibiId,
            colorQuantity: item.colorQuantity,
            shoppingCartId: item.shoppingCartId,
          });
        });
        editDcShoppingmallShoppingcart({
          shoppingCartId: this.cartList[index].shoppingCartId,
          picePrice: this.cartList[index].picePrice,
          totalPrice: this.cartList[index].totalPrice,
          quantity: this.cartList[index].boxNumber,
          sellAttributeId: this.cartList[index].sellAttributeId,
          cinvCode: this.cartList[index].cinvCode,
          shoppingCartDeatailList: shoppingCartDeatailList,
        }).then((res) => {
          if (res.code == 200) {
          }
        });
      });
      this.$forceUpdate();
    },
    // =====================================================零批数量减少==============================================================
    decrementItem(item, index, indexFather) {
      console.log(item);
      this.buyCountSum = 0;
      this.buyCountSumMoney = 0;
      if (item.colorQuantity <= 1) {
        // this.$set(
        //   this.cartList[indexFather].shoppingCartDeatailListCopy[index],
        //   "colorQuantity",
        //   1
        // );
        this.$set(
          this.cartList[indexFather].shoppingCartDeatailList[index],
          "colorQuantity",
          1
        );
        this.cartList[indexFather].countSum = 0;
        this.cartList[indexFather].shoppingCartDeatailList.forEach((itemp) => {
          console.log(itemp.colorQuantity);
          this.cartList[indexFather].countSum =
            itemp.colorQuantity * 1 + this.cartList[indexFather].countSum * 1;
        });
        if (
          this.cartList[indexFather].countSum %
            this.cartList[indexFather].packingQuantity !=
          0
        ) {
          this.cartList[indexFather].boxNumber = Math.ceil(
            this.cartList[indexFather].countSum /
              this.cartList[indexFather].packingQuantity
          );
          this.cartList[indexFather].volume =
            (this.cartList[indexFather].ivolume /
              this.cartList[indexFather].packingQuantity /
              1000000) *
            this.cartList[indexFather].countSum;
          this.cartList[indexFather].totalPrice =
            this.cartList[indexFather].picePrice * this.cartList[indexFather].countSum;
        } else {
          this.cartList[indexFather].boxNumber =
            this.cartList[indexFather].countSum /
            this.cartList[indexFather].packingQuantity;
          this.cartList[indexFather].volume =
            (this.cartList[indexFather].ivolume /
              this.cartList[indexFather].packingQuantity /
              1000000) *
            this.cartList[indexFather].countSum;
          this.cartList[indexFather].totalPrice =
            this.cartList[indexFather].picePrice * this.cartList[indexFather].countSum;
        }
        this.buyCounterPcsSum = 0;
        this.buyCounterVolumeSum = 0;
        this.buyCounterCtnSum = 0;
        this.buyCounterMoneySum = 0;

        this.cartList.forEach((item) => {
          if (item.checked == true) {
            this.buyCounterPcsSum = this.buyCounterPcsSum * 1 + item.countSum;
            this.buyCounterVolumeSum = this.buyCounterVolumeSum * 1 + item.volume;
            this.buyCounterCtnSum = this.buyCounterCtnSum * 1 + item.boxNumber;
            this.buyCounterMoneySum = this.buyCounterMoneySum * 1 + item.totalPrice;
          }
        });
        this.$forceUpdate();
        return;
      }
      // this.$set(
      //   this.cartList[indexFather].shoppingCartDeatailListCopy[index],
      //   "colorQuantity",
      //   item.colorQuantity - 1
      // );
      this.$set(
        this.cartList[indexFather].shoppingCartDeatailList[index],
        "colorQuantity",
        item.colorQuantity - 1
      );
      this.cartList[indexFather].countSum = 0;
      this.cartList[indexFather].shoppingCartDeatailList.forEach((itemp) => {
        console.log(itemp.colorQuantity);
        this.cartList[indexFather].countSum =
          itemp.colorQuantity * 1 + this.cartList[indexFather].countSum * 1;
      });
      if (
        this.cartList[indexFather].countSum %
          this.cartList[indexFather].packingQuantity !=
        0
      ) {
        this.cartList[indexFather].boxNumber = Math.ceil(
          this.cartList[indexFather].countSum / this.cartList[indexFather].packingQuantity
        );
        this.cartList[indexFather].volume =
          (this.cartList[indexFather].ivolume /
            this.cartList[indexFather].packingQuantity /
            1000000) *
          this.cartList[indexFather].countSum;
        this.cartList[indexFather].totalPrice =
          this.cartList[indexFather].picePrice * this.cartList[indexFather].countSum;
      } else {
        this.cartList[indexFather].boxNumber =
          this.cartList[indexFather].countSum /
          this.cartList[indexFather].packingQuantity;
        this.cartList[indexFather].ivolume = this.cartList[indexFather].volume =
          (this.cartList[indexFather].ivolume /
            this.cartList[indexFather].packingQuantity /
            1000000) *
          this.cartList[indexFather].countSum;
        this.cartList[indexFather].totalPrice =
          this.cartList[indexFather].picePrice * this.cartList[indexFather].countSum;
      }
      this.buyCounterPcsSum = 0;
      this.buyCounterVolumeSum = 0;
      this.buyCounterCtnSum = 0;
      this.buyCounterMoneySum = 0;

      this.cartList.forEach((item) => {
        if (item.checked == true) {
          this.buyCounterPcsSum = this.buyCounterPcsSum * 1 + item.countSum;
          this.buyCounterVolumeSum = this.buyCounterVolumeSum * 1 + item.volume;
          this.buyCounterCtnSum = this.buyCounterCtnSum * 1 + item.boxNumber;
          this.buyCounterMoneySum = this.buyCounterMoneySum * 1 + item.totalPrice;
        }
      });
      let shoppingCartDeatailList = [];
      this.cartList[indexFather].shoppingCartDeatailList.forEach((item) => {
        shoppingCartDeatailList.push({
          colorName: item.colorName,
          peibiId: item.peibiId,
          colorQuantity: item.colorQuantity,
          shoppingCartId: item.shoppingCartId,
        });
      });
      editDcShoppingmallShoppingcart({
        shoppingCartId: this.cartList[indexFather].shoppingCartId,
        picePrice: this.cartList[indexFather].picePrice,
        totalPrice: this.cartList[indexFather].totalPrice,
        quantity: this.cartList[indexFather].boxNumber,
        sellAttributeId: this.cartList[indexFather].sellAttributeId,
        cinvCode: this.cartList[indexFather].cinvCode,
        shoppingCartDeatailList: shoppingCartDeatailList,
      }).then((res) => {
        if (res.code == 200) {
        }
      });
      this.$forceUpdate();
    },
    // =============================================================================================================================

    // =====================================================零批数量增加==============================================================
    incrementItem(item, index, indexFather) {
      // this.$set(
      //   this.cartList[indexFather].shoppingCartDeatailListCopy[index],
      //   "colorQuantity",
      //   item.colorQuantity * 1 + 1
      // );
      this.$set(
        this.cartList[indexFather].shoppingCartDeatailList[index],
        "colorQuantity",
        item.colorQuantity * 1 + 1
      );
      this.cartList[indexFather].countSum = 0;
      this.cartList[indexFather].shoppingCartDeatailList.forEach((itemp) => {
        console.log(itemp.colorQuantity);
        this.cartList[indexFather].countSum =
          itemp.colorQuantity * 1 + this.cartList[indexFather].countSum * 1;
      });
      if (
        this.cartList[indexFather].countSum %
          this.cartList[indexFather].packingQuantity !=
        0
      ) {
        this.cartList[indexFather].boxNumber = Math.ceil(
          this.cartList[indexFather].countSum / this.cartList[indexFather].packingQuantity
        );
        this.cartList[indexFather].volume =
          (this.cartList[indexFather].ivolume /
            this.cartList[indexFather].packingQuantity /
            1000000) *
          this.cartList[indexFather].countSum;
        this.cartList[indexFather].totalPrice =
          this.cartList[indexFather].picePrice * this.cartList[indexFather].countSum;
      } else {
        this.cartList[indexFather].boxNumber =
          this.cartList[indexFather].countSum /
          this.cartList[indexFather].packingQuantity;
        this.cartList[indexFather].volume =
          (this.cartList[indexFather].ivolume /
            this.cartList[indexFather].packingQuantity /
            1000000) *
          this.cartList[indexFather].countSum;
        this.cartList[indexFather].totalPrice =
          this.cartList[indexFather].picePrice * this.cartList[indexFather].countSum;
      }
      this.buyCounterPcsSum = 0;
      this.buyCounterVolumeSum = 0;
      this.buyCounterCtnSum = 0;
      this.buyCounterMoneySum = 0;

      this.cartList.forEach((item) => {
        if (item.checked == true) {
          this.buyCounterPcsSum = this.buyCounterPcsSum * 1 + item.countSum;
          this.buyCounterVolumeSum = this.buyCounterVolumeSum * 1 + item.volume;
          this.buyCounterCtnSum = this.buyCounterCtnSum * 1 + item.boxNumber;
          this.buyCounterMoneySum = this.buyCounterMoneySum * 1 + item.totalPrice;
        }
      });
      let shoppingCartDeatailList = [];
      this.cartList[indexFather].shoppingCartDeatailList.forEach((item) => {
        shoppingCartDeatailList.push({
          colorName: item.colorName,
          peibiId: item.peibiId,
          colorQuantity: item.colorQuantity,
          shoppingCartId: item.shoppingCartId,
        });
      });
      editDcShoppingmallShoppingcart({
        shoppingCartId: this.cartList[indexFather].shoppingCartId,
        picePrice: this.cartList[indexFather].picePrice,
        totalPrice: this.cartList[indexFather].totalPrice,
        quantity: this.cartList[indexFather].boxNumber,
        sellAttributeId: this.cartList[indexFather].sellAttributeId,
        cinvCode: this.cartList[indexFather].cinvCode,
        shoppingCartDeatailList: shoppingCartDeatailList,
      }).then((res) => {
        if (res.code == 200) {
        }
      });
      this.$forceUpdate();
    },
    // =============================================================================================================================
    muchShowClick(index) {
      if (this.muchShow == true) {
        this.cartList[index].shoppingCartDeatailListCopy = this.cartList[
          index
        ].shoppingCartDeatailList;
      } else {
        this.cartList[index].shoppingCartDeatailListCopy = this.cartList[
          index
        ].shoppingCartDeatailList.slice(0, 4);
      }
      this.muchShow = !this.muchShow;
    },
    goToChangeItem(item) {
      // window.open(
      //   this.$locationViewUrl+"#/goods/Detail?cinvCode=" + item.cinvCode,
      //   "_blank"
      // );
      window.open(
        this.$locationViewUrl +
          "#/goods/cartItemDetail?cinvCode=" +
          item.cinvCode +
          "&brandName=" +
          this.brandName +
          "&shoppingCartId=" +
          item.shoppingCartId,
        "_blank"
      );
    },
    deleteCartItem(index) {
      this.$confirm("此操作将该商品从购物车中删除, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.buyCounterPcsSum = 0;
          this.buyCounterVolumeSum = 0;
          this.buyCounterCtnSum = 0;
          this.buyCounterMoneySum = 0;
          this.cartList.splice(index, 1);
          this.cartList.forEach((item) => {
            item.checked = false;

            item.countSum = 0;
            item.shoppingCartDeatailList.forEach((itemp) => {
              console.log(itemp.colorQuantity);
              item.countSum = itemp.colorQuantity * 1 + item.countSum * 1;
            });
            if (item.countSum % item.packingQuantity != 0) {
              item.boxNumber = Math.ceil(item.countSum / item.packingQuantity);
            } else {
              item.boxNumber = item.countSum / item.packingQuantity;
            }
            item.totalPrice = item.picePrice * item.countSum;
            item.volume = (item.ivolume / item.packingQuantity / 1000000) * item.countSum;
            item.imageUrl = this.$locationUrl + "file/" + item.imageUrl;
            console.log(item.imageUrl);
            item.shoppingCartDeatailList.forEach((items) => {
              items.imageUrls =
                this.$locationUrl +
                "file/abyFile/commodityFile/commodityColorImage/" +
                items.fileName;
              items.imageUrlLists = [item.imageUrls];
              console.log(items.imageUrls);
            });
            item.shoppingCartDeatailListCopy = item.shoppingCartDeatailList.slice(0, 4);
            this.buyCounterPcsSum = this.buyCounterPcsSum * 1 + item.countSum;
            this.buyCounterVolumeSum = this.buyCounterVolumeSum * 1 + item.volume;
            this.buyCounterCtnSum = this.buyCounterCtnSum * 1 + item.boxNumber;
            this.buyCounterMoneySum = this.buyCounterMoneySum * 1 + item.totalPrice;
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },

    buyGoods(item) {
      // this.$store.commit('getGoodsInfo', item)
      this.$router.push({ path: "/goods/goodOrderDetail", query: { brandName: this.brandName } });
    },
    getImageHeight() {
      this.dynamicHeight = this.$refs.images.height + "px";
    },
    sumChange(a, b) {
      console.log("3213123");
      console.log(a, b);
      if (a == 1) {
        this.allShop = this.allShop * 1 + b * 1;
      } else {
        this.allShop = this.allShop * 1 - b * 1;
      }
    },

    deleteMultipe() {
      this.dialogTableVisible = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@mixin wh($w, $h: $w) {
  width: $w;
  height: $h;
}
.counter1 {
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 50%;
  padding: 5px;
  border: 1px solid #8e8e8e;
  position: relative;
  line-height: 31px;
  border-radius: 40px;
}
.counters {
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
  padding: 5px;
  border: 1px solid #8e8e8e;
  position: relative;
  line-height: 11px;
  border-radius: 40px;
}

// .counters span {
//   position: absolute;
//   left: 50%;
//   font-size: 15px;
//   top: 50%;
//   transform: translate(-50%, -50%);
// }

// .counters::before,
// .counters::after {
//   content: "";
//   cursor: pointer;
// }

// .counters::before {
//   content: "-";
//   font-size: 12px;
//   margin-left: 4px;
// }

// .counters::after {
//   content: "+";
//   font-size: 12px;
//   margin-right: 4px;
// }
.counter {
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 70%;
  padding: 5px;
  border: 2px solid #d7d7d7;
  position: relative;

  border-radius: 12px;
  /* height: 40%; */
  line-height: 11px;
}
.shopping {
  .content {
    background-color: #fff;

    margin-bottom: 45px;
    font-family: "Microsoft YaHei";
    padding-bottom: 10px;
  }
}

.hd,
.cart-table-th {
  padding: 0 18px;
  align-items: center;
  justify-content: space-between;
}

.hd {
  height: 72px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e6e6e6;

  .car {
    color: #000;
    font-size: 18px;
    font-weight: 600;
  }

  .cart-sum {
    strong {
      font-size: 22px;
      font-weight: 700;
      color: #f50;
      margin-right: 12px;
    }

    a {
      $h: 42px;
      display: inline-block;
      text-align: center;
      line-height: $h;
      @include wh(74px, $h);
      background: #aaa;
      color: #fff;
      font-size: 16px;
      border-radius: 21px;
      cursor: not-allowed;
    }

    .active {
      background-color: #f50;
      cursor: pointer;
    }
  }
}

.cartmain {
  user-select: none;
  $h: 50px;

  .cart-table-th {
    padding: 0 25px;
    height: $h;
    margin-bottom: 15px;
  }

  .th {
    height: 100%;
    color: #3c3c3c;
    font-weight: bold;
    font-size: 14px;
    line-height: $h;
  }

  .default-list {
    color: #aaaaaa;
    font-size: 25px;
    font-weight: 700;
    text-align: center;
    line-height: 300px;
  }
}
</style>
<style lang="scss">
.th {
  flex: 2;
}

.th-chk {
  flex: 1.2;

  // width: 150px;
  input,
  label {
    cursor: pointer;
  }

  input {
    vertical-align: middle;
    margin-right: 8px;
  }

  label {
    width: 50px;
    height: 50px;
    display: inline-block;
  }
}

.th-info {
  flex: 2.8;
}

.th-price,
.th-amount,
.th-sum,
.th-op {
  text-align: center;
}
</style>

<template>
  <div>
    <div class="detail">
      <TopBar :isHome="isHome"></TopBar>
      <SearchHome :isHome="isHome"></SearchHome>

      <div class="container">
        <div class="right" style="overflow: scroll">
          <div
            style="
              display: flex;
              justify-content: space-between;
              width: 86%;
              line-height: 80px;
            "
          >
            <div style="font-size: 22px; text-align: center">
              确认订单<span style="font-size: 14px">(2)</span>
            </div>
            <div style="color: #7e7e7e">继续选购<i class="el-icon-arrow-right"></i></div>
          </div>
          <div
            v-for="(item, index) in currentItem.detail"
            :key="index"
            style="display: flex; margin-bottom: 80px"
          >
            <div style="width: 50%">
              <el-image style="width: 100%" :src="item" :preview-src-list="[item]">
              </el-image>
            </div>
            <div
              style="
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
              "
            >
              <div
                class="content home-width com-margin-center"
                style="width: 68%; margin: 0 20px; border-top: #9f9f9f 2px solid"
              >
                <div
                  style="
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-start;
                    width: 100%;
                    margin-top: 20px;
                  "
                >
                  <div style="display: flex; align-items: center; width: 100%">
                    <div
                      style="
                        width: 70%;
                        font-size: 15px;
                        color: #8d8d8d;

                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                      "
                      :style="{ height: imageHight + 'px' }"
                    >
                      <div style="color: #282828; font-size: 16px; font-weight: 500">
                        BG012211222
                      </div>
                      <div>CHRISBELLA-双手腕女包</div>
                      <div>单件价格￥899</div>
                      <div style="font-size: 14px">现货</div>
                    </div>
                  </div>
                  <div
                    style="
                      display: flex;
                      align-items: flex-start;
                      width: 40%;
                      justify-content: end;
                    "
                  >
                    <div
                      style="
                        margin-left: 40px;
                        display: flex;
                        flex-direction: column;
                        align-items: end;
                      "
                    >
                      <div>
                        <i
                          class="el-icon-delete"
                          style="
                            font-size: 15px;
                            font-weight: 500;
                            margin-bottom: 10px;
                            justify-content: baseline;
                          "
                        ></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style="
                    margin-top: 30px;
                    display: flex;
                    justify-content: space-between;
                    align-items: end;
                  "
                >
                  <div style="display: flex; flex-wrap: wrap">
                    <div
                      style="
                        display: flex;
                        aligin-item: center;
                        flex-direction: column;
                        margin-right: 10px;
                        margin-bottom: 10px;
                      "
                    >
                      <el-image
                        style="
                          width: 65px;
                          height: 65px;

                          margin-bottom: 10px;
                        "
                        src="http://192.168.1.12:8099/file/abyFile/backGround/26.jpg"
                        :preview-src-list="[
                          'http://192.168.1.12:8099/file/abyFile/backGround/26.jpg',
                        ]"
                      >
                      </el-image>
                      <div style="width: 100%; justify-content: center">
                        <div class="counters">
                          <span style="font-weight: 500">10</span>
                        </div>
                      </div>
                    </div>
                    <div
                      style="
                        display: flex;
                        aligin-item: center;
                        flex-direction: column;
                        margin-right: 10px;
                        margin-bottom: 10px;
                      "
                    >
                      <el-image
                        style="
                          width: 65px;
                          height: 65px;

                          margin-bottom: 10px;
                        "
                        src="http://192.168.1.12:8099/file/abyFile/backGround/25.jpg"
                        :preview-src-list="[
                          'http://192.168.1.12:8099/file/abyFile/backGround/25.jpg',
                        ]"
                      >
                      </el-image>
                      <div style="width: 100%; justify-content: center">
                        <div class="counters">
                          <span style="font-weight: 500">5</span>
                        </div>
                      </div>
                    </div>
                    <div
                      style="
                        display: flex;
                        aligin-item: center;
                        flex-direction: column;
                        margin-right: 10px;
                        margin-bottom: 10px;
                      "
                    >
                      <el-image
                        style="
                          width: 65px;
                          height: 65px;

                          margin-bottom: 10px;
                        "
                        src="http://192.168.1.12:8099/file/abyFile/backGround/24.jpg"
                        :preview-src-list="[
                          'http://192.168.1.12:8099/file/abyFile/backGround/24.jpg',
                        ]"
                      >
                      </el-image>
                      <div style="width: 100%; justify-content: center">
                        <div class="counters">
                          <span style="font-weight: 500">10</span>
                        </div>
                      </div>
                    </div>
                    <div
                      style="
                        display: flex;
                        aligin-item: center;
                        flex-direction: column;
                        margin-right: 10px;
                        margin-bottom: 10px;
                      "
                    >
                      <el-image
                        style="
                          width: 65px;
                          height: 65px;

                          margin-bottom: 10px;
                        "
                        src="http://192.168.1.12:8099/file/abyFilebackGround/24.jpg"
                        :preview-src-list="[
                          'http://192.168.1.12:8099/file/abyFile/backGround/24.jpg',
                        ]"
                      >
                      </el-image>
                      <div style="width: 100%; justify-content: center">
                        <div class="counters">
                          <span style="font-weight: 500">10</span>
                        </div>
                      </div>
                    </div>

                    <div
                      style="
                        display: flex;
                        aligin-item: center;
                        flex-direction: column;
                        margin-right: 10px;
                        margin-bottom: 10px;
                      "
                    >
                      <el-image
                        style="
                          width: 65px;
                          height: 65px;

                          margin-bottom: 10px;
                        "
                        src="http://192.168.1.12:8099/file/abyFile/backGround/23.jpg"
                        :preview-src-list="[
                          'http://192.168.1.12:8099/file/abyFile/backGround/23.jpg',
                        ]"
                      >
                      </el-image>
                      <div style="width: 100%; justify-content: center">
                        <div class="counters">
                          <span style="font-weight: 500">5</span>
                        </div>
                      </div>
                    </div>
                    <div
                      style="
                        display: flex;
                        aligin-item: center;
                        flex-direction: column;
                        margin-right: 10px;
                      "
                    >
                      <el-image
                        style="
                          width: 65px;
                          height: 65px;

                          margin-bottom: 10px;
                        "
                        src="http://192.168.1.12:8099/file/abyFile/backGround/22.jpg"
                        :preview-src-list="[
                          'http://192.168.1.12:8099/file/abyFile/backGround/22.jpg',
                        ]"
                      >
                      </el-image>
                      <div style="width: 100%; justify-content: center">
                        <div class="counters">
                          <span style="font-weight: 500">10</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                style="
                  display: flex;
                  align-items: center;
                  width: 69%;
                  justify-content: space-between;
                  border-bottom: #9f9f9f 2px solid;
                  margin: 0px 20px;
                  padding-bottom: 30px;
                "
              >
                <div style="width: 40%; margin-left: 15px">
                  <div class="counter"><span style="font-weight: 500">5</span></div>
                </div>

                <div
                  style="
                    margin-left: 40px;
                    display: flex;
                    flex-direction: column;
                    align-items: end;
                  "
                >
                  <div style="font-size: 23px; font-weight: 900">￥899</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="left">
          <div style="width: 100%">
            <div class="hd com-flex" style="display: flex; line-height: 80px">
              <span class="car"
                >确认收货地址<span v-show="shopItems.length !== 0"></span
              ></span>
              <div style="display: flex; align-items: center">
                <div
                  class="cart-sum"
                  style="margin-right: 20px"
                  @click="dialogTableVisibles = true"
                >
                  <span>修改该地址</span>
                </div>
                <div class="cart-sum" style="margin-right: 20px">
                  <span>设置为默认地址</span>
                </div>
                <div class="cart-sum" @click="dialogTableVisible = true">
                  <span>管理收货地址</span>
                </div>
              </div>
            </div>
            <div>
              <el-radio-group v-model="radio">
                <div>
                  <div style="padding: 10px 0">
                    <div>
                      <el-radio :label="1">
                        <span style="font-size: 15px; font-weight: 700"
                          >广东省广州市天河区燕岭路633号(xxx收)13266479854</span
                        ></el-radio
                      >
                    </div>
                  </div>
                  <div style="padding: 10px 0">
                    <div>
                      <el-radio :label="2"
                        ><span style="font-size: 15px; font-weight: 700"
                          >广东省广州市天河区燕岭路燕塘燕岭路25号(xxx收)13266479854</span
                        ></el-radio
                      >
                    </div>
                  </div>
                  <div style="padding: 10px 0">
                    <div>
                      <el-radio :label="3"
                        ><span style="font-size: 15px; font-weight: 700"
                          >广州市天河区天源路1190号(xxx收)13266479854</span
                        ></el-radio
                      >
                    </div>
                  </div>
                  <div style="padding: 10px 0">
                    <div>
                      <el-radio :label="4"
                        ><span style="font-size: 15px; font-weight: 700"
                          >广东省广州市白云区鹤龙二路85号(xxx收)13266479854</span
                        ></el-radio
                      >
                    </div>
                  </div>
                </div>
              </el-radio-group>
            </div>
          </div>
          <div style="width: 100%; margin-top: 30px">
            <img
              src="http://192.168.1.12:8099/file/abyFile/backGround/5427.png"
              alt=""
              style="width: 100%"
            />
          </div>
          <div style="width: 100%; margin-top: 30px">
            <div
              style="display: flex; justify-content: space-between; align-items: center;line-height: 30px;font-weight: 600;"
            >
              <div>小计</div>
              <div>￥899</div>
            </div>
            <div
              style="display: flex; justify-content: space-between; align-items: center;line-height: 30px;font-weight: 600;"
            >
              <div>运费</div>
              <div>￥0</div>
            </div>
            <div
              style="display: flex; justify-content: space-between; align-items: center;line-height: 30px;font-weight: 600; "
            >
              <div>总金额</div>
              <div>￥899</div>
            </div>
            <div
              style="
                width: 100%;
                justify-content: center;
                margin-top: 20px;
                display: flex;
                height: 50px;
              "
            >
              <div
                class="counter1"
                style="
                  font-weight: 500;
                  background: black;
                  color: white;
                  text-align: center;
                  justify-content: center;
                "
              >
                <div @click="buyGoods">立即结算</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import shopOrder from "./shopOrder/index.vue";
import Zoom from "./Detail/Zoom.vue";
import ImgList from "./Detail/ImgList.vue";
import InfoDetail from "./Detail/InfoDetail.vue";
import InfoDetailOther from "./Detail/infoDetailOther.vue";
import { mapState } from "vuex";
export default {
  name: "DetailPrd",
  data() {
    return {
      isHome: false,
      skuId: undefined,
      dialogTableVisible: false,
      shopItems: true,
      skuNum: 1,
      dialogImageUrl: "",
      currentItem: {
        id: 1,
        ishot: false,
        img: "https://www.gqedu.org.cn:6868/manage/72411650fc654bd3a7c9747904612799.JPG",
        content: "CHRISBELLA-双手腕女包-BG012211222",
        price: "899",
        dialogVisible: false,
        detail: [
          "http://192.168.1.12:8099/file/abyFile/backGround/22.jpg",
          "http://192.168.1.12:8099/file/abyFile/backGround/23.jpg",
          "http://192.168.1.12:8099/file/abyFile/backGround/24.jpg",
          "http://192.168.1.12:8099/file/abyFile/backGround/24.jpg",
          "http://192.168.1.12:8099/file/abyFile/backGround/26.jpg",
        ],
      },
      hotgoodsList: [
        {
          cover: "http://192.168.1.12:8099/file/abyFile/backGround/2.jpg",
          name: "CHRISBELLA-女包-双手腕",
          cost: 1000,
        },
        {
          cover: "http://192.168.1.12:8099/file/abyFile/backGround/3.jpg",
          name: "BAGCO-女包-双手腕",
          cost: 1000,
        },
        {
          cover: "http://192.168.1.12:8099/file/abyFile/backGround/4.jpg",
          name: "CHRISBELLA-女包-双手腕",
          cost: 1000,
        },
        {
          cover: "http://192.168.1.12:8099/file/abyFile/backGround/5.jpg",
          name: "CHRISBELLA-女包-双手腕",
          cost: 1000,
        },
      ],
    };
  },
  // computed: {
  //     ...mapState(['currentItem'])
  // },
  mounted() {
    document.title = this.currentItem.content;
    // 当前商品为空，跳装到首页
    if (Object.keys(this.currentItem).length === 0) {
      this.$router.push({ name: "home" });
    }
    window.scrollTo(0, 0);
  },
  components: {
    Zoom,
    ImgList,
    InfoDetail,
    InfoDetailOther,
    shopOrder,
  },
  methods: {
    dialogVisibleClick(item) {
      this.dialogVisible = true;
      this.dialogImageUrl = item;
    },
    changeImage(v) {
      console.log(v);
      let that = this;
      this.$nextTick(() => {
        that.$set(that.currentItem, "img", v);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.counter1 {
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
  padding: 5px;
  border: 1px solid #8e8e8e;
  position: relative;
  line-height: 31px;
  border-radius: 40px;
}
.counters {
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 85%;
  padding: 5px;
  border: 1px solid #8e8e8e;
  position: relative;
  line-height: 11px;
  border-radius: 40px;
}

.counters span {
  position: absolute;
  left: 50%;
  font-size: 15px;
  top: 50%;
  transform: translate(-50%, -50%);
}

.counters::before,
.counters::after {
  content: "";
  cursor: pointer;
}

.counters::before {
  content: "-";
  font-size: 12px;
  margin-left: 4px;
}

.counters::after {
  content: "+";
  font-size: 12px;
  margin-right: 4px;
}
.counter {
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 77%;
  padding: 5px;
  border: 2px solid #d7d7d7;
  position: relative;

  border-radius: 12px;
  /* height: 40%; */
  line-height: 56px;
}

.counter span {
  position: absolute;
  left: 50%;
  font-size: 25px;
  top: 50%;
  transform: translate(-50%, -50%);
}

.counter::before,
.counter::after {
  content: "";
  cursor: pointer;
}

.counter::before {
  content: "-";
  font-size: 30px;
  margin-left: 10px;
}

.counter::after {
  content: "+";
  font-size: 30px;
  margin-right: 10px;
}
.new_goods_title_hot {
  height: 40px;

  margin-top: 140px;
  margin-bottom: 140px;

  span {
    font-size: 40px;
    line-height: 28px;

    padding-left: 15px;
    font-weight: bold;
    font-size: 40px;
  }
}
.recommend {
  margin-top: 20px;

  .item {
    // height: 510px;
    margin-left: 20px;
    margin-bottom: 12px;
    width: 21%;

    .recommend_left {
      height: 280px;
      width: 360px;

      img {
        width: 400px;
        height: 320px;
      }

      &:hover {
        cursor: pointer;
      }
    }

    &:nth-child(1),
    &:nth-child(4n + 1) {
      margin-left: 0;
    }

    dl {
      width: 100%;
      // border: 1px solid #eee;

      &:hover {
        cursor: pointer;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.3);
        width: 100%;
      }

      img {
        width: 100%;
        // height: 400px;
        // &:hover {
        //   width: 440px;
        //   width: 440px;
        // }
      }

      dd {
        padding: 0 5px;
      }

      .title {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 15px;
        font-weight: 700;
        color: black;
        line-height: 28px;
      }

      .other {
        .price {
          font-size: 17px;
          color: black;
          font-weight: 600;
        }

        .wei {
          font-size: 14px;
          color: #666;
        }
      }

      .btn {
        line-height: 28px;
        text-align: center;
        color: black;
        border: 1px solid black;
        box-sizing: border-box;
        border-radius: 30px;
        margin: 6px auto 10px auto;
        display: block;
        line-height: 20px;
        font-size: 14px;
        font-weight: 700;
        &:hover {
          color: #fff;
          background: black;
        }
      }
    }
  }

  .null {
    width: 188px;
    border: 1px solid #eee;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.container {
  display: flex;
}
.left {
  position: sticky;
  margin-right: 5%;
  top: 150px;
  height: 100vh; /* 设置左侧模块高度为视口高度 */
  width: 30%; /* 设置左侧模块宽度 */
  display: flex;
  flex-direction: column;
  //   justify-content: center;
  .hd {
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e6e6e6;

    .car {
      color: #000;
      font-size: 15px;
      font-weight: 600;
    }

    .cart-sum {
      font-size: 14px;
      strong {
        font-size: 14px;
        font-weight: 700;
        color: #f50;
        margin-right: 12px;
      }

      a {
        $h: 42px;
        display: inline-block;
        text-align: center;
        line-height: $h;
        //   @include wh(74px, $h);
        background: #aaa;
        color: #fff;
        font-size: 16px;
        border-radius: 21px;
        cursor: not-allowed;
      }

      .active {
        background-color: #f50;
        cursor: pointer;
      }
    }
  }
}
.right {
  width: 60%;
  margin-left: 5%;
  overflow: scroll;
  height: 100vh; /* 设置右侧模块高度，使其能够滚动 */
  // background: lightgreen;
}
// .left::-webkit-scrollbar {
//   display: none;
// }

// /* 隐藏 IE 和 Edge 浏览器滚动条 */
// .left {
//   -ms-overflow-style: none;
// }
.right::-webkit-scrollbar {
  display: none;
}

/* 隐藏 IE 和 Edge 浏览器滚动条 */
.right {
  -ms-overflow-style: none;
}
.tb-item-info {
  padding: 20px;
  position: relative;
  box-sizing: border-box;
  border: 1px solid #e8e8e8;
  justify-content: space-between;
}

.mask {
  z-index: 10;
  top: -8px;
  right: 15px;
  width: 30px;
  height: 21px;
  text-align: center;
  background-color: #fff;
}

.section4 {
  position: relative;
  text-align: center;
  margin-bottom: 25px;
  background: #fff;

  & > span {
    position: relative;
    display: inline-block;
    margin: 25px auto;
    font-weight: 700;
    font-size: 22px;

    &::after {
      content: "";
      position: absolute;
      bottom: -10px;
      left: 50%;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
      display: inline-block;
      background: -webkit-gradient(
        linear,
        left top,
        right top,
        from(rgba(255, 166, 0, 0.929412)),
        to(#ff6a00)
      );
      background: linear-gradient(90deg, rgba(255, 166, 0, 0.929412), #ff6a00);
      width: 40%;
      border-radius: 50px;
      height: 4px;
    }
  }

  .article_list {
    text-align: left;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    flex-wrap: wrap;

    .article_item {
      border: 1px solid #eee;
      padding: 10px 30px;
      margin: 10px 0;
      width: 44%;
      cursor: pointer;

      &:hover {
        box-shadow: 0 0 5px 1px #dcdfe6;

        .title a {
          color: #ff6a00;
        }
      }

      .title {
        display: -webkit-box;
        display: flex;
        -webkit-box-pack: justify;
        justify-content: space-between;
        margin-bottom: 10px;
      }

      .summary {
        color: #767676;
        font-size: 14px;
      }

      .title a {
        font-size: 18px;
        color: #333;
      }

      .title {
        p {
          color: #999;
        }
      }
    }
  }
}

.table-header {
  width: 92%;
  margin-left: 6%;
  margin-right: 2%;
  height: 50px;
  line-height: 50px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  font-family: SourceHanSansCN-Medium;
  color: #092553;
  font-weight: 900;
  font-size: 19px;
}

.table-header-title {
  border-radius: 2.5px;
  width: 5px;
  height: 16px;
  margin-right: 15px;
  background-color: #28bd9c;
}

.billList {
  padding: 20px;

  .item {
    margin-bottom: 25px;

    .name {
      padding: 15px 0;
      font-weight: bold;
      text-align: center;
      border: 1px solid #e8e8e8;
      font-size: 30px;
    }

    .content {
      font-size: 26px;

      .items {
        display: flex;
        border-left: 1px solid #e8e8e8;

        .cell {
          width: 355px;
          display: flex;

          .title {
            width: 150px;
            padding: 15px 0;
            text-align: center;
            border-right: 1px solid #e8e8e8;
            border-bottom: 1px solid #e8e8e8;
            font-weight: bold;
          }

          .msg {
            width: 205px;
            text-align: center;
            padding: 15px 0;
            border-right: 1px solid #e8e8e8;
            border-bottom: 1px solid #e8e8e8;
          }
        }
      }
    }
  }
}
</style>

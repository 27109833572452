import { render, staticRenderFns } from "./imageGoodListDetails.vue?vue&type=template&id=58d13476&scoped=true"
import script from "./imageGoodListDetails.vue?vue&type=script&lang=js"
export * from "./imageGoodListDetails.vue?vue&type=script&lang=js"
import style0 from "./imageGoodListDetails.vue?vue&type=style&index=0&id=58d13476&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58d13476",
  null
  
)

export default component.exports
<template>
  <div class="shopping com-overflow-hidden" style="padding: 30px 0">
    <div
      class="content home-width com-margin-center"
      style="width: 1200px; margin: 0 auto"
    >
      <div class="hd com-flex" style="display: flex">
        <el-menu
          :default-active="activeIndex"
          class="el-menu-demo"
          mode="horizontal"
          @select="handleSelect"
          style="width: 95%"
          active-text-color="#ff6000"
        >
          <el-menu-item index="1" style="font-size: 18px; font-weight: 700"
            >新增</el-menu-item
          >
          <el-menu-item index="2" style="font-size: 18px; font-weight: 700"
            >历史</el-menu-item
          >
        </el-menu>
      </div>
      <div v-if="indexNumber == 1" style="padding: 20px 40px">
        <div style="">
          <el-form class="form" ref="form" :model="form" label-width="auto">
            <!-- <el-form-item label="官方邮箱" prop="email">
                    <el-input v-model.trim="form.email" autocomplete="off" placeholder="请输入官方邮箱" maxlength="50"></el-input>
                </el-form-item>
                <el-form-item label="咨询微信" prop="wechat">
                    <el-input v-model.trim="form.wechat" autocomplete="off" placeholder="请输入微信,多个通过/区分" maxlength="50"
                        type="textarea"></el-input>
                  
                </el-form-item> -->

            <el-form-item label="图片" prop="imageUrl">
              <div class="demo-image__preview">
                <el-upload
                  :class="{
                    styleA: file_list.length === 0,
                    styleB: file_list.length === 1,
                  }"
                  :action="path + '/api/file'"
                  accept=".jpg,.jpeg,.png,.JPG,.JPEG,.mp4,.webp"
                  list-type="picture-card"
                  :headers="{ ['ACCESS-TOKEN']: accessToken }"
                  :limit="1"
                  ref="upload"
                  :before-upload="beforeUploadOther"
                  :on-change="beforeUpload"
                  :on-preview="handlePreview"
                  :on-exceed="handleExceed"
                  :on-success="handleSuccess"
                  :on-remove="handleRemove"
                  :file-list="file_list"
                >
                  <i slot="default" class="el-icon-plus"></i>
                  <div slot="file" slot-scope="{ file }">
                    <img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
                    <span class="el-upload-list__item-actions">
                      <span
                        class="el-upload-list__item-preview"
                        @click="handlePictureCardPreview(file)"
                      >
                        <i class="el-icon-zoom-in"></i>
                      </span>
                      <span
                        v-if="!disabled"
                        class="el-upload-list__item-delete"
                        @click="handleRemove(file)"
                      >
                        <i class="el-icon-delete"></i>
                      </span>
                    </span>
                  </div>
                </el-upload>
                <el-dialog :visible.sync="dialogVisibles" append-to-body width="30%">
                  <img width="100%" :src="dialogImageUrl" alt="" />
                </el-dialog>
              </div>
            </el-form-item>
            <el-form-item label="共享区域" prop="landLine" style="width: 100%">
              <el-select v-model="form.value1" multiple placeholder="请选择">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="备注" prop="landLine" style="width: 100%">
              <el-input
                v-model.trim="form.landLine"
                type="textarea"
                :autosize="{ minRows: 6, maxRows: 10 }"
                placeholder="请输入内容"
                v-model="textarea2"
                style="width: 100%"
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div style="display: flex; justify-content: center; align-self: center">
          <el-button size="mini" type="primary" plain @click="close()">取 消</el-button>
          <el-button size="mini" v-if="title != '查看'" type="primary" @click="getHtml()"
            >确 定</el-button
          >
        </div>
      </div>
      <div class="content" v-if="indexNumber == 2">
        <el-table :data="list" ref="multipleTable" max-height="800px">
          <el-table-column
            align="center"
            prop="createdBy"
            label="发布人"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="complaintMobileNumber"
            label="联系电话"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="content"
            label="发布内容"
          ></el-table-column>
          <el-table-column align="center" prop="content" label="发布图片">
            <template slot-scope="scope">
              <el-image
                style="width: 100px; height: 100px"
                :src="url"
                :preview-src-list="srcList"
              >
              </el-image>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="createdDate"
            label="发布时间"
          ></el-table-column>
          <el-table-column align="center" prop="errorMessage" label="操作">
            <template slot-scope="scope">
              <el-button type="text" @click="detailShow(scope.row)"
                >查看详情并回复</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="ft"></div>
    </div>
    <detail ref="detail"></detail>
  </div>
</template>

<script>
import shopOrder from "./shopOrder/index.vue";
import ShopItem from "./Shoping/ShopItemOrder.vue";
import detail from "./coCreationCenterDetail/Detail.vue";

export default {
  name: "ShoppingVue",
  data() {
    return {
      file_list: [],
      form: {},
      url: "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
      srcList: [
        "https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg",
        "https://fuss10.elemecdn.com/1/8e/aeffeb4de74e2fde4bd74fc7b4486jpeg.jpeg",
      ],
      options: [
        {
          value: "选项1",
          label: "非洲",
        },
        {
          value: "选项2",
          label: "迪拜",
        },
        {
          value: "选项3",
          label: "中东",
        },
        {
          value: "选项4",
          label: "广州",
        },
        {
          value: "选项5",
          label: "菲力宾",
        },
      ],
      list: [
        {
          id: "465227710597308416",
          content: "我要投诉你们的工作人员",
          results: null,
          resultsMobileNumber: null,
          resultsBy: null,
          resultsDate: null,
          complaintMobileNumber: "13266480503",
          complaintBy: null,
          complaintDate: null,
          satisfaction: null,
          type: null,
          createdId: null,
          createdBy: null,
          createdDate: "2024-01-16",
          updatedId: null,
          updatedBy: null,
          updatedDate: null,
          zfRoomId: null,
          zfLevelId: null,
          zfFloorId: null,
          zfPropertyId: null,
          storeId: null,
        },
        {
          id: "468574061568364544",
          content: "我要投诉的内容是：xxxxxxxxxxxxxxxxxxxxx",
          results: null,
          resultsMobileNumber: null,
          resultsBy: null,
          resultsDate: null,
          complaintMobileNumber: "13266480503",
          complaintBy: null,
          complaintDate: null,
          satisfaction: null,
          type: null,
          createdId: null,
          createdBy: "123",
          createdDate: "2024-01-25",
          updatedId: null,
          updatedBy: null,
          updatedDate: null,
          zfRoomId: null,
          zfLevelId: null,
          zfFloorId: null,
          zfPropertyId: null,
          storeId: null,
        },
        {
          id: "469031458843144192",
          content: "老年人操作電子儀器比較困難，如果未能完成呈序請予指教！",
          results: null,
          resultsMobileNumber: null,
          resultsBy: null,
          resultsDate: null,
          complaintMobileNumber: "13058041609",
          complaintBy: null,
          complaintDate: null,
          satisfaction: null,
          type: null,
          createdId: null,
          createdBy: "谭淑珣",
          createdDate: "2024-01-26",
          updatedId: null,
          updatedBy: null,
          updatedDate: null,
          zfRoomId: null,
          zfLevelId: null,
          zfFloorId: null,
          zfPropertyId: null,
          storeId: null,
        },
        {
          id: "469031637740609536",
          content: "本人是業主，不是租客。",
          results: null,
          resultsMobileNumber: null,
          resultsBy: null,
          resultsDate: null,
          complaintMobileNumber: "13058041609",
          complaintBy: null,
          complaintDate: null,
          satisfaction: null,
          type: null,
          createdId: null,
          createdBy: "谭淑珣",
          createdDate: "2024-01-26",
          updatedId: null,
          updatedBy: null,
          updatedDate: null,
          zfRoomId: null,
          zfLevelId: null,
          zfFloorId: null,
          zfPropertyId: null,
          storeId: null,
        },
        {
          id: "483707898968305664",
          content: "一直未有收到租金收入狀況更新，請提供1月及2月份租金收入支出流情及清單",
          results: null,
          resultsMobileNumber: null,
          resultsBy: null,
          resultsDate: null,
          complaintMobileNumber: "13902597263",
          complaintBy: null,
          complaintDate: null,
          satisfaction: null,
          type: null,
          createdId: null,
          createdBy: "林明春",
          createdDate: "2024-03-07",
          updatedId: null,
          updatedBy: null,
          updatedDate: null,
          zfRoomId: null,
          zfLevelId: null,
          zfFloorId: null,
          zfPropertyId: null,
          storeId: null,
        },
        {
          id: "485177789332258816",
          content: "我的物業是否已出租，沒有收到任何通知，請跟進回覆",
          results: null,
          resultsMobileNumber: null,
          resultsBy: null,
          resultsDate: null,
          complaintMobileNumber: "13902597263",
          complaintBy: null,
          complaintDate: null,
          satisfaction: null,
          type: null,
          createdId: null,
          createdBy: "林明春",
          createdDate: "2024-03-11",
          updatedId: null,
          updatedBy: null,
          updatedDate: null,
          zfRoomId: null,
          zfLevelId: null,
          zfFloorId: null,
          zfPropertyId: null,
          storeId: null,
        },
      ],
      indexNumber: 1,
      isHome: false,
      isActive: false,
      isAllSelected: false,
      allShop: "0.00",
      dialogTableVisible: false,
      shopItems: [
        {
          checked: false,
          type: 1,
          img:
            "https://www.gqedu.org.cn:6868/manage/72411650fc654bd3a7c9747904612799.JPG",
          content: "CHRISBELLA-双手腕女包-BG012211222",
          price: 4100,
          num: 300,
          id: "62371932",
          show: false,
        },
        {
          checked: false,
          type: 2,
          img:
            "https://www.gqedu.org.cn:6868/manage/8e7f8f051fe345f29c54c9ecf9cf5a72.JPG",
          content: "BAGCO-单手腕女包-EK012212003",
          price: 4100,
          num: 300,
          id: "62371932",
          show: false,
        },
      ],
    };
  },
  components: {
    ShopItem,
    shopOrder,
    detail,
  },
  computed: {},
  watch: {
    shopItems: {
      deep: true,
      immediate: true,
      handler(n) {
        let sum = 0;
        for (const item of n) {
          if (item.isSelected) {
            this.isActive = true;
            return;
          }
        }
        this.isActive = false;
      },
    },
  },
  methods: {
    detailShow() {
      this.$refs.detail.show();
    },
    handleSelect(index) {
      console.log(index);
      this.indexNumber = index;
    },
    sumChange(a, b) {
      console.log("3213123");
      console.log(a, b);
      if (a == 1) {
        this.allShop = this.allShop * 1 + b * 1;
      } else {
        this.allShop = this.allShop * 1 - b * 1;
      }
    },
    sumChanges() {
      // if(this.isAllSelected)
      this.allShop = 0;

      this.shopItems.forEach((item) => {
        item.checked = true;
        this.allShop = item.price * 1 * item.num * 1 + this.allShop * 1;
      });
    },
    deleteMultipe() {},
    AllSelectedClick() {
      // if(this.isAllSelected)
      this.allShop = 0;
      if (this.isAllSelected == false) {
        this.isActive = true;
        this.shopItems.forEach((item) => {
          item.checked = true;
          this.allShop = item.price * 1 * item.num * 1 + this.allShop * 1;
        });
      } else {
        this.isActive = false;
        this.shopItems.forEach((item) => {
          item.checked = false;
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@mixin wh($w, $h: $w) {
  width: $w;
  height: $h;
}

.shopping {
  width: 100vw;
  background: repeat-y
    url("https://www.gqedu.org.cn:6868/manage/716bbd2b57c841ccbac91d12a4ea907d.png");
  background-size: cover;

  .content {
    background-color: #fff;
    border-radius: 24px;
    margin-bottom: 45px;
    font-family: "Microsoft YaHei";
    padding-bottom: 10px;
  }
}

.hd,
.cart-table-th {
  padding: 0 18px;
  align-items: center;
  justify-content: space-between;
}

.hd {
  height: 72px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e6e6e6;

  .car {
    color: #000;
    font-size: 18px;
    font-weight: 600;
  }

  .cart-sum {
    strong {
      font-size: 22px;
      font-weight: 700;
      color: #f50;
      margin-right: 12px;
    }

    a {
      $h: 42px;
      display: inline-block;
      text-align: center;
      line-height: $h;
      @include wh(74px, $h);
      background: #aaa;
      color: #fff;
      font-size: 16px;
      border-radius: 21px;
      cursor: not-allowed;
    }

    .active {
      background-color: #f50;
      cursor: pointer;
    }
  }
}

.cartmain {
  user-select: none;
  $h: 50px;

  .cart-table-th {
    padding: 0 44px;
    height: $h;
    margin-bottom: 15px;
    background: #f5f5f5;
  }

  .th {
    height: 100%;
    color: #3c3c3c;
    font-weight: bold;
    font-size: 14px;
    line-height: $h;
  }

  .default-list {
    color: #aaaaaa;
    font-size: 25px;
    font-weight: 700;
    text-align: center;
    line-height: 300px;
  }
}
</style>
<style lang="scss">
.th {
  flex: 2;
}

.th-chk {
  flex: 1.2;

  // width: 150px;
  input,
  label {
    cursor: pointer;
  }

  input {
    vertical-align: middle;
    margin-right: 8px;
  }

  label {
    width: 50px;
    height: 50px;
    display: inline-block;
  }
}

.th-info {
  flex: 2.8;
}

.th-price,
.th-amount,
.th-sum,
.th-op {
  text-align: center;
}
</style>

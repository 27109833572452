<template>
  <div id="goods">
    <div class="banner"></div>
    <div
      class="goods_params wrap"
      style="width: 100%; margin: 0 auto; background: #f0f0f0"
    >
      <div
        class="block"
        style="
          margin-left: 6%;
          margin-right: 6%;
          display: flex;
          justify-content: space-between;
        "
      >
        <div style="flex: 1">
          <ul class="fl">
            <li
              :class="formData.sort == '' ? 'active' : ''"
              style="font-size: 15px; font-weight: 600"
              v-popover:popovers
            >
              <span v-if="lange == '中文'">
                {{ form.cinvcname ? form.cinvcname : "商品大类" }}</span
              >
              <span v-else>
                {{ form.cinvcname ? form.cinvcname : "Product categories" }}</span
              ><i
                class="el-icon-arrow-down"
                style="font-size: 15px; font-weight: 600; margin-left: 5px"
              ></i>
            </li>
            <el-popover
              ref="popovers"
              placement="bottom"
              title=""
              width="100%"
              trigger="click"
            >
              <div
                style="
                  display: flex;
                  align-items: center;
                  width: 87vw;
                  background: #ffffff;
                  padding: 20px 90px;
                "
                v-if="lange == '中文'"
              >
                <el-radio-group v-model="form.cinvcname" size="small">
                  <el-radio
                    :label="item.categorychinesename"
                    border
                    v-for="(item, index) in CommodityClass"
                    :key="index"
                    style="margin-bottom: 20px"
                    >{{ item.categorychinesename }}</el-radio
                  >
                </el-radio-group>
              </div>
              <div
                style="
                  display: flex;
                  align-items: center;
                  width: 87vw;
                  background: #ffffff;
                  padding: 20px 90px;
                "
                v-else
              >
                <el-radio-group v-model="form.cinvcname" size="small">
                  <el-radio
                    :label="item.categoryname"
                    border
                    v-for="(item, index) in CommodityClass"
                    :key="index"
                    style="margin-bottom: 20px"
                    >{{ item.categoryname }}</el-radio
                  >
                </el-radio-group>
              </div>
            </el-popover>
            <li
              :class="formData.sort == '' ? 'active' : ''"
              style="font-size: 15px; font-weight: 600"
              v-popover:popover
            >
              <span v-if="lange == '中文'">
                {{ form.functionType ? form.functionType : "功能类型" }}</span
              >
              <span v-else>
                {{ form.functionType ? form.functionType : "Functions" }}</span
              ><i
                class="el-icon-arrow-down"
                style="font-size: 15px; font-weight: 600; margin-left: 5px"
              ></i>
            </li>
            <el-popover
              ref="popover"
              placement="bottom"
              title=""
              width="100%"
              trigger="click"
            >
              <div
                style="
                  display: flex;
                  align-items: center;
                  width: 87vw;
                  background: #ffffff;
                  padding: 20px 90px;
                "
                v-if="lange == '中文'"
              >
                <el-radio-group v-model="form.functionType" size="small">
                  <el-radio
                    :label="item.calias"
                    border
                    v-for="(item, index) in useType"
                    :key="index"
                    style="margin-bottom: 20px"
                    >{{ item.calias }}</el-radio
                  >
                </el-radio-group>
              </div>
              <div
                style="
                  display: flex;
                  align-items: center;
                  width: 87vw;
                  background: #ffffff;
                  padding: 20px 90px;
                "
                v-else
              >
                <el-radio-group v-model="form.functionType" size="small">
                  <el-radio
                    :label="item.cvalue"
                    border
                    v-for="(item, index) in useType"
                    :key="index"
                    style="margin-bottom: 20px"
                    >{{ item.cvalue }}</el-radio
                  >
                </el-radio-group>
              </div>
            </el-popover>

            <li
              :class="formData.sort == '' ? 'active' : ''"
              style="font-size: 15px; font-weight: 600"
              v-popover:popoverjiage
            >
              <span v-if="lange == '中文'">
                {{ form.priceOrderCopy ? form.priceOrderCopy : "价格排序" }}</span
              >
              <span v-else>
                {{ form.priceOrderCopy ? form.priceOrderCopy : "Sort By Price" }}</span
              ><i
                class="el-icon-arrow-down"
                style="font-size: 15px; font-weight: 600; margin-left: 5px"
              ></i>
            </li>
            <el-popover
              ref="popoverjiage"
              placement="bottom"
              title=""
              width="100%"
              trigger="click"
            >
              <div
                style="
                  display: flex;
                  align-items: center;
                  width: 87vw;
                  background: #ffffff;
                  padding: 20px 90px;
                "
                v-if="lange == '中文'"
              >
                <el-radio-group v-model="form.priceOrderCopy" size="small">
                  <el-radio label="价格升序" border style="margin-bottom: 20px"
                    >价格升序</el-radio
                  >
                  <el-radio label="价格降序" border style="margin-bottom: 20px"
                    >价格降序</el-radio
                  >
                </el-radio-group>
              </div>
              <div
                style="
                  display: flex;
                  align-items: center;
                  width: 87vw;
                  background: #ffffff;
                  padding: 20px 90px;
                "
                v-else
              >
                <el-radio-group v-model="form.priceOrderCopy" size="small">
                  <el-radio label="价格升序" border style="margin-bottom: 20px"
                    >Price Low To High</el-radio
                  >
                  <el-radio label="价格降序" border style="margin-bottom: 20px"
                    >Price High To Low</el-radio
                  >
                </el-radio-group>
              </div>
            </el-popover>
            <li
              :class="formData.sort == '' ? 'active' : ''"
              style="font-size: 15px; font-weight: 600"
              v-popover:popoverp
            >
              <span v-if="lange == '中文'">
                {{ form.sizeType ? form.sizeType : "包型尺寸" }}</span
              >
              <span v-else> {{ form.sizeType ? form.sizeType : "Package size" }}</span>
              <i
                class="el-icon-arrow-down"
                style="font-size: 15px; font-weight: 600; margin-left: 5px"
              ></i>
            </li>
            <el-popover
              ref="popoverp"
              placement="bottom"
              title=""
              width="100%"
              trigger="click"
            >
              <div
                style="
                  display: flex;
                  align-items: center;
                  width: 87vw;
                  background: #ffffff;
                  padding: 20px 90px;
                "
                v-if="lange == '中文'"
              >
                <el-radio-group v-model="form.sizeType" size="small">
                  <el-radio
                    :label="item.cvalue"
                    border
                    v-for="(item, index) in CommoditySize"
                    :key="index"
                    style="margin-bottom: 20px"
                    >{{ item.cvalue }}</el-radio
                  >
                </el-radio-group>
              </div>
              <div
                style="
                  display: flex;
                  align-items: center;
                  width: 87vw;
                  background: #ffffff;
                  padding: 20px 90px;
                "
                v-else
              >
                <el-radio-group v-model="form.sizeType" size="small">
                  <el-radio
                    :label="item.calias"
                    border
                    v-for="(item, index) in CommoditySize"
                    :key="index"
                    style="margin-bottom: 20px"
                    >{{ item.calias }}</el-radio
                  >
                </el-radio-group>
              </div>
            </el-popover>
          </ul>
        </div>
        <div style="display: flex; width: 27%">
          <div style="display: flex; margin-right: 5%">
            <el-input
              v-if="lange == '中文'"
              placeholder="请输入自定义商品名称或商品编码"
              v-model.trim="form.cInvName"
            ></el-input>
            <el-input v-else placeholder="" v-model.trim="form.cInvName"></el-input>
          </div>
          <div style="display: flex; align-items: center" @click="queryGoodOne">
            <i
              class="el-icon-search"
              style="font-size: 20px; font-weight: 600; margin-right: 10px"
            ></i>
            <div
              style="font-size: 18px; font-weight: 600; width: 60px"
              v-if="lange == '中文'"
            >
              搜索
            </div>
            <div style="font-size: 18px; font-weight: 600; width: 60px" v-else>
              Search
            </div>
          </div>
          <div
            style="display: flex; align-items: center; margin-left: 20px"
            @click="clearSelest"
          >
            <div
              style="font-size: 18px; font-weight: 600; width: 120px"
              v-if="lange == '中文'"
            >
              清空查询
            </div>
            <div style="font-size: 18px; font-weight: 600; width: 120px" v-else>
              Clear Search
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="main wrap" style="display: flex; justify-content: center; width: 100%">
      <div style="display: flexl; width: 100%">
        <div style="width: 100%">
          <div
            class="recommend clearfix"
            style="display: flex; justify-content: center; width: 100%; flex-wrap: wrap"
          >
            <div
              class="item fl"
              v-for="(item, index) in hotgoodsList"
              :key="index"
              style="margin-bottom: 60px"
            >
              <dl>
                <dt>
                  <div style="display: flex; position: relative" @click="buyGoods(item)">
                    <imageSwiper :imgUrl="item.imageUrlList" style="width: 100%">
                    </imageSwiper>
                  </div>
                </dt>
                <div class="title">
                  {{ item.brandName + "-" + item.cinvCode }}
                </div>
                <div class="other clearfix" style="line-height: 36px; font-weight: 600">
                  <span class="price fl" v-if="organizationNames == '迪拜'"
                    >{{ parseFloat(item.price).toFixed(2) }}DHS/PCS</span
                  >
                  <span class="price fl" v-else
                    >{{ parseFloat(item.price).toFixed(2) }}RMB/PCS</span
                  >
                </div>
                <div style="display: flex; margin-top: 8px; padding-bottom: 20px">
                  <div @click="deleteMultipe(item)">
                    <span class="btn" v-if="lange == '中文'">加入购物车</span>
                    <span class="btn" v-else>Add To Cart</span>
                  </div>
                  <div @click="buyGoods(item)" style="margin-left: 24px">
                    <span class="btns" style="margin-right: 15px" v-if="lange == '中文'"
                      >立即购买</span
                    >
                    <span class="btns" style="margin-right: 15px" v-else>Order Now</span>
                  </div>
                </div>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      style="display: flex; width: 100%; aligin-items: center; justify-content: center"
    >
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage4"
        :page-sizes="[16, 32, 48, 96]"
        :page-size="16"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <addCartDialog
      ref="addCartDialog"
      v-if="addCartDialogShow"
      @back="addCartDialogShowClick"
    />
  </div>
</template>

<script>
import {
  hostGood,
  getRecommendCommodityPage,
  getDcBasearchiveCommodity,
  queryCommodityUsageType,
  queryCommodityCategory,
  queryCommoditySize,
  queryCommodityClass,
} from "@/api/good/index.js";
import addCartDialog from "./addCartDialog/index.vue";
import Zoom from "./Detail/Zoom.vue";
import ImgList from "./Detail/ImgList.vue";
import InfoDetail from "./Detail/infoDetailOther.vue";
import imageSwiper from "@/components/imageSwiper/index.vue";
import shopOrder from "./shopOrder/indexOther.vue";
import { mapGetters } from "vuex";
export default {
  name: "GoodsHome",
  components: {
    shopOrder,
    Zoom,
    ImgList,
    InfoDetail,
    imageSwiper,
    addCartDialog,
  },

  data() {
    return {
      addCartDialogShow: true,
      radio3: "",
      radio4: "",
      radio5: "",
      radio6: "",
      currentPage4: 1,
      drawer: false,
      direction: "rtl",
      checkList: [],
      formData: {},
      sonShow: false,
      isLogin: false,
      articleList: [],
      articleList2: [],
      currentItem: {
        id: 1,
        ishot: false,
        img: "https://www.gqedu.org.cn:6868/manage/72411650fc654bd3a7c9747904612799.JPG",
        content: "CHRISBELLA-双手腕女包-BG012211222",
        price: "899",
        dialogVisible: false,
        detail: [
          "https://www.gqedu.org.cn:6868/manage/72411650fc654bd3a7c9747904612799.JPG",
          "https://www.gqedu.org.cn:6868/manage/92914b8fed0149ad803d08c6cc9cee5a.JPG",
          "https://www.gqedu.org.cn:6868/manage/d1c3d0d15bf0485e8bd00a13a8706c6b.JPG",
          "https://www.gqedu.org.cn:6868/manage/72488a01333b4f378d88f51060384aa0.JPG",
          "https://www.gqedu.org.cn:6868/manage/1f17d3fa9fd04cf8a40ec1810805ec02.JPG",
          "https://www.gqedu.org.cn:6868/manage/c57a78ad32224bd386e187446d08d3f3.JPG",
          "https://www.gqedu.org.cn:6868/manage/b03075c6b4054d3a82420bce2de9e448.JPG",
          "https://www.gqedu.org.cn:6868/manage/27b2eb231fd44d039e105271fbb307f7.JPG",
          "https://www.gqedu.org.cn:6868/manage/116ea389b7be494bbc7cb56f68a3672a.JPG",
        ],
      },

      hotgoodsList: [],
      hotgoodsListnew: [],
      hotgoodsListCopy: [
        {
          cover: "http://192.168.1.12:8099/file/abyFile/backGround/10.jpg",
          name: "综合店(500m³)",
          cost: 1000,
        },
        {
          cover: "http://192.168.1.12:8099/file/abyFile/backGround/11.jpg",
          name: "形象店(80m³)",
          cost: 1000,
        },
      ],
      hotgoodsListCopys: [
        {
          cover: "http://192.168.1.12:8099/file/abyFile/backGround/12.jpg",
          name: "圣诞节",
          cost: 1000,
        },
        {
          cover: "http://192.168.1.12:8099/file/abyFile/backGround/13.jpg",
          name: "Big Sale Up to 50%",
          cost: 1000,
        },
      ],
      newestGoodsList: [
        {
          cover:
            "https://www.gqedu.org.cn:6868/manage/1b939a9bd48e40dfa3b4442f0ff25e16.JPG",
          name: "综合店(500m³)",
          cost: 1000,
        },
        {
          cover:
            "https://www.gqedu.org.cn:6868/manage/8e7f8f051fe345f29c54c9ecf9cf5a72.JPG",
          name: "BAGCO-女包-双手腕",
          cost: 1000,
        },
        {
          cover:
            "https://www.gqedu.org.cn:6868/manage/1b939a9bd48e40dfa3b4442f0ff25e16.JPG",
          name: "CHRISBELLA-女包-双手腕",
          cost: 1000,
        },
        {
          cover:
            "https://www.gqedu.org.cn:6868/manage/1b939a9bd48e40dfa3b4442f0ff25e16.JPG",
          name: "CHRISBELLA-女包-双手腕",
          cost: 1000,
        },
        {
          cover:
            "https://www.gqedu.org.cn:6868/manage/1b939a9bd48e40dfa3b4442f0ff25e16.JPG",
          name: "CHRISBELLA-女包-双手腕",
          cost: 1000,
        },
        {
          cover:
            "https://www.gqedu.org.cn:6868/manage/1b939a9bd48e40dfa3b4442f0ff25e16.JPG",
          name: "CHRISBELLA-女包-双手腕",
          cost: 1000,
        },
        {
          cover:
            "https://www.gqedu.org.cn:6868/manage/1b939a9bd48e40dfa3b4442f0ff25e16.JPG",
          name: "CHRISBELLA-女包-双手腕",
          cost: 1000,
        },
        {
          cover:
            "https://www.gqedu.org.cn:6868/manage/1b939a9bd48e40dfa3b4442f0ff25e16.JPG",
          name: "CHRISBELLA-女包-双手腕",
          cost: 1000,
        },
        {
          cover:
            "https://www.gqedu.org.cn:6868/manage/1b939a9bd48e40dfa3b4442f0ff25e16.JPG",
          name: "CHRISBELLA-女包-双手腕",
          cost: 1000,
        },
        {
          cover:
            "https://www.gqedu.org.cn:6868/manage/1b939a9bd48e40dfa3b4442f0ff25e16.JPG",
          name: "CHRISBELLA-女包-双手腕",
          cost: 1000,
        },
        {
          cover:
            "https://www.gqedu.org.cn:6868/manage/1b939a9bd48e40dfa3b4442f0ff25e16.JPG",
          name: "CHRISBELLA-女包-双手腕",
          cost: 1000,
        },
        {
          cover:
            "https://www.gqedu.org.cn:6868/manage/8e7f8f051fe345f29c54c9ecf9cf5a72.JPG",
          name: "BAGCO-女包-双手腕",
          cost: 1000,
        },
        {
          cover:
            "https://www.gqedu.org.cn:6868/manage/1b939a9bd48e40dfa3b4442f0ff25e16.JPG",
          name: "CHRISBELLA-女包-双手腕",
          cost: 1000,
        },
        {
          cover:
            "https://www.gqedu.org.cn:6868/manage/1b939a9bd48e40dfa3b4442f0ff25e16.JPG",
          name: "CHRISBELLA-女包-双手腕",
          cost: 1000,
        },
        {
          cover:
            "https://www.gqedu.org.cn:6868/manage/1b939a9bd48e40dfa3b4442f0ff25e16.JPG",
          name: "CHRISBELLA-女包-双手腕",
          cost: 1000,
        },
        {
          cover:
            "https://www.gqedu.org.cn:6868/manage/1b939a9bd48e40dfa3b4442f0ff25e16.JPG",
          name: "CHRISBELLA-女包-双手腕",
          cost: 1000,
        },
        {
          cover:
            "https://www.gqedu.org.cn:6868/manage/1b939a9bd48e40dfa3b4442f0ff25e16.JPG",
          name: "CHRISBELLA-女包-双手腕",
          cost: 1000,
        },
        {
          cover:
            "https://www.gqedu.org.cn:6868/manage/1b939a9bd48e40dfa3b4442f0ff25e16.JPG",
          name: "CHRISBELLA-女包-双手腕",
          cost: 1000,
        },
        {
          cover:
            "https://www.gqedu.org.cn:6868/manage/1b939a9bd48e40dfa3b4442f0ff25e16.JPG",
          name: "CHRISBELLA-女包-双手腕",
          cost: 1000,
        },
        {
          cover:
            "https://www.gqedu.org.cn:6868/manage/1b939a9bd48e40dfa3b4442f0ff25e16.JPG",
          name: "CHRISBELLA-女包-双手腕",
          cost: 1000,
        },
        {
          cover:
            "https://www.gqedu.org.cn:6868/manage/8e7f8f051fe345f29c54c9ecf9cf5a72.JPG",
          name: "BAGCO-女包-双手腕",
          cost: 1000,
        },
        {
          cover:
            "https://www.gqedu.org.cn:6868/manage/1b939a9bd48e40dfa3b4442f0ff25e16.JPG",
          name: "CHRISBELLA-女包-双手腕",
          cost: 1000,
        },
        {
          cover:
            "https://www.gqedu.org.cn:6868/manage/1b939a9bd48e40dfa3b4442f0ff25e16.JPG",
          name: "CHRISBELLA-女包-双手腕",
          cost: 1000,
        },
        {
          cover:
            "https://www.gqedu.org.cn:6868/manage/1b939a9bd48e40dfa3b4442f0ff25e16.JPG",
          name: "CHRISBELLA-女包-双手腕",
          cost: 1000,
        },
        {
          cover:
            "https://www.gqedu.org.cn:6868/manage/1b939a9bd48e40dfa3b4442f0ff25e16.JPG",
          name: "CHRISBELLA-女包-双手腕",
          cost: 1000,
        },
        {
          cover:
            "https://www.gqedu.org.cn:6868/manage/1b939a9bd48e40dfa3b4442f0ff25e16.JPG",
          name: "CHRISBELLA-女包-双手腕",
          cost: 1000,
        },
        {
          cover:
            "https://www.gqedu.org.cn:6868/manage/1b939a9bd48e40dfa3b4442f0ff25e16.JPG",
          name: "CHRISBELLA-女包-双手腕",
          cost: 1000,
        },
        {
          cover:
            "https://www.gqedu.org.cn:6868/manage/1b939a9bd48e40dfa3b4442f0ff25e16.JPG",
          name: "CHRISBELLA-女包-双手腕",
          cost: 1000,
        },
        {
          cover:
            "https://www.gqedu.org.cn:6868/manage/1b939a9bd48e40dfa3b4442f0ff25e16.JPG",
          name: "CHRISBELLA-女包-双手腕",
          cost: 1000,
        },
        {
          cover:
            "https://www.gqedu.org.cn:6868/manage/1b939a9bd48e40dfa3b4442f0ff25e16.JPG",
          name: "CHRISBELLA-女包-双手腕",
          cost: 1000,
        },
        {
          cover:
            "https://www.gqedu.org.cn:6868/manage/8e7f8f051fe345f29c54c9ecf9cf5a72.JPG",
          name: "BAGCO-女包-双手腕",
          cost: 1000,
        },
        {
          cover:
            "https://www.gqedu.org.cn:6868/manage/1b939a9bd48e40dfa3b4442f0ff25e16.JPG",
          name: "CHRISBELLA-女包-双手腕",
          cost: 1000,
        },
        {
          cover:
            "https://www.gqedu.org.cn:6868/manage/1b939a9bd48e40dfa3b4442f0ff25e16.JPG",
          name: "CHRISBELLA-女包-双手腕",
          cost: 1000,
        },
        {
          cover:
            "https://www.gqedu.org.cn:6868/manage/1b939a9bd48e40dfa3b4442f0ff25e16.JPG",
          name: "CHRISBELLA-女包-双手腕",
          cost: 1000,
        },
        {
          cover:
            "https://www.gqedu.org.cn:6868/manage/1b939a9bd48e40dfa3b4442f0ff25e16.JPG",
          name: "CHRISBELLA-女包-双手腕",
          cost: 1000,
        },
        {
          cover:
            "https://www.gqedu.org.cn:6868/manage/1b939a9bd48e40dfa3b4442f0ff25e16.JPG",
          name: "CHRISBELLA-女包-双手腕",
          cost: 1000,
        },
        {
          cover:
            "https://www.gqedu.org.cn:6868/manage/1b939a9bd48e40dfa3b4442f0ff25e16.JPG",
          name: "CHRISBELLA-女包-双手腕",
          cost: 1000,
        },
        {
          cover:
            "https://www.gqedu.org.cn:6868/manage/1b939a9bd48e40dfa3b4442f0ff25e16.JPG",
          name: "CHRISBELLA-女包-双手腕",
          cost: 1000,
        },
        {
          cover:
            "https://www.gqedu.org.cn:6868/manage/1b939a9bd48e40dfa3b4442f0ff25e16.JPG",
          name: "CHRISBELLA-女包-双手腕",
          cost: 1000,
        },
      ],
      newestGoodsListg: [
        {
          cover:
            "https://www.gqedu.org.cn:6868/manage/419926d1c8d34c779340a14d8d6f934d.png",
          title: "综合店(500m³)",
          name: "CHRISBELLA-女包-双手腕",
          cost: 1000,
        },
        {
          cover:
            "https://www.gqedu.org.cn:6868/manage/023db60b374740ac92b92fcf126a3124.png",
          title: "形象店(500m³)",
          name: "BAGCO-女包-双手腕",
          cost: 1000,
        },
        {
          cover:
            "https://www.gqedu.org.cn:6868/manage/6bb5caac00dd4fee847130c907b99409.png",
          title: "综合店(80m³)",
          name: "CHRISBELLA-女包-双手腕",
          cost: 1000,
        },
        {
          cover:
            "https://www.gqedu.org.cn:6868/manage/0b257728ad194a05912769cfacfbba30.png",
          title: "轻奢店(500m³)",
          name: "CHRISBELLA-女包-双手腕",
          cost: 1000,
        },
        {
          cover:
            "https://www.gqedu.org.cn:6868/manage/8419693610694aae99fb2c6fac39b0cd.png",
          title: "专门店(100m³)",
          name: "CHRISBELLA-女包-双手腕",
          cost: 1000,
        },
        {
          cover:
            "https://www.gqedu.org.cn:6868/manage/71964b0833e54e07815bd6620744c437.png",
          title: "综合店(50m³)",
          name: "CHRISBELLA-女包-双手腕",
          cost: 1000,
        },
        {
          cover:
            "https://www.gqedu.org.cn:6868/manage/3ab0b469880b4f3c8a252eb42b474b51.png",
          title: "批发店(300m³)",
          name: "CHRISBELLA-女包-双手腕",
          cost: 1000,
        },
      ],
      imageHight: 0,
      advertList: [
        {
          image:
            "https://www.gqedu.org.cn:6868/manage/9499266a523640778394bd3d9e70103b.JPG",
        },
        {
          image:
            "https://www.gqedu.org.cn:6868/manage/8e7f8f051fe345f29c54c9ecf9cf5a72.JPG",
        },
        {
          image:
            "https://www.gqedu.org.cn:6868/manage/cc13dac7e14246f39bb6868029068531.JPG",
        },
        {
          image:
            "https://www.gqedu.org.cn:6868/manage/aa64484d49a04ebfaf662a9bc2242182.JPG",
        },
      ],
      form: {},
      brandName: this.$route.query.brandName,
      organizationNames: localStorage.getItem("organizationNames"),
      hotNull: 0,
      newNull: 0,
      swiperOptions: {
        loop: true,
        autoplay: true,
        initialSlide: 1,
      },
      total: 0,

      itemContent: {},
      dialogTableVisible: false,
      page: {
        pageSize: 16,
        pageNum: 1,
        brandName: this.$route.query.brandName,
        organizationId: this.organizationName == "广州" ? 1 : 2,
      },
      useType: [],
      Category: [],
      CommoditySize: [],
      CommodityClass: [],
      discount: 1,
      addPrice: 0,
    };
  },
  computed: {
    ...mapGetters({
      lange: "user/lange",
      organizationName: "user/organizationName",
      priceChangeList: "user/priceChangeList",
    }),
  },
  created() {
    // hostGood({ pageSize: 10, pageNum: 1 }).then((res) => {
    //   this.hotgoodsList = res.data.list;
    //   this.hotgoodsList.forEach((item) => {
    //     item.imageUrl = "http://192.168.1.12:8099/file/" + item.imageUrl;
    //     console.log(item.imageUrl);
    //   });
    //   console.log(this.hotgoodsList);
    // });
    if (this.priceChangeList.length != 0) {
      if (this.brandName == "CHRISBELLA") {
        let discountLength = this.priceChangeList.filter((item) => {
          return item.brandName == "CHRISBELLA";
        });
        if (discountLength.length != 0) {
          this.discount = discountLength[0].discount / 100;
        }
        let addPriceLength = this.priceChangeList.filter((item) => {
          return item.brandName == "CHRISBELLA";
        });
        if (addPriceLength.length != 0) {
          this.addPrice = addPriceLength[0].priceadjustment;
        }
      } else if (this.brandName == "BAGCO") {
        let discountLength = this.priceChangeList.filter((item) => {
          return item.brandName == "BAGCO";
        });
        if (discountLength.length != 0) {
          this.discount = discountLength[0].discount / 100;
        }
        let addPriceLength = this.priceChangeList.filter((item) => {
          return item.brandName == "BAGCO";
        });
        if (addPriceLength.length != 0) {
          this.addPrice = addPriceLength[0].priceadjustment;
        }
      } else {
        let discountLength = this.priceChangeList.filter((item) => {
          return item.brandName == "SUSEN";
        });
        if (discountLength.length != 0) {
          this.discount = discountLength[0].discount / 100;
        }
        let addPriceLength = this.priceChangeList.filter((item) => {
          return item.brandName == "SUSEN";
        });
        if (addPriceLength.length != 0) {
          this.addPrice = addPriceLength[0].priceadjustment;
        }
      }
    }
    getDcBasearchiveCommodity(this.page).then((res) => {
      this.hotgoodsList = res.data.list;

      this.total = res.data.total;

      this.hotgoodsList.forEach((item) => {
        item.imageUrl = this.$locationUrl + "file/" + item.imageUrl;
        item.imageUrl1 = this.$locationUrl + "file/" + item.imageUrl1;
        item.imageUrlList = [item.imageUrl, item.imageUrl1];
        console.log(item.imageUrlList);
        console.log(item.imageUrl);
        console.log(item.imageUrl1);
        item.price = (item.price * 1 + this.addPrice * 1) * this.discount;
      });
      queryCommodityUsageType({}).then((res) => {
        this.useType = res.data;
      });
      queryCommodityCategory({}).then((res) => {
        this.Category = res.data;
      });
      queryCommoditySize({}).then((res) => {
        this.CommoditySize = res.data;
      });
      queryCommodityClass({}).then((res) => {
        this.CommodityClass = res.data;
      });
    });
  },
  // mounted() {this.setImageHeight()},
  watch: {},
  // mounted() {
  //   window.addEventListener("popstate", function (event) {
  //     // 在这里可以调用历史记录页面的方法
  //     // 例如，假设页面实例保存在 app 变量中
  //     hostGood({ pageSize: 10, pageNum: 1 }).then((res) => {
  //       this.hotgoodsList = res.data.list;
  //       this.hotgoodsList.forEach((item) => {
  //         item.imageUrl = "http://192.168.1.12:8099/file/" + item.imageUrl;
  //         console.log(item.imageUrl);
  //       });
  //     });
  //     console.log(this.hotgoodsList);
  //     getRecommendCommodityPage({ pageSize: 10, pageNum: 1 }).then((res) => {
  //       console.log(res);
  //     });
  //     this.$nextTick(() => {
  //       const elements = this.$refs.images;
  //       if (elements) {
  //         let height = 0;
  //         elements.forEach((element) => {
  //           height = element.clientWidth;
  //           this.imageHight = height + "px";
  //         });

  //         console.log("22222", this.imageHight);
  //       }
  //     });
  //   });
  // },
  methods: {
    addCartDialogShowClick() {
      this.addCartDialogShow = false;
      setTimeout(() => {
        this.addCartDialogShow = true;
      }, 500);
    },
    handleMouseOver(index, item) {
      console.log(this.$refs.changeImage[index]);
      this.$refs.changeImage[index].style.transform = "translateX(0)";
      this.$refs.changeImage[index].style.visibility = "visible";
      item.hasEntered = true; // 设置已经执行过了的标记
      console.log(item.hasEntered);

      // this.$refs.myDiv.style.color = 'white';
      // this.$refs.myDiv.style.padding = '10px';
    },
    handleMouseLeave(index, item) {
      this.$refs.changeImage[index].style.transform = "translateX(-50%)";
      this.$refs.changeImage[index].style.visibility = "hidden";

      item.hasEntered = false;
      console.log(item.hasEntered);
    },
    handleSizeChange(val) {
      getDcBasearchiveCommodity({
        pageNum: this.page.pageNum,
        pageSize: val,
        cInvCode: this.form.customerPersonName1,
        brandName: this.brandName,
        organizationId: this.organizationName == "广州" ? 1 : 2,
        cInvName: this.form.cInvName,
        functionType: this.form.functionType,
        categoryType: this.form.categoryType,
        sizeType: this.form.sizeType,
        priceOrder: this.form.priceOrder,
      }).then((res) => {
        this.hotgoodsList = res.data.list;
        // this.total = res.data.total %page.pageSize
        this.total = res.data.total;

        this.hotgoodsList.forEach((item) => {
          item.imageUrl = this.$locationUrl + "file/" + item.imageUrl;
          item.imageUrl1 = this.$locationUrl + "file/" + item.imageUrl1;
          item.imageUrlList = [item.imageUrl, item.imageUrl1];
          item.price = (item.price * 1 + this.addPrice * 1) * this.discount;
          console.log(item.imageUrl);
          console.log(item.imageUrl1);
        });
      });
    },
    clearSelest() {
      this.page.pageNum = 1;
      this.form = {};
      getDcBasearchiveCommodity(this.page).then((res) => {
        this.hotgoodsList = res.data.list;
        // this.total = res.data.total %page.pageSize
        this.total = res.data.total;

        this.hotgoodsList.forEach((item) => {
          item.imageUrl = this.$locationUrl + "file/" + item.imageUrl;
          item.imageUrl1 = this.$locationUrl + "file/" + item.imageUrl1;
          item.imageUrlList = [item.imageUrl, item.imageUrl1];
          item.price = (item.price * 1 + this.addPrice * 1) * this.discount;
          console.log(item.imageUrl);
          console.log(item.imageUrl1);
        });
      });
    },
    handleCurrentChange(val) {
      this.page.pageNum = val;
      getDcBasearchiveCommodity({
        pageNum: val,
        pageSize: this.page.pageSize,
        cInvCCode: this.form.cInvCCode,
        brandName: this.brandName,
        organizationId: this.organizationName == "广州" ? 1 : 2,
        cInvName: this.form.cInvName,
        functionType: this.form.functionType,
        categoryType: this.form.categoryType,
        sizeType: this.form.sizeType,
        priceOrder: this.form.priceOrder,
      }).then((res) => {
        this.hotgoodsList = res.data.list;
        // this.total = res.data.total %page.pageSize
        this.total = res.data.total;

        this.hotgoodsList.forEach((item) => {
          item.imageUrl = this.$locationUrl + "file/" + item.imageUrl;
          item.imageUrl1 = this.$locationUrl + "file/" + item.imageUrl1;
          item.imageUrlList = [item.imageUrl, item.imageUrl1];
          item.price = (item.price * 1 + this.addPrice * 1) * this.discount;
          console.log(item.imageUrl);
          console.log(item.imageUrl1);
        });
      });
    },
    queryGoodOne(item) {
      if (this.form.priceOrderCopy == "价格降序") {
        this.form.priceOrder = 0;
      } else {
        this.form.priceOrder = 1;
      }
      let cInvCCodelist = [];
      if (this.lange == "中文") {
        cInvCCodelist = this.CommodityClass.filter((item) => {
          return item.categorychinesename == this.form.cinvcname;
        });
      } else {
        cInvCCodelist = this.CommodityClass.filter((item) => {
          return item.categoryname == this.form.cinvcname;
        });
      }
      console.log(cInvCCodelist);
      if (cInvCCodelist.length != 0) {
        this.form.categoryid = cInvCCodelist[0].categoryid;
      }

      getDcBasearchiveCommodity({
        pageNum: 1,
        pageSize: 12,
        brandName: this.brandName,
        organizationId: this.organizationName == "广州" ? 1 : 2,
        categoryid: this.form.categoryid,
        cInvName: this.form.cInvName,
        functionType: this.form.functionType,
        categoryType: this.form.categoryType,
        sizeType: this.form.sizeType,
        priceOrder: this.form.priceOrder,
      }).then((res) => {
        this.hotgoodsList = res.data.list;
        // this.total = res.data.total %page.pageSize
        this.total = res.data.total;

        this.hotgoodsList.forEach((item) => {
          item.imageUrl = this.$locationUrl + "file/" + item.imageUrl;
          item.imageUrl1 = this.$locationUrl + "file/" + item.imageUrl1;
          item.imageUrlList = [item.imageUrl, item.imageUrl1];
          item.price = (item.price * 1 + this.addPrice * 1) * this.discount;
          console.log(item.imageUrl);
          console.log(item.imageUrl1);
        });
        this.drawer = false;
      });
    },
    drawerClick() {
      console.log(1);
      this.drawer = true;
    },
    // handleScroll() {
    //   // 获取滚动条的位置
    //   const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    //   // 获取页面可见区域的高度
    //   const clientHeight = document.documentElement.clientHeight;
    //   // 获取页面内容的高度
    //   const scrollHeight = document.documentElement.scrollHeight;

    //   // 如果滚动到页面底部附近（这里设置为距离底部100px），可以执行你的操作
    //   if (scrollHeight - scrollTop - clientHeight < 100) {
    //     // 执行你的操作，例如加载更多内容等
    //    console.log(1)
    //   }
    // },
    setImageHeight(index) {
      const imageElement = this.$refs.image[index];
      const imageWrapperElement = this.$refs.imageWrapper[index];

      const width = imageWrapperElement.offsetWidth;
      console.log(width);
      imageElement.style.height = `${width}px`;
    },
    changeImage(v) {
      console.log(v);
      let that = this;
      this.$nextTick(() => {
        that.$set(that.currentItem, "img", v);
      });
    },

    changselect(index, indexPath) {
      if (index == 1) {
        const targetDiv = document.querySelector(".new_goods_title_hot");
        targetDiv.scrollIntoView({ behavior: "smooth" });
      } else if (index == 2) {
        const targetDiv = document.querySelector(".new_goods_title_new");
        targetDiv.scrollIntoView({ behavior: "smooth" });
      } else if (index == 3) {
        const targetDiv = document.querySelector(".new_goods_title_new1");
        targetDiv.scrollIntoView({ behavior: "smooth" });
      } else if (index == 4) {
        const targetDiv = document.querySelector(".new_goods_title_new2");
        targetDiv.scrollIntoView({ behavior: "smooth" });
      } else if (index == 6) {
        const targetDiv = document.querySelector(".new_goods_title_Image_store");
        targetDiv.scrollIntoView({ behavior: "smooth" });
      } else {
        const targetDiv = document.querySelector(".section4");
        targetDiv.scrollIntoView({ behavior: "smooth" });
      }
    },
    IsPC() {
      var userAgentInfo = navigator.userAgent;
      var Agents = new Array(
        "Android",
        "iPhone",
        "SymbianOS",
        "Windows Phone",
        "iPad",
        "iPod"
      );
      var flag = true;
      for (var v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
          flag = false;
          break;
        }
      }
    },
    backgroundImg(value) {
      var host = document.location.host;
      var result = "";
      if (value && value.indexOf("files") != -1) {
        if (host.search("192") != -1) {
          result = "http://192.168.0.50:8080" + value;
        } else {
          result = document.location.protocol + "//" + host + "/" + value;
        }
      } else {
        result = value;
      }
      return result;
    },
    //公告列表
    getArticle() {
      this.$request.post({
        url: "/ReportApi/business/newlists",
        params: {
          pageNo: 1,
          pageSize: 3,
          type: 1,
        },
        success: (res) => {
          this.articleList = res[0];
        },
      });
    },
    //新闻列表
    getArticle2() {
      this.$request.post({
        url: "/ReportApi/business/newlists",
        params: {
          pageNo: 1,
          pageSize: 6,
          type: 2,
        },
        success: (res) => {
          this.articleList2 = res[0];
        },
      });
    },
    //热销商品列表
    getHotGoodsList() {
      this.$request.post({
        url: "/ReportApi/business/goodlist",
        params: {
          pageNo: 1,
          pageSize: 11,
          sort: 3,
          tenantid: localStorage.getItem("tenantInfoid"),
        },
        success: (res) => {
          this.hotgoodsList = res[0];
          if (this.hotgoodsList.length < 11) {
            this.hotNull = 11 - this.hotgoodsList.length;
          }
          this.getNewestGoodsList();
        },
      });
    },
    //最新商品
    getNewestGoodsList() {
      this.$request.post({
        url: "/ReportApi/business/goodlist",
        params: {
          pageNo: 1,
          pageSize: 11,
          sort: 5,
          tenantid: localStorage.getItem("tenantInfoid"),
        },
        success: (res) => {
          this.newestGoodsList = res[0];
          if (this.newestGoodsList.length < 11) {
            this.newNull = 11 - this.newestGoodsList.length;
          }
        },
      });
    },
    deleteMultipe(item) {
      this.$refs.addCartDialog.show(item.cinvCode);
    },
    buyGoods(item) {
      window.open(
        this.$locationViewUrl + "#/goods/Detail?cinvCode=" + item.cinvCode+'&brandName='+this.brandName,
        "_blank"
      );
    },
    buyGood(item) {
      // this.$store.commit('getGoodsInfo', item)
   
      this.$router.push({ path: "/goods/ImageGoodList", query: { brandName: this.brandName } });
    },
    openQQ() {
      var url =
        "tencent://message/?uin=" +
        this.$store.state.tenantInfo.qq +
        "&amp;Site=&amp;Menu=yes";
      window.open(url, "_self");
    },
    getAdvertList() {
      this.$request.post({
        url: "/ReportApi/business/advert",
        params: {
          type: 1,
          tenantId: localStorage.getItem("tenantInfoid"),
        },
        success: (res) => {
          this.advertList = res;
          //consloe.log(res);
        },
        finally: () => {},
      });
    },
    toUrl(url) {
      if (url.indexOf("http") != -1) {
        window.open(url);
      } else {
        this.$router.push(url);
      }
    },
  },
};
</script>
<style>
.btnMore {
  line-height: 28px;
  text-align: center;
  color: black;
  border: 1px solid black;
  box-sizing: border-box;
  border-radius: 30px;
  margin: 70px auto 70px auto;
  display: block;
  line-height: 45px;
  font-size: 14px;
  font-weight: 700;
  width: 200px;
  &:hover {
    color: #fff;
    background: black;
  }
}
.new_goods_title_hots {
  height: 40px;

  margin-top: 140px;
  margin-bottom: 140px;

  span {
    font-size: 40px;
    line-height: 28px;

    padding-left: 15px;
    font-weight: bold;
    font-size: 40px;
  }
}
</style>
<style lang="scss" scoped>
.el-men u-vertical-demo:not(.el-menu--collapse) {
  width: 154px;
  min-height: 400px;
}

#goods {
  .goods_params {
    border-radius: 4px;
    font-size: 14px;
    color: black;

    .block {
      line-height: 80px;
      background: #f0f0f0;

      &:nth-child(1) {
        background: #f0f0f0;
      }

      .left_info {
        float: left;
        padding-left: 20px;
        padding-right: 15px;
      }

      line-height: 80px;

      ul {
        li {
          float: left;
          padding: 0 38px;

          &:hover {
            cursor: pointer;
            color: #f80;
          }
        }

        .active {
          font-weight: bold;
          color: #f80;
        }
      }
    }
  }
  .header_wrap {
    min-height: 100%;

    .main {
      // padding-bottom: 200px;
      .header {
        .top_header {
          background: #f5f5f5;
          border-bottom: #eaeaea;
          a {
            color: #999;
            &:hover {
              color: #333;
            }
          }
          .wrap {
            width: 1400px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 39px;
            color: #999;
            font-size: 12px;
          }
          .top_right {
            li {
              padding: 0 15px;
              border-right: 1px solid #e1e1e1;
              display: inline-block;
              cursor: pointer;
            }
          }
        }
        .center_header {
          height: 125px;
          width: 1400px;
          // width: 1265px;
          .logo {
            width: 220px;
            height: 105px;
            margin-top: 10px;
          }
          .nav {
            margin-left: 50px;
            ul {
              float: left;
              // width: 688px;
              width: 610px;
              display: block;
              margin-top: 50px;
              margin-right: 30px;
            }
            li {
              float: left;
              margin: 0 4px;
              padding: 0 4px;
              font-size: 16px;
              color: #999;
              &:hover {
                cursor: pointer;
                color: #f80;
              }
            }
            .router-link-exact-active {
              color: #f80;
            }
          }
          .head_menu_user {
            margin-top: 50px;
            img {
              margin-right: 10px;
            }
            .info {
              color: #999;
              line-height: 25px;
              font-size: 14px;
              .red {
                color: #f80;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                display: inline-flex;
                max-width: 90px;
              }
              .logout {
                margin-left: 15px;
                cursor: pointer;
              }
            }
          }
        }
      }
      .login_header {
        padding-top: 30px;
        .login_img {
          display: flex;
          align-items: center;
          div:hover {
            cursor: pointer;
          }
          p {
            color: #8c8c8c;
            margin-left: 15px;
          }
        }
        .login_text {
          line-height: 130px;
          font-size: 14px;
          a {
            color: #f80;
          }
        }
      }
    }
  }
  .footer_wrap {
    height: 200px;

    padding-top: 30px;
    ul {
      li {
        display: inline-block;
        width: 270px;
        height: 56.5px;
        img {
          width: 74px;
          margin-top: -7px;
        }
        .info {
          display: inline-block;
          margin-left: 20px;
          vertical-align: top;
          .title {
            color: #333;
            font-size: 16px;
            font-weight: bold;
            line-height: 36px;
          }
          .desc {
            font-size: 12px;
            color: #999;
          }
        }
        &:not(:first-child) {
          margin-left: 33px;
        }
      }
    }
    .copyright {
      text-align: center;
      font-size: 12px;
      color: #666;
      margin-top: 48px;
      clear: both;
      .jc {
        line-height: 50px;
      }
      .link {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        p {
          display: flex;
          flex-wrap: wrap;
        }
        a {
          margin-right: 6px;
          white-space: nowrap;
        }
      }
    }
  }
  .banner {
    position: relative;
    cursor: pointer;
  }

  .banner_sub_width {
    height: 400px;

    .banner_sub {
      width: 320px;
      margin-top: 10px;
      height: 400px;
      background: #fff;
      border-radius: 4px;
    }

    .login_avatar {
      color: #999;
      text-align: center;
      font-size: 19px;

      img {
        border-radius: 50%;
        margin-bottom: 10px;
        margin-top: 10px;
        width: 80px;
        height: 80px;
      }
    }

    .to_user_center {
      width: 220px;
      padding: 48px 0;

      span {
        background: #f80;
        color: #fff;
        line-height: 30px;
        width: 360px;
        text-align: center;
        border-radius: 4px;
        margin: 0 auto;
        display: block;
        cursor: pointer;
      }
    }

    .login_btn {
      margin-top: 10px;

      .left_login {
        width: 83px;
        line-height: 31px;
        font-size: 14px;
        border: 1px solid #f80;
        border-radius: 3px;
        margin-left: 28px;
        text-align: center;

        &:hover {
          cursor: pointer;
        }

        &:first-child {
          color: #f80;

          &:hover {
            background: #f80;
            color: #fff;
          }
        }

        &:last-child {
          background: #f80;
          color: #fff;
        }
      }
    }

    .sub_gg {
      padding: 8px 25px;
      box-sizing: border-box;
      margin-top: 10px;
      border-top: 1px solid #f1f1f1;
      font-size: 12px;

      h3 {
        font-size: 14px;
        margin-bottom: 5px;
      }

      li {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        line-height: 25px;
        color: #999;
      }
    }

    .other_block {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      .block {
        border-top: 1px solid #dedede;
        float: left;
        width: 140px;
        height: 73px;
        background: #fbfbfb;
        box-sizing: border-box;
        font-size: 14px;
        text-align: center;

        &:first-child,
        &:nth-child(3) {
          border-right: 1px solid #dedede;
        }

        img {
          width: 30px;
          height: 30px;
          margin-top: 8px;
          margin-bottom: 5px;
        }

        &:hover {
          background: #eaeaea;
          cursor: pointer;
        }
      }
    }
  }

  .main {
    .advertising {
      display: flex;
      margin-top: 10px;

      div {
        flex: 1;

        img {
          border-radius: 4px;
          cursor: pointer;
        }
      }
    }

    .recommend {
      margin-top: 20px;

      .item {
        // height: 510px;
        margin-left: 20px;
        margin-bottom: 12px;
        width: 21%;

        .recommend_left {
          height: 280px;
          width: 360px;

          img {
            width: 400px;
            height: 320px;
          }

          &:hover {
            cursor: pointer;
          }
        }

        // &:nth-child(1),
        // &:nth-child(4n + 1) {
        //   margin-left: 0;
        // }

        dl {
          width: 100%;
          // border: 1px solid #eee;

          // &:hover {
          //   cursor: pointer;
          //   box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.3);
          //   width: 100%;
          // }
          .image-transitions {
            transition: transform 0.5s ease;
          }
          img {
            width: 100%;
            // height: 400px;
            // &:hover {
            //   width: 440px;
            //   width: 440px;
            // }
          }

          dd {
            padding: 0 5px;
          }

          .title {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-size: 15px;
            font-weight: 600;
            color: black;
            line-height: 36px;
            margin-top: 25px;
          }

          .other {
            .price {
              font-size: 17px;
              color: black;
              font-weight: 600;
            }

            .wei {
              font-size: 14px;
              color: #666;
            }
          }

          .btn {
            line-height: 28px;
            text-align: center;
            color: black;
            border: 1px solid black;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            border-radius: 30px;
            /* margin: 6px auto 10px auto; */
            display: block;
            line-height: 20px;
            font-size: 14px;
            font-weight: 700;
            padding: 2px 10px;
            &:hover {
              color: #fff;
              background: black;
            }
          }
          .btns {
            line-height: 28px;
            text-align: center;
            color: black;
            border: 1px solid black;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            border-radius: 30px;
            /* margin: 6px auto 10px auto; */
            display: block;
            line-height: 20px;
            font-size: 14px;
            font-weight: 700;
            padding: 2px 17px;
            &:hover {
              color: #fff;
              background: black;
            }
          }
        }
      }
      .itemShop {
        margin-left: 20px;
        margin-bottom: 12px;
        width: 45%;
        .recommend_left {
          height: 280px;
          width: 360px;

          img {
            width: 800px;
            height: 320px;
          }

          &:hover {
            cursor: pointer;
          }
        }

        // &:nth-child(1),
        // &:nth-child(4n + 1) {
        //   margin-left: 0;
        // }

        dl {
          width: 100%;
          // border: 1px solid #eee;

          // &:hover {
          //   cursor: pointer;
          //   box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.3);
          //   width: 440px;
          // }

          img {
            width: 100%;
          }

          dd {
            padding: 0 5px;
          }

          .title {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-size: 15px;
            font-weight: 700;
            color: black;
            line-height: 36px;
            margin-top: 25px;
          }

          .other {
            .price {
              font-size: 17px;
              color: black;
              font-weight: 600;
            }

            .wei {
              font-size: 14px;
              color: #666;
            }
          }

          .btn {
            line-height: 28px;
            text-align: center;
            color: black;
            border: 1px solid black;
            box-sizing: border-box;
            border-radius: 30px;
            margin: 6px auto 10px auto;
            display: block;
            line-height: 20px;
            font-size: 14px;
            font-weight: 700;
            &:hover {
              color: #fff;
              background: black;
            }
          }
        }
      }
      .items {
        height: 470px;
        margin-left: 10px;
        margin-bottom: 12px;

        .recommend_left {
          height: 280px;
          width: 360px;

          img {
            width: 360px;
            height: 280px;
          }

          &:hover {
            cursor: pointer;
          }
        }

        &:nth-child(1),
        &:nth-child(3n + 1) {
          margin-left: 0;
        }

        dl {
          width: 360px;
          // border: 1px solid #eee;

          &:hover {
            cursor: pointer;
            box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.3);
          }

          img {
            width: 360px;
            height: 360px;
          }

          dd {
            padding: 0 5px;
          }

          .title {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-size: 15px;
            font-weight: 700;
            color: black;
            line-height: 36px;
            margin-top: 25px;
          }

          .other {
            .price {
              font-size: 18px;
              color: black;
            }

            .wei {
              font-size: 14px;
              color: #666;
            }
          }

          .btn {
            width: 243px;
            line-height: 28px;
            text-align: center;
            color: #f80;
            border: 1px solid #f80;
            box-sizing: border-box;
            border-radius: 4px;
            margin: 6px auto 10px auto;
            display: block;
            height: 28px;
            font-size: 14px;

            &:hover {
              color: #fff;
              background: #f80;
            }
          }
        }
      }

      .null {
        width: 188px;
        border: 1px solid #eee;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .new_goods_title_hot {
      height: 40px;

      margin-top: 140px;
      margin-bottom: 140px;

      span {
        font-size: 40px;
        line-height: 28px;

        padding-left: 15px;
        font-weight: bold;
        font-size: 40px;
      }
    }

    .new_goods_title_new {
      height: 40px;
      border-bottom: 1px solid #efefef;
      margin-top: 40px;
      margin-bottom: 30px;

      span {
        font-size: 40px;
        line-height: 28px;
        border-left: 5px solid #f80;
        padding-left: 15px;
        font-weight: bold;
        font-size: 40px;
      }
    }

    .new_goods_title_new1 {
      height: 40px;
      border-bottom: 1px solid #efefef;
      margin-top: 40px;
      margin-bottom: 30px;

      span {
        font-size: 40px;
        line-height: 28px;
        border-left: 5px solid #f80;
        padding-left: 15px;
        font-weight: bold;
        font-size: 40px;
      }
    }

    .new_goods_title_new2 {
      height: 40px;
      border-bottom: 1px solid #efefef;
      margin-top: 40px;
      margin-bottom: 30px;

      span {
        font-size: 40px;
        line-height: 28px;
        border-left: 5px solid #f80;
        padding-left: 15px;
        font-weight: bold;
        font-size: 40px;
      }
    }

    .new_goods_title_Image_store {
      height: 40px;
      border-bottom: 1px solid #efefef;
      margin-top: 40px;
      margin-bottom: 30px;

      span {
        font-size: 20px;
        line-height: 28px;
        border-left: 5px solid #f80;
        padding-left: 15px;
        font-weight: bold;
        font-size: 20px;
      }
    }

    .section4 {
      position: relative;
      text-align: center;
      margin-bottom: 25px;
      background: #fff;

      & > span {
        position: relative;
        display: inline-block;
        margin: 25px auto;
        font-weight: 700;
        font-size: 22px;

        &::after {
          content: "";
          position: absolute;
          bottom: -10px;
          left: 50%;
          -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
          display: inline-block;
          background: -webkit-gradient(
            linear,
            left top,
            right top,
            from(rgba(255, 166, 0, 0.929412)),
            to(#ff6a00)
          );
          background: linear-gradient(90deg, rgba(255, 166, 0, 0.929412), #ff6a00);
          width: 40%;
          border-radius: 50px;
          height: 4px;
        }
      }

      .article_list {
        text-align: left;
        display: -webkit-box;
        display: flex;
        -webkit-box-pack: justify;
        justify-content: space-between;
        flex-wrap: wrap;

        .article_item {
          border: 1px solid #eee;
          padding: 10px 30px;
          margin: 10px 0;
          width: 44%;
          cursor: pointer;

          &:hover {
            box-shadow: 0 0 5px 1px #dcdfe6;

            .title a {
              color: #ff6a00;
            }
          }

          .title {
            display: -webkit-box;
            display: flex;
            -webkit-box-pack: justify;
            justify-content: space-between;
            margin-bottom: 10px;
          }

          .summary {
            color: #767676;
            font-size: 14px;
          }

          .title a {
            font-size: 18px;
            color: #333;
          }

          .title {
            p {
              color: #999;
            }
          }
        }
      }
    }
  }

  .xian {
    height: 5px;
    background: #eee;
    margin-top: 30px;
    margin-bottom: 20px;
  }
}
</style>
